<template>
    <div
        @click="onViewResources"
        :class="{ 'not-allowed': !courseUnit.is_selected, 'cursor-pointer': courseUnit.is_selected }"
    >
        <div>
            <div style="color: var(--el-app-primary)">
                <i
                    style="font-size: 20px"
                    class="fas fa-folder-minus"
                ></i>
                <span class="course-unit-code">{{ courseUnit.course_unit_code }}</span>
            </div>
            <div style="display: flex; justify-content: space-between">
                <small style="color: var(--el-app-primary)">{{ courseUnit.title }}</small>
                <small>
                    <i
                        v-if="courseUnit.is_selected"
                        class="el-icon-check"
                        style="color: green"
                    ></i>
                    <i
                        v-else
                        class="fas fa-lock"
                        style="color: red"
                    ></i>
                </small>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CourseUnitCardItem',
    props: {
        courseUnit: {
            type: Object,
            required: true
        },
        courseName: {
            type: String,
            required: true
        }
    },
    methods: {
        onViewResources() {

            if (this.courseUnit.is_selected) {
                this.$router.push({
                    name: 'CourseUnitResources',
                    params: {
                        courseName: this.courseName,
                        courseUnitName: this.courseUnit.title,
                        courseUnitId: this.courseUnit.course_unit_id,
                    },
                });
            }
        }
    }
}

</script>

<style scoped>
.course-unit-code {
    margin-left: 10px;
    font-weight: 500;
    font-size: 12px;
    font-weight: 600;
}</style>