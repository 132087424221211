<template>
  <section class="container">
    <div class="content">
      <div class="row">
        <div class="text-left col-12">
          <div style="
              font-size: 20px;
              font-weight: 400;
              color: var(--el-app-primary);
              margin-bottom: 15px;
            ">
            <strong>E-learning Help or feedback</strong>
          </div>
        </div>
      </div>

      <div style="text-align: left">
        <label for="subject"><strong>Subject</strong></label>
        <el-select
          filterable
          :class="{ 'input-error': $v.subject.$error }"
          @keyup.enter="sendFeedback"
          v-model="$v.subject.$model"
          placeholder="Choose Subject"
          style="width: 100%"
        >
          <el-option
            v-for="subject in subject_options"
            :key="subject.id"
            :value="subject.sub_value"
            :label="subject.title"
          >
          </el-option>
        </el-select>
        <span
          v-if="$v.subject.$error"
          style="font-size: 0.7em; color: red"
        >{{
          subjectError
        }}</span>

        <div style="margin: 20px 0"></div>
        <label for="message"><strong>Message</strong></label>
        <el-input
          type="textarea"
          id="message"
          :class="{ 'input-error': $v.message.$error }"
          @keyup.enter="sendFeedback"
          v-model="$v.message.$model"
          :autosize="{ minRows: 8, maxRows: 10 }"
          placeholder="Type message here ..."
        >
        </el-input>
        <br />
        <span
          v-if="$v.message.$error"
          style="font-size: 0.7em; color: red"
        >{{
          messageError
        }}</span>

        <div style="margin: 20px 0"></div>
        <div style="display: flex; justify-content: flex-end">
          <el-button
            type="primary"
            v-if="!submiting"
            @click="sendFeedback"
            :disabled="!$v.subject.required || !$v.message.required"
          >Submit Feedback</el-button>
          <ScaleOut
            v-else
            :background="'#164B70'"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ScaleOut from "@/components/scale-out-component.vue";

export default {
  components: { ScaleOut },
  mixins: [validationMixin],

  data() {
    return {
      subject: "",
      message: "",
      submiting: false,
      subject_options: [
        { id: 1, title: "Finances", sub_value: "FINANCES" },
        { id: 2, title: "Quality assurance", sub_value: "QUALITY_ASSURANCE" },
        { id: 3, title: "Academics", sub_value: "ACADEMICS" },
        { id: 4, title: "Technical", sub_value: "TECHINICAL" },
      ],
    };
  },
  mounted() {},

  computed: {
    subjectError() {
      if (!this.$v.subject.required) {
        return "*Subject is required";
      }
      return "";
    },
    messageError() {
      if (!this.$v.message.required) {
        return "*Message is required";
      }
      return "";
    },
  },

  validations: {
    subject: {
      required,
    },
    message: {
      required,
    },
  },

  methods: {
    async sendFeedback() {
      if (!this.$v.subject.required || !this.$v.message.required) return;
      let helpData = {
        subject: this.subject,
        message: this.message,
      };
      this.submiting = true;
      try {
        let request = await this.$http.post(`contact/add`, helpData);
        if (
          request.data.success &&
          request.data.message == "MESSAGE_ADDED_SUCCESSFULLY"
        ) {
          this.showSuccessMessage("Success", "Message submitted successfully");
          this.subject = "";
          this.message = "";
          this.$v.$reset();
        } else {
          this.$rollbar.warning("STUDENTS FRONT END: Unexpected API response", {
            response: request.data,
            request,
          });
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }
        this.showFailedMessage(
          "Unable to send message",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.submiting = false;
      }
    },
  },
};
</script>

<style scoped>
.content {
  margin-top: 45px !important;
  padding: 30px 100px 100px 100px;
}
</style>