export default `<div>
<div class="mceNonEditable hide_on_submission">
    <p>&nbsp;</p>
    <p style="text-align: center;"><strong><img src="https://vclass.ac/img/vu-logo-with-words.ac2cb230.png"
                alt="VLogo" width="269" height="115" /></strong></p>
    <h2 style="text-align: center;"><strong>Course Work Answer Book</strong></h2>
    <h2 style="text-align: center;"><strong>UNIVERSITY COURSE WORK 2022 / 2023</strong></h2>
    <p><strong>DIRECTIONS TO CANDIDATES.</strong></p>
    <p>1. Leave margin blank.</p>
    <p>2. Write the numbers of the questions which you have</p>
    <p>attempted, with sub sections where necessary, in the</p>
    <p>spaces provided below</p>
</div>
<table border="1" cellspacing="0" cellpadding="0">
    <tbody>
        <tr>
            <td colspan="9" width="638" class="mceNonEditable">
                <p>&nbsp;<strong>NUMBER OF QUESTIONS</strong> you have answered in the order in which you have
                    written them</p>
            </td>
        </tr>
        <tr>
            <td width="71">&nbsp;</td>
            <td width="71">&nbsp;</td>
            <td width="71">&nbsp;</td>
            <td width="71">&nbsp;</td>
            <td width="71">&nbsp;</td>
            <td width="71">&nbsp;</td>
            <td width="71">&nbsp;</td>
            <td width="71">&nbsp;</td>
            <td width="71">&nbsp;</td>
        </tr>
    </tbody>
</table>
<p class="hide_on_submission">&nbsp;</p>
<div class="mceNonEditable hide_on_submission">
    <p><strong>How and where should I submit my course work script? </strong></p>
    <p>Every student will be required to attend their course work via <strong>Vclass portal</strong>. E.g. you
        go to <a href="http://www.vclass.ac">www.vclass.ac</a> and login, to your account, then <strong>click on
            Course Work</strong>.</p>
    <p>Under course work you will see the following: -</p>
    <p>a) Instructions for that particular course work with time required to finish your course work as per
        instructions,</p>
    <p>b) A student will be required to download the question paper and the answer sheet provided by the
        university within the same module course work, or a student can be required to attempt structured
        questions within the system depending on how the course work was set.</p>
    <p>c) &nbsp;Submission of answered questions is done,</p>
    <p>d) Student is required to click to <strong>consent</strong> to show that the answered course work belongs to
        them.</p>
    <p>e) <strong>Note</strong> that if an course work is for download, a student will be required to download
        the question paper and answer sheet, write their course work within the given stipulated time</p>
    <p>f) Required to scan and upload back the answered booklet through the same portal as per format available.
    </p>
    <p>g) Course work uploaded will directly be received by the Registry department.</p>
    <p>h) Students here are required to use <strong>VClass e-Learning system </strong>for all course work and
        for any failure they can contact the Registry department for guidance.</p>
    <p>i) No late submission will be accepted.</p>
    <p><strong>Avoid any course work malpractice because this will attract severe penalties such as invalidating
            the course work answered script whose consequences will attract retakes.</strong></p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <h2 style="text-align: center;"><strong>ANSWERS HERE</strong></h2>
    <hr />
</div>
<div>
    <p>&nbsp;</p>
</div>
</div>`;