<template>
  <article>
    <!-- {{ this.course_work }} -->
    <button
      type="button"
      class="btn z-depth-0"
      :style="{
        width: this.course_work.start_in_seconds ? '180px' : 'auto',
        textTransform: this.course_work.start_in_seconds
          ? 'none'
          : 'capitalize',
      }"
      @click="onCourseWorkAttempt"
      :disabled="
        !studentHasSpecialSubmitPermission && (!course_work.is_available || studentSubmitted)
      "
      v-ripple="'rgba(255, 255, 255, 0.35)'"
      style="
        width: auto;
        text-transform: capitalize;
        height: 40px;
        border-radius: 4px;
        background-color: var(--el-app-primary);
      "
    >
      <span style="position: relative; bottom: 4px">{{ buttonText }} </span>
    </button>

  </article>
</template>

<script>
/* import { format } from "date-fns"; */
export default {
  name: "app",
  components: {},

  data() {
    return {
      enterCourseWorkCardNumberDialog: false,
      courseWorkCardNo: "",
      courseWorkStartInSeconds: null,
      countDownInterval: null,
    };
  },
  computed: {
    formattedDateWithTime() {
      return (seconds) => {
        //Ensure we only start the Count down if it's less than a day
        return seconds >= 86400
          ? "Not Available"
          : `Starts in ${new Date(seconds * 1000).toISOString().substr(11, 8)}`;
      };
    },

    studentStartedCourseWork() {
      return !!this.course_work.student_course_work_submissions[0];
    },

    studentHasSpecialSubmitPermission() {
      return (
        this.studentStartedCourseWork &&
        this.course_work.student_course_work_submissions[0]
          .is_special_submission_valid
      );
    },

    studentSubmitted() {
      return (
        this.studentStartedCourseWork &&
        this.course_work.student_course_work_submissions[0].submitted_at
      );
    },

    buttonText() {
      //first check for special permission
      if (this.studentHasSpecialSubmitPermission) {
        return "Resume Course Work";
      }

      if (this.course_work.is_available) {
        if (this.studentSubmitted) {
          return "Already Submitted";
        } else if (this.studentStartedCourseWork) {
          return "Resume Course Work";
        } else {
          return "Start Course Work";
        }
      } else {
        if (this.course_work.start_in_seconds) {
          return this.formattedDateWithTime(this.courseWorkStartInSeconds);
        }
        //Meaning Now this Course Work is in the Past
        else {
          //Find Out, if the  Student had not started the Course Work Even. and it's not in the future
          if (
            !this.studentStartedCourseWork &&
            this.course_work.is_course_work_ended
          ) {
            return "You missed this Course Work";
          }

          return "Not Available";
        }
      }
    },
  },

  mounted() {
    if (this.course_work.start_in_seconds) {
      this.courseWorkStartInSeconds = this.course_work.start_in_seconds;
      this.startCountDown();
    }
  },

  props: {
    course_work: {
      type: Object,
      required: true,
    },
    isStudentProctorVerified: {
      type: Boolean,
      required: false,
    },
    studentProctorToken: {
      type: String,
      required: false,
    },
  },

  destroyed() {
    clearInterval(this.countDownInterval);
  },

  methods: {
    startCountDown() {
      this.countDownInterval = setInterval(() => {
        if (this.courseWorkStartInSeconds == 0) {
          clearInterval(this.countDownInterval);
          return location.reload();
        }
        --this.courseWorkStartInSeconds;
      }, 1000);
    },

    async onStartCourseWork() {
      try {
        // if (!this.courseWorkCardNo) return;

        this.$emit("on-loading-start");
        let request = await this.$http.post(`course-work/start-course-work/`, {
          // permit_number: this.courseWorkCardNo,
          course_work_id: this.course_work.course_work_id,
          course_unit_id: this.$route.params.courseUnitId,
        });
        if (
          request.data.success &&
          request.data.message === "COURSE_WORK STARTED SUCCESSFULLY"
        ) {
          //TODO: AS A HACK, FORCEFULLY LOAD THE Course Work SCREEN AS A NEW HTML PAGE SINCE IN THAT WAY, TINY MCE LOADS WELL
          window.location.href = `/dashboard/course-work/ongoing/${this.course_work.course_work_id}`;
          /* this.$router.push({
            path: `/dashboard/course-work/ongoing/${this.course_work.course_work_id}`,
          }); */
          // this.enterCourseWorkCardNumberDialog = false;
        } else if (
          request.data.message ==
          "COURSE_WORK NOT FOUND OR STUDENT DOES NOT OFFER ANY COURSE UNITS ON THIS COURSE_WORK"
        ) {
          this.showWarningMessage(
            "Course Work Not found",
            "We were unable to find this course work for you right now. Please Contact your Admin"
          );
        } else if (
          request.data.message ==
          "STUDENT DOES NOT HAVE THE SUPPLIED COURSE UNIT FOR THIS COURSE_WORK"
        ) {
          this.showWarningMessage(
            "No Permission",
            "The Chosen Module does not have this Course work scheduled for it. Please Contact your Admin"
          );
        } else if (request.data.message == "COURSE_WORK NOT AVAILABLE") {
          this.showWarningMessage(
            "Not Available",
            "The Course Work is not available for attempting at the moment."
          );
        } else if (
          request.data.message == "STUDENT ALREADY STARTED THE COURSE WORK"
        ) {
          window.location.href = `/dashboard/course-work/ongoing/${this.course_work.course_work_id}`;
        } else if (
          request.data.message == "STUDENT_NOT_PERMITTED_TO_SIT_FOR_COURSE_WORK"
        ) {
          this.showWarningMessage(
            "No Clearance",
            "You're not cleared to attempt this course Work. Please contact your Admin"
          );
        } else {
          this.$rollbar.warning("STUDENTS FRONT END: Unexpected API response", {
            response: request.data,
            request,
          });
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }
        this.showFailedMessage(
          "Unable to Start Course Work",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.$emit("on-loading-end");
      }
    },

    handleClose(done) {
      this.clearInputs();
      done();
    },

    clearInputs() {
      this.courseWorkCardNo = "";
    },

    onCourseWorkAttempt() {
      if (
        !this.course_work.is_available &&
        !this.studentHasSpecialSubmitPermission
      )
        return;

      // if (this.course_work.is_proctored && !this.isStudentProctorVerified) {
      //   return (window.location.href = `https://demo.proctorexam.com/student_sessions/${this.studentProctorToken}`);
      // }

      //Ensure the student has a submission and they havent submitted or they have a special submission pass
      if (
        this.studentHasSpecialSubmitPermission ||
        (this.studentStartedCourseWork && !this.studentSubmitted)
      ) {
        //TODO: AS A HACK, FORCEFULLY LOAD THE COURSE WORK SCREEN AS A NEW HTML PAGE SINCE IN THAT WAY, TINY MCE LOADS WELL
        return (window.location.href = `/dashboard/course-work/ongoing/${this.course_work.course_work_id}`);
      }

      //If Student already has a started at time for the Course Work, and it's still available. Means we can let them just continue to the course work.

      // this.enterCourseWorkCardNumberDialog = true;
      this.onStartCourseWork();
    },
  },
};
</script>


<style scoped>
.fixed-width {
  width: 190px !important;
}

.auto-width {
  width: auto !important;
}

* {
  box-sizing: border-box;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.enter_course_work_card_number_dialog .el-dialog {
  width: 30%;
}

.search_by_input {
  width: 400px !important;
}

.enter_course_work_card_number_dialog .el-button--primary {
  color: #fff;
  background-color: #409eff !important;
  border-color: #409eff !important;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .enter_course_work_card_number_dialog .el-dialog {
    width: 50%;
    background-color: #fafafa;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .enter_course_work_card_number_dialog .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .enter_course_work_card_number_dialog .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .enter_course_work_card_number_dialog .el-dialog {
    width: 90%;
  }
}
</style>
