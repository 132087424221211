import { render, staticRenderFns } from "./preview-online-answer-sheet-dialog.vue?vue&type=template&id=207c4313&scoped=true"
import script from "./preview-online-answer-sheet-dialog.vue?vue&type=script&lang=js"
export * from "./preview-online-answer-sheet-dialog.vue?vue&type=script&lang=js"
import style0 from "./preview-online-answer-sheet-dialog.vue?vue&type=style&index=0&id=207c4313&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "207c4313",
  null
  
)

export default component.exports