<template>
  <div class="container-fluid">
    <br /><br />
    <div class="mx-0 row w-100">
      <div class="pb-3 mr-0 text-left col-md-8">
        <BreadCrumbNavigation
          v-if="examination"
          :pageTitles="['Examinations', 'Ongoing Exam']"
        />
      </div>
    </div>
    <div
      v-if="loading"
      style="
        height: 60vh;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <div>
        <ScaleOut
          class="mt-3"
          :background="'#164B70'"
        /><br />
        <span style="font-weight: 500">Loading Examination Details...</span>
      </div>
    </div>
    <div
      v-show="!loading"
      class="row w-100"
    >
      <div
        class="text-left col-md-6"
        style="display: flex"
      >
        <div class="row w-100 mx-">
          <div class="col-12">
            <span
              v-if="examination.title"
              class="pb-4"
              style="
                font-weight: 600;
                text-transform: capitalize;
                font-size: 1.2em;
              "
            >
              {{ examination.title.toLowerCase() }}
            </span>
          </div>
          <div class="col-12">
            <span
              v-if="examination.title"
              class="pb-4"
              style="
                font-weight: 600;
                text-transform: capitalize;
                font-size: 1.1em;
                color: rgba(0, 0, 0, 0.4);
              "
            >
              {{ examination.examination_intakes[0].course_unit.title }} -
              {{
                examination.examination_intakes[0].course_unit.course_unit_code
              }}
            </span>
          </div>
        </div>
      </div>
      <div
        class="text-right col-md-6"
        style="display: flex; justify-content: flex-end"
      >
        <UploadAnswerSheetDialog
          v-if="!!examination.examination_id"
          :examId="examination.examination_id"
          @on-submit-online-answer-sheet="onSubmitOnlineAnswerSheet"
          :studentAnswers="studentAnswers"
          :isSubmittingOnlineAnswer="isSubmittingOnlineAnswer"
        />
        <el-dropdown @command="handleClick">
          <el-button type="primary">
            Download<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="QuestionPaper">Download Question Paper</el-dropdown-item>
            <el-dropdown-item command="AnswerSheet">Download Answer Sheet</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div
      v-show="!loading"
      class="row w-100"
    >
      <div class="col-12">
        <div id="the-tab-section">
          <div style="display: flex; justify-content: center">
            <div id="the-tab">
              <el-tabs
                class="mx-auto"
                :stretch="true"
                v-model="activeView"
                @tab-click="onTabClick"
              >
                <el-tab-pane name="questionPaper">
                  <span slot="label"><i class="pr-2 el-icon-paperclip"></i> Question Paper</span>
                </el-tab-pane>
                <el-tab-pane name="answerSheet">
                  <span slot="label"><i class="pr-2 el-icon-document"></i> Online Answer
                    Sheet</span>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-show="!loading"
      class="mt-3 row"
    >
      <div
        class="col-12"
        v-show="activeView == 'questionPaper'"
      >
        <DocumentContent
          :type="'questionPaper'"
          :examination="examination"
          :key="questionPaper"
        />
      </div>
      <div
        class="col-12"
        v-show="activeView == 'answerSheet'"
      >
        <tinymce
          v-if="showTinyMce"
          :key="tinymceKey"
          id="d1"
          v-model="studentAnswers"
          name="content"
        ></tinymce>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-irregular-whitespace */
import ScaleOut from "@/components/scale-out-component.vue";
import BreadCrumbNavigation from "../components/bread-crumb-component.vue";
import DocumentContent from "../components/document-content.vue";

import UploadAnswerSheetDialog from "../components/dialogs/upload-answer-sheet-dialog.vue";
import tinymce from "../components/examinations/TinymceVue.vue";
import { Message } from "element-ui";

import { addSeconds, differenceInSeconds } from "date-fns";
import getMonthName from "../helpers/getMonthName";
import defaultOnlineExamInstructions from "../helpers/defaultOnlineExamInstructions";
// import tinyMCEPreventDelete from "../helpers/tinyMCEPreventDelete";
export default {
  components: {
    DocumentContent,
    UploadAnswerSheetDialog,
    ScaleOut,
    tinymce,
    BreadCrumbNavigation,
  },

  data() {
    return {
      loading: false,
      submiting: false,
      activeView: "questionPaper",
      isLoading: true,
      showTinyMce: true,
      examination: {},
      // examination: this.$route.params.examination,
      examId: this.$route.params.examId, //this is the examination id from the browser
      batches: [],
      students_doing_exam: [],
      question_fileExtension: "",
      answerSheet_fileExtension: "",
      endsInEpochTime: 0,
      countDownInterval: null,
      saveChangesInterval: null,
      questionPaper: 0,
      tinymceKey: 0,

      tableData: [],
      tableProps: {
        border: true,
        stripe: true,
      },

      selectedBatchId: "",
      filters: [
        {
          prop: "title",
          value: "",
        },
      ],
      studentAnswers: "",
      studentAnswersCopy: "",
      onlineAnswerSheetUploadUrl: null,
      isSubmittingOnlineAnswer: false,
    };
  },

  /*   watch: {
    studentAnswers(value) {
      this.studentAnswersCopy = value;
    },
  }, */

  destroyed() {
    clearInterval(this.countDownInterval);
    clearInterval(this.saveChangesInterval);

    //Remove the counter when the Page is closed
    this.$store.commit("UPDATE_EXAM_TIME_LEFT", "");
  },

  computed: {
    getInitials() {
      return (nameString) => {
        //  format(parseISO(dateString), "do MMMM yyyy");
        var names = nameString.split(" "),
          initials = names[0].substring(0, 1).toUpperCase();
        if (names.length > 1) {
          initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      };
    },
  },

  mounted() {
    this.getExamination();

    this.periodicallyCheckAnswerSheetChanges();
    this.studentAnswersCopy = this.studentAnswers;

    /*     const that = this;
    this.$nextTick(() => {
      that.showTinyMce = true;
    }); */
    // If there's No examination, e.g page has reloaded, go back to previous page
    // if (!this.examination) {
    //     this.$router.back();
    // }
  },

  methods: {
    startCountDown() {
      this.countDownInterval = setInterval(() => {
        const remainingTime = differenceInSeconds(new Date(this.endsInEpochTime), new Date());
        this.$store.commit(
          "UPDATE_EXAM_TIME_LEFT",
          this.formattedEndsInSeconds(remainingTime)
        );
        if (remainingTime == 0) {
          return clearInterval(this.countDownInterval);
        }
      }, 1000);
    },

    async onSubmitOnlineAnswerSheet() {
      const fullScreenLoader = this.$loading({
        lock: true,
        text: "Submitting Answer sheet.",
        spinner: "el-icon-loading",
        background: "rgba(0%, 0%, 0%, 0.7)",
      });
      try {
        this.isSubmittingOnlineAnswer = true;
        const isSaved = await this.saveStudentChanges();

        if (!isSaved) throw "UNABLE TO SAVE STUNDENT CHANGES";

        const request = await this.$http.post(
          "examinations/submit-online-answer-sheet",
          {
            examination_id: this.examId,
          }
        );

        if (
          request.data.success &&
          request.data.message == "ONLINE ANSWER SHEET SUBMITTED SUCCESSFULLY"
        ) {
          this.showSuccessMessage(
            "Exam Submitted",
            "Examination Answers successfully submitted"
          );
          // this.$router.replace({ name: "Examinations" });
          this.$router.push({
            name: "Examinations",
            params: {
              submitted_examination_id:
                request.data.student_examination_submission_id,
              should_ask_for_feedback: request.data.should_ask_for_feedback,
            },
          });
        } else if (request.data.message == "EXAMINATION IS NOT AVAILABLE") {
          this.showWarningMessage(
            "Examination Not Available",
            "The Examination you are trying to submit your answer sheet to is no longer available. Please Contact Admin for Help."
          );
        } else if (
          request.data.message == "VALID STUDENT EXAM RELATED INFO NOT FOUND"
        ) {
          this.showWarningMessage(
            "Exam Info mismatch",
            "Unable to provide consistent examination info for your account. Please try again"
          );
          this.$router.replace({ name: "Examinations" });
        } else if (request.data.message == "ONLINE ANSWERSHEET SEEMS EMPTY") {
          this.showWarningMessage(
            "Online Answersheet Empty?",
            "Did you try to submit an empty online answersheet. Please review it or upload a file instead."
          );
        } else {
          this.$rollbar.critical(
            "STUDENTS EXAMS FRONT END: Unexpected Error occured while submitting exam",
            request
          );
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        if (error.message === "Network Error") {
          this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        this.showFailedMessage(
          "Unable to Submit Examination",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.isSubmittingOnlineAnswer = false;
        fullScreenLoader.close();
      }
    },

    formattedEndsInSeconds(seconds) {
      if (seconds > 86400) {
        return `${(seconds / 3600).toFixed(0)} Hours`;
      }
      return new Date((seconds || 0) * 1000).toISOString().substr(11, 8);
    },

    periodicallyCheckAnswerSheetChanges() {
      this.saveChangesInterval = setInterval(() => {
        //If the Content has changed, then update our DB with the new content
        if (this.studentAnswersCopy != this.studentAnswers) {
          this.saveStudentChanges();
        }
      }, 1000 * 60 * 2);
    },

    // periodicallyCheckAnswerSheetChanges() {
    //   this.saveChangesInterval = setInterval(() => {
    //     //If the Content has changed, then update our DB with the new content
    //     if (this.studentAnswersCopy != this.studentAnswers) {
    //       this.saveStudentChanges();
    //     }
    //   }, 1000 * 60 * 2);
    // },

    toMonthName(monthNum) {
      return getMonthName(monthNum, true);
    },

    handleClick(command) {
      if (command == "AnswerSheet") {
        if (!this.examination.answer_sheet_path_url) {
          return this.showWarningMessage(
            "No Answer Sheet",
            "There's No Answer Sheet attached. Please Contact Admin"
          );
        }

        const link = document.createElement("a");
        link.href = this.examination.answer_sheet_path_url;
        link.download = "Answer Sheet";
        link.click();
      } else if (command == "QuestionPaper") {
        if (!this.examination.question_paper_path_url) {
          return this.showWarningMessage(
            "No Question Paper",
            "There's No Question Paper attached. Please Contact Admin"
          );
        }

        const link = document.createElement("a");
        link.href = this.examination.question_paper_path_url;
        link.download = "Answer Sheet";
        link.click();
      }

      ++this.documentReaderKey;
    },

    async getStudentChanges() {
      try {
        //Add a time stamp to prevent the browser from caching this request as it would cause incorrect answersheets on next retrieval
        //since the browser would identify this url and serve a copy from cache
        let request = await this.$http.get(
          `${this.examination.aws_online_answersheet_path
          // "https://s3.us-east-1.amazonaws.com/victoria-university-elearning/vu_examinations%2F7bca7c15-419b-4489-a837-09f2b13ae9ad%2Fsaved_online_answers%2FVU-BAE-2121-002201.html"
          }?timestamp=${new Date().getTime()}`,
          {
            headers: {
              "Content-Type": "text/html",
              Authorization: " ",
            },
          }
        );
        // console.log(request.data);
        this.studentAnswers = request.data;
        this.studentAnswersCopy = this.studentAnswers;
      } catch (error) {
        //If the file is not found i.e then it's interesting how the file was not uploaded. anyways in such scenarios, ignore it
        if (error.response && error.response.status == 404) {
          this.studentAnswers = defaultOnlineExamInstructions;
          this.studentAnswersCopy = this.studentAnswers;
          // alert("empty");
        } else throw error;
      }
    },

    async saveStudentChanges() {
      try {
        //Otherwise, submit the Answer Sheet
        Message.info("Saving Changes");

        if (!this.onlineAnswerSheetUploadUrl) {
          this.onlineAnswerSheetUploadUrl =
            await this.requestOnlineAnswerSheetUploadUrl();
        }

        if (!this.onlineAnswerSheetUploadUrl) return;

        const content = this.studentAnswers;
        var blob = new Blob([content], { type: "text/xml" });

        await this.uploadResourceToAWS({
          aws_upload_url: this.onlineAnswerSheetUploadUrl.upload_url,
          selected_file: blob,
          // eslint-disable-next-line no-unused-vars
          onProgress: (progressEvent) => {
            // console.log(progressEvent);
          },
        });

        this.studentAnswersCopy = content;
        Message.success("Changes Saved");
        return true;
      } catch (error) {
        if (error.response && error.response.status == 403) {
          const uploadUrlExpired = JSON.stringify(error.response.data).includes(
            "<Message>Invalid according to Policy: Policy expired.</Message>"
          );

          //If the upload URK link is expired, clear it and save student changes again
          if (uploadUrlExpired) {
            this.onlineAnswerSheetUploadUrl = null;
            this.saveStudentChanges();
          }
        } else {
          if (error.message === "Network Error") {
            Message.error("Internet Connection Error");
          } else {
            Message.error("Unable to Save Changes");
          }
        }
        return false;
      }
    },

    async requestOnlineAnswerSheetUploadUrl() {
      let request = await this.$http.post(
        "examinations/request-for-online-answersheet-upload-url",
        {
          examination_id: this.examId,
        }
      );

      if (
        request.data.success &&
        request.data.message == "ONLINE ANSWER SHEET UPLOAD URL GENERATED"
      ) {
        return request.data.online_answer_sheet;
      } else {
        this.$rollbar.warning(
          "STUDENT FRONT END: Unexpected API response while getting server response",
          request.data,
          request
        );
        throw "UNEXPECTED_RESPONSE";
      }
    },

    async uploadResourceToAWS({ aws_upload_url, selected_file, onProgress }) {
      const formData = new FormData();
      Object.entries(aws_upload_url.fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("file", selected_file);
      let request = await this.$http.post(`${aws_upload_url.url}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: " ",
        },
        onUploadProgress: onProgress,
        // onUploadProgress: (progressEvent) => console.log(progressEvent),
      });

      if (request.status == 204) {
        return request.headers.location;
      } else {
        throw "UNABLE TO UPLOAD FILE TO AWS";
      }
    },

    async getExamination() {
      try {
        this.loading = true;
        this.loadingError = false;
        let request = await this.$http.get(
          `examinations/ongoing-examination/${this.examId}`
        );
        if (
          request.data.success &&
          request.data.message == "ONGOING EXAMINATION FETCHED SUCCESSFULLY"
        ) {
          this.examination = request.data.examination;
          this.endsInEpochTime = addSeconds(new Date(), request.data.examination.ends_in_seconds)

          if (
            this.examination.student_examination_submissions[0] &&
            this.examination.student_examination_submissions[0]
              .special_submission_ends_in_seconds
          ) {
            if (
              this.examination.student_examination_submissions[0]
                .special_submission_ends_in_seconds
            ) {
              this.endsInEpochTime = addSeconds(new Date(), this.examination.student_examination_submissions[0].special_submission_ends_in_seconds)
            }
          }

          //If we have the aws_online_answersheet_path, means there could be some info in online submissions for the user
          if (this.examination.aws_online_answersheet_path) {
            await this.getStudentChanges();
          } else {
            this.studentAnswers = defaultOnlineExamInstructions;
            this.studentAnswersCopy = this.studentAnswers;
          }

          this.startCountDown();

          //If the exam is not available for taking yet, then dont accept them to even view this page at the moment, just redirect them to Examinations UI
        } else if (request.data.message == "EXAMINATION ALREADY SUBMITTED") {
          this.showWarningMessage(
            "Already Submitted",
            "This Examination is not available because you already submitted your Answer Sheet."
          );
          return this.$router.push({ name: "Examinations" });
        } else if (
          request.data.message ==
          "EXAMINATION NOT FOUND OR STUDENT DOES NOT OFFER ANY COURSE UNITS ON THIS EXAM"
        ) {
          this.showWarningMessage(
            "Examination Not Found",
            "Unable to find this examination for you. Please ensure you offer the module for the examination and try again."
          );
          return this.$router.push({ name: "Examinations" });
        } else if (
          request.data.message == "STUDENT HAS NOT STARTED THE EXAMINATION"
        ) {
          this.showWarningMessage(
            "Please start Examination",
            "You have not yet started the examination. Please start it to continue"
          );
          return this.$router.push({ name: "Examinations" });
        } else if (
          request.data.message == "EXAMINATION NOT AVAILABLE FOR ATTEMPTING"
        ) {
          this.showWarningMessage(
            "Exam Not available",
            "The Examination you're trying to take is not available."
          );
          return this.$router.push({ name: "Examinations" });
        } else if (
          request.data.message === "STUDENT HAS ONLY MARKS SUBMISSION"
        ) {
          this.showWarningMessage(
            "For Marking Only",
            "Reserved For Marking Purposes Only, you're not Permitted to attempt this Examination. Please contact the Admin for further assistance."
          );
          return this.$router.replace({ path: "/dashboard/examinations" });
        } else {
          this.$rollbar.warning("STUDENTS FRONT END: Unexpected API response", {
            response: request.data,
            request,
          });
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        this.loadingError = true;

        if (error.message === "Network Error") {
          this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
          return this.$router.push({ name: "Examinations" });
        }
        // console.log(error);
        this.showFailedMessage(
          "Unable to load examination",
          "An unexpected Error occurred, please try again"
        );
        return this.$router.push({ name: "Examinations" });
      } finally {
        this.loading = false;
      }
    },

    onPDfError() {
      this.showFailedMessage(
        "loading Failed",
        "Unable to load the selected Document, please try again"
      );
      // this.$router.back();
    },

    onTabClick() { },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.direction_arrows {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: rgb(214, 226, 238);
  color: var(--el-app-primary);
  cursor: pointer;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.direction_arrows i {
  font-size: 15px;
}

#the-tab-section {
  width: 80%;
  position: fixed;
  background-color: #f6f6f6;
  z-index: 100;
  top: 60px;
  padding-top: 30px;
}

#the-tab {
  width: 50%;
}

.TabpaddingTop {
  margin: 130px 27px 0px 27px;
  width: 100%;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  #the-tab-section {
    width: 100%;
  }

  #the-tab {
    width: 100%;
  }

  .TabpaddingTop {
    margin: 130px 10px 0px 10px;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  #the-tab-section {
    width: 100%;
  }

  #the-tab {
    width: 100%;
  }

  .TabpaddingTop {
    margin: 130px 10px 0px 10px;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  #the-tab-section {
    width: 100%;
  }

  #the-tab {
    width: 100%;
  }

  .TabpaddingTop {
    margin: 130px 10px 0px 10px;
  }

  .search_add_section {
    display: block;
  }

  .addbtn {
    width: 100%;
    margin-top: 10px;
  }

  .search_by_input {
    width: 100% !important;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  #the-tab-section {
    width: 100%;
  }

  #the-tab {
    width: 100%;
  }

  .TabpaddingTop {
    margin: 130px 5px 0px 5px;
  }

  .search_add_section {
    display: block;
  }

  .addbtn {
    width: 100%;
    margin-top: 10px;
  }

  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
.announcement_dialogs .el-dialog {
  width: 70%;
}

.search_by_input {
  width: 400px !important;
}

.announcement_dialogs .el-select .el-input__inner {
  cursor: pointer;
  height: 36px !important;
  width: 100% !important;
  padding-left: 10px !important;
  text-transform: capitalize;
}

.announcement_dialogs .el-button--primary {
  color: #fff;
  background-color: #409eff !important;
  border-color: #409eff !important;
}

el-table th>.cell {
  color: black !important;
  font-weight: 500;
}

.el-table .cell {
  font-size: 0.9em;
  line-height: 23px;
  font-weight: 300;
}

.announcement_dialogs .el-input input {
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.announcement_dialogs .el-input input:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}

.announcement_dialogs .el-upload-dragger {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 366px !important;
  height: 150px !important;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.announcement_dialogs .el-icon-upload:before {
  font-size: 50px;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .announcement_dialogs .el-dialog {
    width: 50%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .announcement_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .announcement_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .announcement_dialogs .el-dialog {
    width: 90%;
  }
}
</style>
