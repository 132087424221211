<template>
  <div id="app">
    <router-view />

    <div
      class="staging_env_banner d-none d-lg-block"
      v-if="isStagingEnv"
    >
      Staging Environment
    </div>

    <!-- <CoolLightBox
      :items="items"
      :index="index"
      @close="index = null"
      :useZoomBar="true"
      :effect="'fade'"
    >
    </CoolLightBox> -->

    <PopupDialog
      :is-visible="showPopupDialog"
      :content="popupDialogContent"
      :title="popupDialogTitle"
      @close-dialog="closePopupDialog"
    />
  </div>
</template>

<script>
import "firebase/firestore";
import "firebase/messaging";
// import CoolLightBox from "vue-cool-lightbox";
import PopupDialog from "./components/dialogs/popup-dialog"
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
const isStagingEnv = process.env.VUE_APP_IS_STAGING;

export default {
  components: { /* CoolLightBox, */ PopupDialog },
  data() {
    return {
      // items: [
      //   "https://victoria-university-elearning.s3.amazonaws.com/vu_payment_images/Direct+Bank+payments.jpg",
      //   "https://victoria-university-elearning.s3.amazonaws.com/vu_payment_images/Fees+Payment+options.jpg",
      //   "https://victoria-university-elearning.s3.amazonaws.com/vu_payment_images/Flexipay.jpg",
      //   "https://victoria-university-elearning.s3.amazonaws.com/vu_payment_images/Mobile+and+Online+payments.jpg",
      //   "https://victoria-university-elearning.s3.amazonaws.com/vu_payment_images/Mobile+payments.jpg",
      // ],
      // index: null,
      installingNotification: null,
      forceReload: false,
      isStagingEnv,
      showPopupDialog: false,
      popupDialogTitle: "📢 WELCOME TO MARCH 2024 TRIMESTER 📢",
      popupDialogContent: `
              <div>
                  Dear Students,
                  <br/><br/>
                  Welcome to the March 2024 Trimester at Victoria University! As we commence this fresh academic term, we are excited to welcome each of you into our dynamic university family. A special greeting to our new students joining us - we're delighted to have you onboard.
                  <div>
                    Below is the schedule of lectures and exams for March Trimester:
                  </div>
                  <br/>
                  <table border="1">
                  <tr>
                      <th class="px-1">Teaching Block</th>
                      <th class="px-1">Teaching Dates</th>
                      <th class="px-1">Exam Dates</th>
                  </tr>
                  <tr>
                      <td class="px-1">Block 1</td>
                      <td class="px-1">1st April to 2nd May</td>
                      <td class="px-1">3rd and 4th of May</td>
                  </tr>
                  <tr>
                      <td class="px-1">Block 2</td>
                      <td class="px-1">6th May to 6th June</td>
                      <td class="px-1">7th and 8th June</td>
                  </tr>
                  <tr>
                      <td class="px-1">Block 3</td>
                      <td class="px-1">10th June to 11th July</td>
                      <td class="px-1">12th and 13th July</td>
                  </tr>
                  </table>
                  <div class="mt-2">
                    Please be reminded that maintaining a minimum of 75% class attendance and the tuition fee payment requirement are essential for eligibility to sit for exams at the end of each block.
                  </div>
                  <div>
                    <br/>
                    Wishing you all the best for a successful and fulfilling trimester!
                  </div>
                </div>`
    };
  },
  computed: {
    forcedUpdateLastChecked() {
      return this.$store.state.forcedUpdateLastChecked;
    },
  },

  mounted() {

    if (
      this.$route.name != "OngoingCourseWork" &&
      this.$route.name != "OngoingExamination"
    ) {

      // if (!process.env.VUE_APP_NO_STUDENT_POPUP_DIALOG) {
      //   // Wait 3 seconds and show popup dialog 
      //   setTimeout(() => {
      //     this.openPopupDialog();
      //   }, 3000);
      // }


      // this.$nextTick().then(() => {
      //   if (process.env.NODE_ENV === "production") {
      //     this.index = 0;
      //   }
      // });
    }
  },

  watch: {
    forcedUpdateLastChecked() {
      //Instruct the app to immediately check for updates as it means there's a NEW VERSION

      this.forceReload = true;
      this.serviceWorkerCheckUpdates();
    },
  },

  methods: {
    serviceWorkerCheckUpdates() {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        let serviceWorker = registrations.find(
          (worker) =>
            worker.active &&
            worker.active.scriptURL.includes("service-worker.js")
        );
        //If we found it, attempt to fetch updates
        if (serviceWorker) {
          serviceWorker.update();
        }
      });
    },
    openPopupDialog() {
      this.showPopupDialog = true;
    },
    closePopupDialog() {

      this.showPopupDialog = false;

      // setTimeout(() => {

      //   if (this.popupDialogTitle === "📢 ATTENTION TO ALL INTERNATIONAL STUDENTS. 📢") {

      //     this.popupDialogTitle = "📢 ATTENTION GRADUATING STUDENTS 📢";
      //     this.popupDialogContent = `
      //         <div class="w-100 text-center " style="font-weight: bold">
      //           OFFICE OF THE ACADEMIC REGISTRAR 
      //         </div>
      //         <div>
      //             <div class="mt-2">
      //               Dear students, the upcoming  <b style="font-weight: 500">7th Graduation date</b> has been changed from <b style="font-weight: 500">Friday 15th September 2023</b> to <b style="font-weight: 500">Thursday 14th September 2023</b>.
      //             </div>
      //             <div class="mt-2">
      //               Please ensure that you have paid your graduation fees and National Council for Higher Education fees before proceeding to the Academic Registrar's office for the clearance form. <b style="font-weight: 500">Deadline for Graduation clearance is 25th August 2023</b>
      //             </div>
      //             <div class="mt-2" style="font-weight: 500">
      //               Note : Total Graduation fees for Bachelor, Diploma and Masters' students is UGX 450,000 and UGX 250,000 for Foundation certificate (HEP,HEB,HEG)
      //             </div>
      //             <div class="mt-2">
      //               National council fees are <b style="font-weight: 500">UGX 20,000 per year</b> depending on the duration you have spent at Victoria University.
      //             </div>
      //             <div class="mt-2">
      //               Thank you.
      //             </div>

      //           </div>`;

      //     this.showPopupDialog = true;
      //   }

      // }, 1000)
    },
  },

  created() {
    document.addEventListener(
      "visibilitychange",
      function () {
        document.dispatchEvent(
          new CustomEvent("appVisibilityChange", { detail: document.hidden })
        );
      },
      false
    );

    navigator.serviceWorker.addEventListener("message", (event) => {
      //If it is an update request, then update the app
      if (
        (event.data && event.data.title == "Check Update") ||
        (event.data.data && event.data.data.title == "Check Update")
      ) {
        this.serviceWorkerCheckUpdates();
      }

      //If it is an update request, then update the app
      if (
        (event.data && event.data.title == "Fetch Notifications") ||
        (event.data.data && event.data.data.title == "Fetch Notifications")
      ) {
        this.$store.commit("UPDATE_NOTIFICATIONS");
      }
    });

    document.addEventListener(
      "swUpdateFound",
      () => {
        this.installingNotification = this.$notify({
          title: "We are Updating",
          // type: "info",
          iconClass: "el-icon-loading mt-2",
          showClose: false,
          message: "Fetching new update now...",
        });
      },
      { once: true }
    );

    document.addEventListener(
      "swUpdated",
      () => {
        if (this.installingNotification) {
          this.installingNotification.close();
        }

        if (this.forceReload) {
          location.reload();
          return false;
        }

        this.$notify({
          title: "Update Available",
          dangerouslyUseHTMLString: true,
          // iconClass: "fas fa-download",
          type: "info",
          showClose: false,
          onClick: () => {
            location.reload();
            return false;
          },
          message: `There is an update available. Please Click below to refresh
                      <button
                          v-on:click="loginUser"
                          v-if="!isBusy"
                          :disabled="$v.emailOrPhone.$invalid || $v.password.$invalid"
                          type="button"
                          class="mt-2 btn z-depth-0"
                          v-ripple="'rgba(255, 255, 255, 0.35)'"
                          style="
                            width: 100px;
                            text-transform: capitalize;
                            font-size: 13px;
                            height: 35px;
                            border-radius: 40px;
                            padding: 10px 10px;
                            background-color: var(--el-app-primary);
                          "
                        >
                          <span style="position: relative; bottom: 3px">Update</span>
                        </button>
                      `,
          duration: 0,
        });
      },
      { once: true }
    );
  },

  destroyed() { },
};
</script>

<style scoped>
b {
  font-weight: 600 !important;
}
</style>

<style>
.staging_env_banner {
  background-color: var(--vu-red) !important;
  color: white;
  padding: 5px 10px;
  z-index: 2000;
  font-size: 0.9em;
  border-radius: 5px;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.cursor-pointer {
  cursor: pointer !important;
}

input:focus:not(.input-error) {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}

.el-message-box__title {
  font-size: 17px;
  font-weight: 600;
  font-family: "Euclid Circular B";
}

.el-popper {
  font-family: "Euclid Circular B" !important;
}

.el-button--primary {
  color: #fff;
  background-color: var(--el-app-primary) !important;
  border-color: var(--el-app-primary) !important;
}

.el-message-box__content {
  color: #4d4e52;
  font-weight: 400;
  font-size: 13px;
  font-family: "Euclid Circular B";
}

.el-notification__content {
  text-align: left !important;
}

.el-notification.right {
  font-family: "Euclid Circular B" !important;
}

.bread-crumb-item {
  font-size: 0.8em;
  font-weight: 500;
  color: #919191;
  cursor: pointer;
}

.bread-crumb-item:hover,
.bread-crumb-home:hover {
  color: #000 !important;
  transition: 1s;
}

#app {
  font-family: "Euclid Circular B";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

input.legacy-input {
  height: calc(1.55rem) !important;
  padding: 5px;
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

input.legacy-input:disabled {
  background-color: rgb(248, 246, 246) !important;
}

input.legacy-input.input-error {
  border: 1px solid red !important;
  box-shadow: none !important;
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;
  /*  animation-iteration-count:  infinite;  */
}

input.legacy-input:focus:not(.input-error) {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f6f6f6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  width: 10px;
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  width: 10px;
  background: #042f6c;
  border-radius: 10px;
}

.card {
  box-shadow: 0 2px 5px 2px rgb(0 0 0 / 0%), 0 2px 10px 0 rgb(0 0 0 / 5%) !important;
}

button:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}
</style>
