<template>
  <div
    class="mx-auto available-tests-exams row"
    style="width: 100%"
  >
    <div
      class="pl-0 pr-0 text-left col-12"
      style="font-weight: 600"
    >
      Upcoming Lectures
    </div>

    <div
      v-if="!isFetching && !isError && liveLectures.length == 0"
      class="mt-4 text-left col-12"
      style="font-weight: 400; font-size: 0.9em"
    >

      <el-empty description=" You have No Upcoming Lectures at the Moment."></el-empty>

    </div>

    <div class="pr-0 mt-1 text-right col-12">
      <!-- <ScheduleLiveLessonComponent @on-lecture-scheduled="fetchLiveLectures" /> -->
    </div>
    <div
      v-if="isError"
      class="px-0 col-12"
      style="height: 180px"
    >
      <div style="font-weight: 600; margin-top: 50px">Fetching Failed</div>
      <div
        class="pt-2"
        style="font-weight: 400; font-size: 0.9em"
      >
        Failed to fetch Lectures now. Please try again
      </div>
      <button
        type="button"
        class="mx-0 mt-3 btn z-depth-0"
        v-ripple="'rgba(255, 255, 255, 0.35)'"
        @click="fetchLiveLectures"
        style="
          width: 220px;
          text-transform: capitalize;
          height: 45px;
          border-radius: 4px;
          background-color: red;
          font-weight: 500;
        "
      >
        <span style="position: relative; bottom: 1px"><i class="pr-2 fas fa-refresh"></i> Try Again</span>
      </button>
    </div>
    <div
      v-show="isFetching && !isError"
      class="px-0 mt-3 col-12"
    >
      <carousel
        ref="preloaderCarouselRef"
        :autoWidth="true"
        :dots="false"
      >
        <div
          v-for="i in [1, 2, 3, 4, 5, 6]"
          :key="i"
          class="mr-3 skeleton-block skeleton-effect-wave"
          style="width: 230px; height: 400px; border-radius: 5px"
          :navText="[
        `<i class='fas fa-chevron-left'></i>`,
        `<i class='fas fa-chevron-right'></i>`,
      ]"
        ></div>
      </carousel>
    </div>

    <div class="px-0 mt-3 col-12">
      <carousel
        ref="cardCarouselRef"
        :key="carouselKey"
        :autoWidth="true"
        :dots="false"
        :navText="[
        `<i class='fas fa-chevron-left'></i>`,
        `<i class='fas fa-chevron-right'></i>`,
      ]"
      >
        <div
          v-for="i in liveLectures"
          :key="i.live_lecture_id"
          v-loading="fetchingLiveClassId == i.live_lecture_id"
          class="mx-3 my-2 card ml-md-0"
          style="width: 230px; height: 400px"
        >
          <div class="pt-0 pb-3">
            <div
              class="past-video"
              v-if="i.has_lecturer_ended_class"
            >
              ENDED
            </div>
            <div v-else>
              <div
                class="past-video"
                v-if="i.class_status"
              >
                {{ i.class_status }}
              </div>

              <div
                class="past-video"
                v-if="i.class_status && i.ongoing_status == 'TODAY_AND_ENDED'"
              >
                PAST
              </div>
              <div
                class="live-video"
                v-if="!i.class_status && i.ongoing_status == 'TODAY_AND_LIVE'"
              >
                LIVE
              </div>
            </div>
            <div class="profile-picture">
              <span style="position: relative; top: 13px; font-size: 1.2em">
                {{
        getInitials(i.lecturer.first_name, i.lecturer.last_name)
      }}</span>
            </div>
            <img
              src="../assets/img/video-placeholder.webp"
              alt=""
              height="150px"
              style="object-fit: cover !important"
            />

            <div
              class="mt-4 text-left col-12 text-truncate"
              style="color: var(--el-app-primary); text-transform: capitalize"
            >
              <strong>{{ i.course_unit_code }}</strong>
            </div>

            <small
              style="
                color: #000;
                padding: 0px 10px;
                color: rgba(0, 0, 0, 0.4);
                text-transform: capitalize;
              "
              class="pb-1 text-left d-block text-truncate"
            >By {{ i.lecturer.first_name }} {{ i.lecturer.last_name }}
            </small>
            <hr class="custom-classic-hr" />
            <small
              style="color: #000; padding: 0px 10px; color: rgba(0, 0, 0, 0.6)"
              class="pb-1 text-left d-block text-truncate"
            >
              <i class="fas fa-check"></i> <strong>Date: </strong>
              {{ formattedDate(i.date) }}
            </small>
            <small
              style="
                color: #000;
                padding: 0px 10px;
                color: rgba(0, 0, 0, 0.6);
                text-transform: lowercase;
              "
              class="pb-1 text-left d-block text-truncate"
            >
              <i class="fas fa-check"></i> <strong>Time: </strong>{{ i.start_time }} to
              {{ i.end_time }}
            </small>
            <small
              style="color: #000; padding: 0px 10px; color: rgba(0, 0, 0, 0.6)"
              class="pb-1 text-left d-block text-truncate"
            >
              <i class="fas fa-check"></i> <strong>Topic: </strong>{{ i.title }}
            </small>
            <div class="pl-0 pr-3 mt-1 col-12">
              <button
                type="button"
                class="btn z-depth-0"
                :disabled="fetchingLiveClassId != null"
                :class="{ 'not-allowed': fetchingLiveClassId != null }"
                @click="fetchLiveLectureUrl(i)"
                v-ripple="'rgba(255, 255, 255, 0.35)'"
                style="
                  width: 100%;
                  text-transform: capitalize;
                  height: 40px;
                  border-radius: 4px;
                  background-color: var(--el-app-primary);
                "
              >
                <span style="position: relative; bottom: 3px">Attend</span>
                <!-- <span
                  v-if="i.starts != null"
                  style="position: relative; bottom: 3px"
                  >{{ i.starts }}</span
                >
                <span v-else style="position: relative; bottom: 3px">{{
                  i.time != "now" ? i.time : "Attend Lecture"
                }}</span> -->
              </button>
            </div>
          </div>
        </div>
      </carousel>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
// import ScheduleLiveLessonComponent from "../components/schedule-live-lesson-component";
import { format, parseISO } from "date-fns";

export default {
  components: {
    carousel,
    // ScheduleLiveLessonComponent,
  },
  data() {
    return {
      isFetching: true,
      isError: false,
      fetchingLiveClassId: null,
      liveLectures: [],
      // lecturerInfo: {},
      tests: [],
      carouselKey: 1,
    };
  },
  computed: {
    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMMM yyyy");
    },

    getInitials() {
      return (first_name = "", last_name = "") => {
        //  format(parseISO(dateString), "do MMMM yyyy");

        let initials =
          first_name.substring(0, 1).toUpperCase() +
          last_name.substring(0, 1).toUpperCase();

        return initials;
      };
    },
    // getInitials(nameString) {},
  },
  watch: {
    liveLectures() {
      //Force rerender of the component once we get some data!!!
      this.carouselKey++;
    },
  },
  mounted() {
    this.$refs.preloaderCarouselRef.$el.getElementsByClassName(
      "owl-prev"
    )[0].innerHTML = "<i class='fas fa-chevron-left'></i>";
    this.$refs.preloaderCarouselRef.$el.getElementsByClassName(
      "owl-next"
    )[0].innerHTML = "<i class='fas fa-chevron-right'></i>";

    this.fetchLiveLectures();
  },

  methods: {
    async fetchLiveLectures() {
      try {
        //If we havent been loading yet, let's Load
        if (!this.isFetching) {
          this.isFetching = true;
          this.isError = false;
        }

        let request = await this.$http.get(
          `live-lecture/fetch/student/${this.$store.state.userId}?only_up_coming`
        );
        if (
          request.data.success &&
          request.data.message == "LECTURES_RETRIEVED_SUCCESSFULLY"
        ) {
          this.liveLectures = request.data.lectures;
          this.isFetching = false;
          this.isError = false;
        } else {
          this.$rollbar.warning("STUDENTS FRONT END: Unexpected API response", {
            response: request.data,
            request,
          });
          throw "REQUEST_RETURNED_FALSE";
        }
      } catch (error) {
        // eslint-disable-line no-unused-vars
        this.isFetching = false;
        this.isError = true;

        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        this.showFailedMessage(
          "Fetching failed",
          "Unable to fetch Lectures, please try again"
        );
      }
    },

    async fetchLiveLectureUrl(lecture) {
      if (lecture.date_relative > 0) {
        return this.showWarningMessage(
          "Too Early",
          "The Lecture you are trying to take is still to early to take"
        );
      }
      this.fetchingLiveClassId = lecture.live_lecture_id;
      let request = await this.httpRequest({
        method: "GET",
        url: `live-lecture/get-student-class-url/by-live-lecture-id/${this.fetchingLiveClassId}`,
        loadingPropertyName: null,
        showSuccessMessage: false,
      });

      this.fetchingLiveClassId = null;

      if (!request) return;

      if (
        request.success &&
        request.message == "LIVE CLASS URL RETRIEVED"
      ) {
        // Store the access token in the session storage
        sessionStorage.setItem("liveClassAccessToken", request.token);
        sessionStorage.setItem("liveClassRedirectUrl", this.$route.path);

        return this.$router.push("/ongoing-lecture");
        // window.location.href = request.lecture_link;
      } //Otherwise if its a google meet url
      else if (request.message === "Google Meet URL retrieved") {
        window.location.href = request.google_meet_url;
      }
    },

  },
};
</script>


<style scoped>
.past-video {
  position: absolute;
  right: 0px;
  top: 15px;
  background-color: rgba(0, 0, 0, 0.205);
  padding: 1px 8px;
  font-weight: 600;
  color: white;
  font-size: 0.7em;
  border-radius: 10px 0px 0px 10px;
}

.live-video {
  position: absolute;
  right: 0px;
  top: 15px;
  background-color: red;
  padding: 1px 8px;
  font-weight: 600;
  color: white;
  font-size: 0.7em;
  border-radius: 10px 0px 0px 10px;
}

.profile-picture {
  position: absolute;
  right: 10px;
  top: 120px;
  background-color: #1f4ea182;
  border: 5px solid white;
  height: 60px;
  width: 60px;
  padding: 1px 8px;
  font-weight: 600;
  color: white;
  font-size: 0.7em;
  border-radius: 100%;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.35;
}
</style>

<style>
.user-avatar .skeleton-block {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

hr.custom-classic-hr {
  /* Gradient transparent-color-transparent */
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.205),
      rgba(0, 0, 0, 0));
}

.available-tests-exams .el-progress__text {
  font-size: 12px !important;
}

.owl-prev {
  position: absolute;
  left: -21px;
  top: 44%;
  padding: 6px 11px !important;
  border-radius: 30px !important;
}

.owl-next {
  position: absolute;
  right: -21px;
  top: 44%;
  padding: 6px 11px !important;
  border-radius: 30px !important;
}
</style>