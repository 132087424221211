<template>
  <div
    class="px-0 container-fluid h-100"
    style="text-align: left"
  >
    <article>
      <section style="padding-top: 50px">
        <div class="container">
          <div class="mb-4 row w-100">
            <div class="px-0 pl-3 mb-0 text-left col-12">
              <BreadCrumbComponent :pageTitles="['Notice Board']" />
            </div>
          </div>

          <div
            v-if="notices.length == 0"
            style="height: 60vh; font-size: 1.2em; font-weight: 500;
            display: flex; align-items: center; justify-content: center;"
          >
            No Announcement added yet.
          </div>

          <div v-else>
            <div
              v-loading="loading"
              style=" width: 100%"
            >
              <div
                class="theNoticeBoard"
                v-for="notice in notices"
                :key="notice.notice_board_id"
              >
                <div style="width: 80%;">
                  <h2>{{ notice.title }}</h2>
                  <p>{{notice.message}}</p>
                  <p style="color: grey;">
                    <small>Published by: {{ notice.staff.first_name }} {{ notice.staff.last_name }}</small>
                  </p>
                </div>
                <div style="color: grey; display: flex; justify-content: flex-end;">
                  <small>{{ formattedDate(notice.createdAt) }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import BreadCrumbComponent from "../components/bread-crumb-component";
import { format, parseISO } from "date-fns";

export default {
  components: { BreadCrumbComponent },

  data() {
    return {
      loading: false,
      responseData: "",
      notices: [],

      tableData: [],
      tableProps: {
        border: true,
        stripe: true,
      },

      filters: [
        {
          prop: "title",
          value: "",
        },
      ],
    };
  },

  computed: {
    formattedDate() {
      return (dateString) =>
        format(parseISO(dateString), "do MMM yyyy HH:mm:ss");
    },
  },

  mounted() {
    this.getNotices();
  },

  methods: {
    async getNotices() {
      try {
        this.loading = true;
        let request = await this.$http.get(`notice-board/get`);
        if (
          request.data.success &&
          request.data.message == "NOTICES_RETRIEVED_SUCEESSFULLY"
        ) {
          this.notices = request.data.notices;
        } else {
          this.$rollbar.warning("STUDENTS FRONT END: Unexpected API response", {
            response: request.data,
            request,
          });
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        this.showFailedMessage(
          "Unable to load Program categories",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.theNoticeBoard {
  background-color: #d3dce6;
  padding: 10px;
  margin: 10px;
}

.theNoticeBoard h2 {
  font-size: 18px;
  font-weight: 700;
}
</style>
