<template>
  <section class="container">
    <div class="mx-0 content row w-100">
      <div class="px-0 mb-4 text-left col-12">
        <BreadCrumbComponent :pageTitles="['Course Work', 'Course Work Instructions']" />
      </div>
      <div
        v-if="!isLoading"
        class="px-0 text-left col-12"
      >
        <div style="font-size: 20px; font-weight: 400; color: var(--el-app-primary);">
          <strong>{{ course_work.title }}</strong>
        </div>
      </div>
      <div
        v-if="!isLoading"
        class="px-0 text-left col-12"
      >
        <div style="
            font-size: 15px;
            font-weight: 400;
            color: var(--el-app-primary);
            margin-bottom: 15px;
          ">
          <!-- <strong v-if="!isLoading">{{ course_work.course_unit_code }} - {{course_workcourse_unit_title}}</strong> -->
          <div style="margin: 0px; padding: 0px">
            <div v-if="userChosenModule">
              {{userChosenModule.course_unit.title}}- {{ userChosenModule.course_unit.course_unit_code }}
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!isLoading"
        class="px-0 pb-2 mt-4 text-left col-12"
        style="font-weight: 600; font-size: 1.1em"
      >
        Course Work Instructions
      </div>
      <div
        v-if="isLoading"
        class="col-12"
        style="margin-top: 130px"
      >
        <Loading />
      </div>
      <div v-if="!isLoading">
        <CourseWorkInstructionItemComponent
          v-if="course_work.is_proctored"
          :instruction="'Your\'re also encouraged to always check your device camera, settings and more by clicking the Check Requirements button below '"
        />
        <CourseWorkInstructionItemComponent :instruction="'The Course Work consists of one Question Paper and an Answer Sheet File. They Both are available for Download.'" />

        <CourseWorkInstructionItemComponent :instruction="'Alternatively, you can also use the Online Answer Sheet to attempt your Questions.'" />
        <CourseWorkInstructionItemComponent :instruction="'In Case you need to upload multiple files, please zip them into one file and upload that one instead.'" />

        <CourseWorkInstructionItemComponent :instruction="`The Course work is only available to be taken from <b>${course_work.start_time_ug_iso} EAT</b> to <b>${course_work.end_time_ug_iso} EAT </b> After that time, it will be unavailable and to be counted as a missed course work.`" />
        <CourseWorkInstructionItemComponent :instruction="'You will be required to Upload your answer sheets before the Time expires. Once Submitted, the course work can not be done again. '" />

        <CourseWorkInstructionItemComponent :instruction="'Cheating is Strictly prohibited and may lead to disqualification or lose of marks.'" />
        <!-- <CourseWorkInstructionItemComponent :instruction="'<strong>You are hereby notified that you will be required to participate in a defense session to defend your answers for evaluators to ascertain that you did the course work on your own without external help.This defense presentation is part of the course work and it will be graded to contribute to your final mark.</strong>'" /> -->
        <CourseWorkInstructionItemComponent :instruction="'The Course Work will begin immediately the start Course Work Button is pressed.'" />
        <div
          class="row w-100 mx-0"
          v-if="course_work.instructions"
        >
          <div class="col-12 text-left pt-3">
            Other Instructions:
          </div>

          <CourseWorkInstructionItemComponent :instruction="course_work.instructions" />

        </div>

        <div
          v-if="course_work.student_course_work_submissions.length && course_work.student_course_work_submissions[0].special_submission_time_ug_iso && !course_work.student_course_work_submissions[0].has_resubmitted "
          class="col-12 text-left pt-3"
          style="font-size: .9em; font-weight: 500"
        >
          You were granted special submission upto {{course_work.student_course_work_submissions[0].special_submission_time_ug_iso}}
        </div>
      </div>
      <div
        v-if="!isLoading"
        class="pl-2 mt-4 text-center col-12"
      >
        <CheckRequirements
          v-if="course_work.is_proctored && !isStudentProctorVerified"
          :course_work="course_work"
          style="display: inline-block"
        />
        <StartCourseWorkComponent
          :course_work="course_work"
          :isStudentProctorVerified="isStudentProctorVerified"
          :studentProctorToken="studentProctorToken"
          style="display: inline-block"
          @on-loading-start="onLoadingStarted"
          @on-loading-end="onLoadingEnded"
        />
      </div>
    </div>
  </section>
</template>

<script>
import BreadCrumbComponent from "../../components/bread-crumb-component";
import CourseWorkInstructionItemComponent from "../../components/courseworks/course-work-instruction-item-component";
import StartCourseWorkComponent from "../../components/courseworks/start-course-work-component.vue";
import CheckRequirements from "../../components/courseworks/dialogs/check-requirements-dialog.vue";
import Loading from "../../components/loading.vue";
import getMonthName from "@/helpers/getMonthName";
export default {
  components: {
    BreadCrumbComponent,
    CourseWorkInstructionItemComponent,
    Loading,
    StartCourseWorkComponent,
    CheckRequirements,
  },
  data() {
    return {
      isLoading: true,
      course_work: {},
      isStudentProctorVerified: false,
      studentProctorToken: "",
    };
  },

  mounted() {
    this.getCourseWork();
    // this.isLoading = false;
  },
  computed: {
    /* 
    Since the course work may be on multiple intakes, then we need to avoid showing all modules on this course work
    as it may confuse the student i.e only show the one they clicked on the previous page and hide all the rest
    */
    userChosenModule() {
      let chosenModule = this.course_work.course_work_intakes
        ? this.course_work.course_work_intakes.find(
            (intake) => intake.course_unit_id == this.$route.params.courseUnitId
          )
        : "";

      // if (chosenModule == undefined) {
      //   this.$router.replace({ path: "/dashboard/course-work" });
      // }

      return chosenModule;
    },
  },
  methods: {
    onLoadingStarted() {
      this.isLoading = true;
    },
    onLoadingEnded() {
      this.isLoading = false;
    },
    toMonthName(monthNum) {
      return getMonthName(monthNum).toUpperCase();
    },
    async getCourseWork() {
      try {
        this.isLoading = true;

        let request = await this.$http.get(
          `course-work/fetch-course-work-instructions/${this.$route.params.courseWorkID}`
        );

        if (
          request.data.success &&
          request.data.message ==
            "COURSE_WORK INSTRUCTIONS FETCHED SUCCESSFULLY"
        ) {
          this.course_work = request.data.course_work;
          // this.isStudentProctorVerified = request.data.isStudentProctorVerified;
          // this.studentProctorToken = request.data.studentProctorToken;
        } else if (
          request.data.message === "STUDENT HAS ONLY MARKS SUBMISSION"
        ) {
          this.showWarningMessage(
            "For Marking Only",
            "Reserved For Marking Purposes Only, you're not Permitted to attempt this Course Work. Please contact the Admin for further assistance."
          );
          this.$router.replace({ path: "/dashboard/course-works" });
        } else {
          this.$rollbar.warning("STUDENTS FRONT END: Unexpected API response", {
            response: request.data,
            request,
          });
          throw "UNEXEPECTED RESPONSE";
        }
      } catch (error) {
        if (error.message === "Network Error") {
          this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        } else {
          this.showFailedMessage(
            "Unable to load course_work",
            "An unexpected Error occurred, please try again"
          );
        }

        // this.$router.replace({ path: "/dashboard/course_work" });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.content {
  margin-top: 45px !important;
}
.ui-margin {
  margin-left: 60px !important;
  margin-right: 60px !important;
}
@media (max-width: 768px) {
  .content {
    margin-top: 15px !important;
  }
}
</style>


<style>
th > .cell {
  color: rgba(0, 0, 0, 0.836) !important;
  font-weight: 600 !important;
  font-size: 13px !important;
}
el-table th > .cell {
  color: black !important;
  font-weight: 500;
}
.el-table .cell {
  font-size: 0.9em;
  line-height: 23px;
  font-weight: 300;
}
</style>