<template>
  <div class="exam-rating-dialog">
    <el-dialog
      title="How Did It Go?"
      :visible="isVisible"
      :destroy-on-close="true"
      :before-close="handleClose"
    >
      <div
        class="container-fluid"
        v-loading="loading"
      >
        <div class="row align-items-center">
          <span class="pl-4">Rate this Examination</span>
          <div class="col-12 exam-rating-stars mt-3 mb-3">
            <el-rate
              v-model="rating"
              style=""
            ></el-rate>
          </div>
        </div>
        <div class="row align-items-center mt-1">
          <label
            for=""
            class="col-12 text-left"
          >Comments</label>
          <div class="col-12">
            <el-input
              type="textarea"
              v-model="comments"
              style="margin-bottom: -0.1rem"
              class="mt-1"
              :autosize="{ minRows: 3, maxRows: 7 }"
              placeholder="Leave your comments"
              @keyup.enter="onSubmitExamRating"
            >
            </el-input>
          </div>
          <div
            class="col-12 pt-1"
            align="center"
          >
            <el-button
              class="w-100 mt-2"
              type="primary"
              @click="onSubmitExamRating"
            >
              Submit
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      comments: "",
      rating: 0,
    };
  },

  props: {
    isVisible: {
      required: true,
      type: Boolean,
    },
    submittedExaminationId: {
      type: String,
      required: true,
    },
  },

  methods: {
    clearInputs() {
      this.comments = "";
      this.rating = "";
    },
    handleClose() {
      this.clearInputs();
      this.$emit("close-dialog");
    },
    async onSubmitExamRating() {
      if (!this.comments && this.rating === 0) {
        return this.showWarningMessage(
          "Rate and Comment",
          "Please Rate and leave a Comment"
        );
      }
      if (this.rating == 0) {
        return this.showWarningMessage(
          "Missing Rating",
          "Please leave a Rating"
        );
      } else if (!this.comments) {
        return this.showWarningMessage(
          "Missing Comments",
          "Please leave a Comment"
        );
      }
      try {
        this.loading = true;
        let request = await this.$http.post(
          `examinations/students-feedback/${this.submittedExaminationId}`,
          {
            rating: this.rating,
            comment: this.comments,
          }
        );
        if (
          request.data.success &&
          request.data.message == "FEEDBACK SUBMITTED SUCCESSFULLY"
        ) {
          this.showSuccessMessage(
            "Feedback Sent",
            "Your Feedback was received successfully. Thank you"
          );
          this.handleClose();
        } else {
          this.$rollbar.warning(
            "STUDENT FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          error
          // "Unable to submit Feedback",
          // "An unexpected error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.exam-rating-dialog .el-dialog {
  width: 420px !important;
}
.exam-rating-stars .el-rate__icon {
  font-size: 30px;
}
/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .exam-rating-dialog .el-dialog {
    width: 350px !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .exam-rating-dialog .el-dialog {
    width: 350px !important;
  }
}
</style>
<style scoped></style>
