<template>
  <div
    class="container"
    style="min-height: 100vh;"
  >
    <div
      class="row"
      id="theResource"
    >
      <div class=" text-left pb-3">
        <BreadCrumbNavigation
          v-if="resource"
          :pageTitles="['My Course', 'My Content', resource.title,]"
        />
      </div>
    </div>
    <div class="float-right mr-3">
      <el-button
        style="background-color: var(--el-app-primary); color: white"
        icon="el-icon-download"
        @click="downloadResource"
        class="z-depth-0 download-btn"
      >Download</el-button>
    </div>

    <!-- VIDEO RESOURCES -->
    <div
      v-if="resource.resource_type == 'video'"
      class="row mx-0"
    >
      <span
        class="pb-4 float-left"
        style="
              font-weight: 600;
              text-transform: capitalize;
              font-size: 1.2em;
            "
      >
        {{ resource.title.toLowerCase() }}
      </span>
      <div class="col-12">
        <vue-plyr>
          <video
            controls
            playsinline
          >
            <source
              :src="resource.path_url"
              type="video/mp4"
            />
            <track
              default
              kind="captions"
              label="English captions"
              srclang="en"
            />
          </video>
        </vue-plyr>
      </div>
    </div>

    <!-- DOCUMENT RESOURCES -->
    <div
      v-else-if="resource.file_extension === 'pdf'"
      class="row mx-0 mt-5"
    >
      <div
        v-show="!isLoading"
        class="row w-100 mx-0"
      >
        <div class="col-12">
          <span
            class="pb-4 float-left"
            style="
                  font-weight: 600;
                  text-transform: capitalize;
                  font-size: 1.2em;
                "
          >
            {{ resource.title.toLowerCase() }}
          </span>
          <span class="float-right">
            <span
              class="pr-5"
              style="font-weight: 400"
            >Page <strong>{{ activePage }}</strong> of
              {{ totalPages ? totalPages : "N/A" }}</span>
            <div style="display: inline-flex">
              <div
                @click="onPreviousPage"
                class="direction_arrows"
              >
                <i class="fas fa-long-arrow-alt-left"></i>
              </div>
              <div
                @click="onNextPage"
                class="direction_arrows"
              >
                <i class="fas fa-long-arrow-alt-right"></i>
              </div>
            </div>
          </span>
        </div>
        <div class="col-12">
          <pdf
            :src="resource.path_url"
            :page="activePage"
            @num-pages="onPageNumberObtained"
            @progress="onProgress"
            @loaded="onLoaded"
            @error="onPDfError"
          ></pdf>
        </div>
        <div class="col-12 text-right">
          <span
            class="pr-5"
            style="font-weight: 400"
          >Page <strong>{{ activePage }}</strong> of
            {{ totalPages ? totalPages : "N/A" }}</span>
          <div
            class="pt-3"
            style="display: inline-flex"
          >
            <div
              @click="onPreviousPage"
              class="direction_arrows"
            >
              <i class="fas fa-long-arrow-alt-left"></i>
            </div>
            <div
              @click="onNextPage"
              class="direction_arrows"
            >
              <i class="fas fa-long-arrow-alt-right"></i>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="isLoading"
        class="col-12 text-center"
        style="margin-top: 130px"
      >
        <ScaleOut
          :background="'#1F4EA1'"
          class="mx-auto"
        />
        <div style="font-size: 0.9em; font-weight: 500">
          {{ downloadProgress }}% Loading...
        </div>
      </div>
    </div>

    <div
      class="row w-100 mx-0 text-center"
      v-else-if="resource.file_extension === 'zip'"
    >
      <div class="col-12">
        <div
          class="pt-5 pb-3"
          style="font-size: .9em"
        >Download the <b>file</b> to view the zip file contents</div>
        <img
          src="@/assets/img/zip.png"
          alt=""
          height="120px"
        />
      </div>
    </div>

    <!-- OTHER SUPPORTED DOCUMENT VIEWER LONG AS IT'S ONE OF .DOC, .DOCX, .XLS, .XLSX, .PPT, .PPTX, .ODT, .ODS, .ODP, .PDF, .TXT -->
    <div
      v-else
      class="row w-100 mx-0"
    >
      <div
        class="col-md-12 pb-4 text-left"
        style="font-weight: 600;
              text-transform: capitalize;
              font-size: 1.2em;"
      >
        {{ resource.title.toLowerCase() }}
      </div>
      <div class="col-md-12 text-left">
        <div class="">
          <iframe
            id="document_viewer_live"
            :src="`https://view.officeapps.live.com/op/embed.aspx?src=${resource.path_url}`"
            class="responsive-iframe"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Tabs, TabPane } from "element-ui";
import ScaleOut from "@/components/scale-out-component.vue";
import BreadCrumbNavigation from "../components/bread-crumb-component.vue";
import pdf from "vue-pdf";

export default {
  components: {
    pdf,
    ScaleOut,
    // "el-tabs": Tabs,
    // "el-tab-pane": TabPane,
    BreadCrumbNavigation,
  },
  data() {
    return {
      activeName: "notes",
      activePage: 1,
      progress: 0,
      downloading: false,
      isLoading: true,
      totalPages: "N/A",
      resource: this.$route.params.resource,
    };
  },

  computed: {
    downloadProgress() {
      return Math.round(this.progress * 100);
    },
  },

  mounted() {
    //If there's No resource, e.g page has reloaded, go back to previous page
    if (!this.$route.params.resource) {
      this.$router.back();
    }
  },
  methods: {
    onPDfError() {
      this.showFailedMessage(
        "loading Failed",
        "Unable to load the selected Document, please try again"
      );
      this.$router.back();
    },
    onLoaded() {
      this.isLoading = false;
    },
    onProgress(progress) {
      if (!progress) return;
      this.progress = progress;
    },
    onNextPage() {
      if (this.activePage == this.totalPages) return;
      ++this.activePage;
      window.scrollTo(0, 0);
    },
    onPreviousPage() {
      if (this.activePage == 1) return;
      --this.activePage;
      window.scrollTo(0, 0);
    },
    onPageNumberObtained(pageNumber) {
      this.totalPages = pageNumber;
    },

    async downloadResource() {
      const link = document.createElement("a");
      link.href = this.resource.path_url;
      link.download = "Resource";

      this.showSuccessMessage(
        "Download Started",
        "Your download will start shortly"
      );
      link.click();
    },

    onTabClick() { },
  },
};
</script>

<style scoped>
.direction_arrows {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: rgb(214, 226, 238);
  color: var(--el-app-primary);
  cursor: pointer;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.direction_arrows i {
  font-size: 15px;
}

.responsive-iframe {
  /* position: absolute;
    top: 0;
    left: 10px;
    bottom: 0;
    right: 10px; */
  width: 100%;
  height: 100vh;
  border: none;
}

#theResource {
  margin: 30px 15px 0px 15px;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  #theResource {
    margin: 30px 10px 0px 10px;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  #theResource {
    margin: 30px 10px 0px 10px;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .download-btn {
    width: 100%;
    margin-top: 10px;
  }

  #theResource {
    margin: 30px 0px 0px 0px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .download-btn {
    width: 100%;
    margin-top: 10px;
  }

  #theResource {
    margin: 30px 0px 0px 0px;
  }
}</style>