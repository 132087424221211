class makeApiRequest {
  constructor(vueThis) {
    this.vueThis = vueThis;
  }

  async getData(path) {
    try {
      const response = await this.vueThis.axios.get(path);
      const data = await response.data;

      if (
        response.data.success &&
        response.data.message == "HELP MESSAGES FETCHED SUCCESSFULLY"
      ) {
        return data;
      } else if (response.data.message) {
        this.vueThis.$rollbar.warning(
          "STUDENTS FRONT END: Received another message besides success",
          {
            response: data.data,
            request: data,
          }
        );

        //Throw this as an error
        throw "UNEXPECTED_RESPONSE_RECEIVED";
      } else {
        //Report the Error to an error reporting service
        this.vueThis.$rollbar.warning(
          "STUDENTS FRONT END: Unexpected API response",
          {
            response: response.data,
            request: response,
          }
        );

        //Throw this as an error
        throw "UNEXPECTED_RESPONSE_RECEIVED";
      }
    } catch (error) {
      if (error.message === "Network Error") {
        return this.vueThis.showFailedMessage(
          "Connection Failed",
          "Unable to Connect. Please check your Internet Connection and try again."
        );
      }
    }
  }

  async deleteData(path, studentData) {
    try {
      this.vueThis.$emit("delete-start", {
        isDeleting: true,
        id: studentData?.chat_message_id,
      });
      const response = await this.vueThis.axios.delete(path);
      const data = await response.data;

      if (
        response.data.success &&
        response.data.message == "MESSAGE DELETED SUCCESSFULLY"
      ) {
        this.vueThis.$emit("remove-deleted-message", {
          ...data,
          ...studentData,
          hasFailed: false,
        });
        return data;
      } else if (response.data.message) {
        this.vueThis.$rollbar.warning(
          "STUDENTS FRONT END: Received another message besides success",
          {
            response: data.data,
            request: data,
          }
        );

        //Throw this as an error
        throw "UNEXPECTED_RESPONSE_RECEIVED";
      } else {
        //Report the Error to an error reporting service
        this.vueThis.$rollbar.warning(
          "STUDENTS FRONT END: Unexpected API response",
          {
            response: response.data,
            request: response,
          }
        );

        //Throw this as an error
        throw "UNEXPECTED_RESPONSE_RECEIVED";
      }
    } catch (error) {
      this.vueThis.$emit("update-deleted-message", {
        ...studentData,
        hasFailed: true,
      });

      if (error.message === "Network Error") {
        return this.vueThis.showFailedMessage(
          "Connection Failed",
          "Unable to Connect. Please check your Internet Connection and try again."
        );
      }
    }
  }

  async postData(path, data) {
    try {
      const response = await this.vueThis.axios.post(path, data);
      const responseData = await response.data;

      if (
        responseData.success &&
        response.data.message == "HELP MESSAGE SENT SUCCESSFULLY"
      ) {
        //emit another event to update the chat with the details of the message
        this.vueThis.$emit("update-chat-message", {
          ...data,
          created_at: responseData.created_at,
          private_client_chat_id: responseData.private_client_chat_id,
          chat_message_id: responseData.chat_message_id,
          hasFailed: false,
        });
        return responseData;
      } else {
        //Report the Error to an error reporting service
        this.vueThis.$rollbar.warning(
          "STUDENTS FRONT END: Unexpected API response",
          {
            response: responseData.data,
            request: responseData,
          }
        );

        this.vueThis.$emit("update-chat-message", {
          ...data,
          created_at: responseData.created_at,
          private_client_chat_id: responseData.private_client_chat_id,
          chat_message_id: responseData.chat_message_id,
          hasFailed: true,
        });

        //Throw this as an error
        throw "UNEXPECTED_RESPONSE_RECEIVED";
      }
    } catch (error) {
      this.vueThis.$emit("update-chat-message", {
        ...data,
        hasFailed: true,
      });

      if (error.message === "Network Error") {
        return this.vueThis.showFailedMessage(
          "Connection Failed",
          "Unable to Connect. Please check your Internet Connection and try again."
        );
      }
    }
  }
}
//singleton for making api requests
export default makeApiRequest;
