<template>
    <div class="course-summary-banner">
        <div v-if="courseInfo.course">
            <h1
                class="text-white"
                style="font-weight: 500 !important"
            >
                <strong>{{ courseInfo.course.course_name }}</strong>
            </h1>
            <span><small style="font-size: 14px">Intake:
                    <span class="text-grey">{{ toMonthName(courseInfo.month) }} Intake
                        {{ courseInfo.year }} / {{ courseInfo.cohort }}</span></small></span><br />
            <span><small style="font-size: 14px">Duration:
                    <span class="text-grey">{{ courseInfo.course.duration_of_study }} years</span></small></span><br />
            <span>
                <small style="font-size: 14px">Modules:
                    <span
                        class="text-grey"
                        v-if="courseInfo"
                    >
                        {{ courseInfo.course_units.length }} Module{{ courseInfo.course_units.length == 1 ? "" : "s" }}
                    </span>
                </small>
            </span>
        </div>
        <div>
            <img src="@/assets/img/students1.png" />
        </div>
    </div>
</template>

<script>
import getMonthName from "@/helpers/getMonthName";
export default {
    name: 'CourseSummaryBanner',
    props: {
        courseInfo: {
            type: Object,
            required: true
        }
    },
    methods: {
        toMonthName(monthInt) {
            return getMonthName(monthInt, true);
        },
    }
}

</script>

<style scoped>
.course-summary-banner {
    text-align: left;
    background-color: var(--el-app-primary);
    color: white;
    padding: 25px;
    border-radius: 5px 5px 0px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 180px;
}

.course-summary-banner h1 {
    font-weight: 500;
    font-size: 20px;
    color: var(--el-app-primary);
}

.course-summary-banner img {
    width: 150px;
}


/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .course-summary-banner {
    padding: 12px 0px 0px 12px;
    border-radius: 10px 10px 0px 10px;
  }

  .course-summary-banner h1 {
    font-weight: 500;
    font-size: 15px;
  }

  .course-summary-banner p {
    font-weight: 300;
    font-size: 10px;
  }

  .course-summary-banner img {
    width: 100px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .course-summary-banner {
    padding: 12px 0px 0px 12px;
    border-radius: 10px 10px 0px 10px;
  }

  .course-summary-banner h1 {
    font-weight: 500;
    font-size: 15px;
  }

  .course-summary-banner p {
    font-weight: 300;
    font-size: 10px;
  }

  .course-summary-banner img {
    width: 100px;
  }

}
</style>