import { CHATS_SSE_BASE_URL } from "../../../helpers/constants";
class serverEventHandler {
  constructor(messageId) {
    this.serverUrl = `${CHATS_SSE_BASE_URL}/chat-messages-activity-stream/${messageId}`;
    this.eventSource = new EventSource(this.serverUrl);
  }

  listen(eventName, callback) {
    this.eventSource.addEventListener(eventName, callback);
  }

  reconnect() {
    this.eventSource.close();
    this.eventSource = new EventSource(this.serverUrl);
  }

  close() {
    this.eventSource.close();
  }
}

export default serverEventHandler;
