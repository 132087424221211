<template>
  <div
    class="main-container"
    id="main-chat-container"
  >
    <Chat
      :messages="[...allChatMessages].reverse()"
      :areChatsAvailable="areChatsAvailable"
      :isLoading="isLoading"
      :failedMessages="failedMessages"
      :failedToDeleteMessage="failedToDeleteMessage"
      :isDeleting="isDeleting"
      :newMessage="newMessage"
      @add-message="addUserChatMessage"
      @update-chat-message="updateUserChat"
      @gotMoreMessages="updateUserMessages"
      @remove-failed-message="removeFailedMessage"
      @remove-deleted-message="removeDeletedMessage"
      @update-deleted-message="updateDeletedMessage"
      @delete-start="deleteStart"
      @update-chatId="updateChatId"
    />

  </div>

</template>


<script >
import Chat from "../components/chats/chat-component.vue";
import makeApiRequest from "../components/helpers/request-handlers/requestsHandler";
import { checkIfItemIsInArray } from "../components/helpers/searchFunctions";
import { sortArrayByDate } from "../components/helpers/sortFunctions";
import serverEventHandler from "../components/helpers/request-handlers/serverEventHandler";

export default {
  name: "student-chat",
  components: {
    Chat,
  },
  data() {
    return {
      textInput: "",
      messagesId: "",
      serverEventListener: null,
      isLoading: true,
      areChatsAvailable: true,
      failedToDeleteMessage: false,
      requestsHandler: {},
      isDeleting: { loading: false, id: "" },
      failedMessages: {},
      newMessage: {},
      allChatMessages: [],
    };
  },
  methods: {
    deleteStart(args) {
      this.isDeleting.loading = true;
      this.isDeleting.id = args.id;
    },
    updateDeletedMessage(message) {
      if (message.hasFailed) {
        this.failedMessages[message.chat_message_id] = message;
        this.allChatMessages.forEach((dataArray) => {
          if (dataArray.chat_message_id === message.chat_message_id) {
            dataArray.hasFailed = message.hasFailed;
            dataArray.showDeleteFailure = true;
          }
        });
        this.isDeleting.loading = false;
        return;
      }
    },
    removeDeletedMessage(message) {
      const messageId = message?.chat_message_id;
      this.allChatMessages = this.allChatMessages.filter(
        (message) => message.chat_message_id != messageId
      );
      this.isDeleting.loading = false;
      this.failedToDeleteMessage = false;
    },
    addUserChatMessage(args) {
      // check if this.hasReceivedNewMessage is in all the chats
      const { isFound } = checkIfItemIsInArray(
        this.allChatMessages,
        args,
        "private_client_chat_id"
      );

      if (!isFound) {
        this.allChatMessages.push(args);
      }
    },
    updateUserChat(args) {
      this.allChatMessages.forEach((dataArray) => {
        if (dataArray.private_client_chat_id === args.private_client_chat_id) {
          dataArray.chat_message_id = args.chat_message_id;
          dataArray.created_at = args.created_at;
          dataArray.hasFailed = args.hasFailed;

          if (args.hasFailed) {
            this.failedMessages[args.private_client_chat_id] = args;
            dataArray.showFailedToSend = true;
          }
        }
      });
    },
    updateUserMessages(args) {
      this.allChatMessages = [...args, ...this.allChatMessages];
    },
    removeFailedMessage(id) {
      delete this.failedMessages[id];
    },
    updateChatId(args) {
      this.messagesId = args.chat_conversation_id;
    },
  },
  computed: {
    sortMessagesByDate() {
      return sortArrayByDate(this.allChatMessages, "created_at");
    },
  },

  async mounted() {
    this.requestsHandler = new makeApiRequest(this);
    //get all the chat messages from the endpoint
    const studentMessages = await this.requestsHandler.getData(
      "/chats/students/help/messages"
    );
    this.allChatMessages = studentMessages?.chat_messages || [];
    this.isLoading = false;
    this.messagesId = studentMessages?.chat_conversation_id || "";
  },
  watch: {
    allChatMessages() {
      if (this.allChatMessages?.length > 0) {
        this.areChatsAvailable = true;
      } else {
        this.areChatsAvailable = false;
      }
    },
    messagesId() {
      if (this.messagesId) {
        this.serverEventListener = new serverEventHandler(this.messagesId);

        this.serverEventListener.listen("NEW_MESSAGE", (event) => {
          const data = JSON.parse(event.data);
          const chat = {
            ...data,
            sender: data.user,
            chat_message_id: data.enc_chat_message_id,
          };
          this.addUserChatMessage(chat);
          this.newMessage = chat;
        });

        this.serverEventListener.listen("MESSAGE_DELETED", (event) => {
          const data = JSON.parse(event.data);
          const chat = {
            ...data,
            chat_message_id: data.enc_chat_message_id,
            chat_conversation_id: data.enc_chat_conversation_id,
          };
          this.removeDeletedMessage(chat);
        });
      }
    },
  },
  beforeDestroy() {
    this.serverEventListener.close();
  },
};
</script>


<style scoped>
#main-chat-container {
  width: 90%;
  height: 95vh;
}

.remove-on-mobile {
  display: block;
}

/* breakpoints for mobile */
@media (max-width: 768px) and (max-width: 1024px) {
  .remove-on-mobile {
    display: none;
  }
  #chat-messages-container {
    width: 100%;
    overflow: auto;
  }

  .chat-messages {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  #chat-messages-container {
    width: 100%;
  }

  .main-container {
    width: 100%;
  }

  #main-chat-container {
    width: 100%;
  }
}
</style>