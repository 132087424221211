<template>
  <section class="container my-calendar">
    <div class="content">
      <div
        v-if="!loading && !loadingError"
        class="mx-0 row w-100"
      >
        <div class="px-0 col-12">
          <div class="mb-4 row w-100">
            <div class="px-0 pl-3 mb-0 text-left col-12">
              <BreadCrumbComponent :pageTitles="['My Calendar']" />
            </div>
          </div>
          <div class="row w-100">
            <div class="text-left col-12">
              <div style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;
                ">
                <strong>My Time Table</strong>
              </div>
            </div>
          </div>
          <div class="mx-1 row w-100">
            <div class="col-12 intro-card">
              <div class="px-3 py-4 row w-100">
                <div class="text-left col-md-9">
                  <div class="intro-heading">Hello {{ studentFirstName }}</div>
                  <div class="intro-content">
                    Welcome to your Weekly Time Table. Here, you can see all
                    your upcoming events and activities such as Lectures.
                  </div>
                </div>
                <div class="col-md-3">
                  <img
                    src="../assets/img/students1.png"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div class="mx-0 row w-100">
              <div class="pl-0 pr-4 mt-5 text-left col-md-9">
                <span style="font-weight: 600">{{ formattedDate(weekStartDate) }} -
                  {{ formattedDate(weekEndDate) }}</span>
                <span
                  @click="onChangeWeekIntent"
                  class="float-right"
                  style="
                    font-size: 0.8em;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.6);
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    margin-left: 8px;
                    padding: 2px 8px;
                    position: relative;
                    bottom: 4px;
                    border-radius: 3px;
                    cursor: pointer;
                  "
                >
                  <i
                    class="pr-1 fas fa-calendar"
                    style="color: purple"
                  ></i>
                  Change Week
                </span>

                <span
                  class="float-right"
                  style="
                    font-size: 0.8em;
                    color: rgba(0, 0, 0, 0.6);
                    position: relative;
                    bottom: 4px;
                  "
                >
                  <div
                    @click="onWeekForwardBackward(+1)"
                    class="text-center chevron-right-box"
                  >
                    <i class="text-center fas fa-chevron-right"></i>
                  </div>
                </span>
                <span
                  class="float-right"
                  style="
                    font-size: 0.8em;
                    color: rgba(0, 0, 0, 0.6);
                    position: relative;
                    bottom: 4px;
                  "
                >
                  <div
                    @click="onWeekForwardBackward(-1)"
                    class="text-center chevron-left-box"
                    style=""
                  >
                    <i class="text-center fas fa-chevron-left"></i>
                  </div>
                </span>

                <DetailedCalendarComponent
                  class="d-none d-md-flex"
                  :lectures="lectures"
                  :weekStartDate="weekStartDate"
                  :weekEndDate="weekEndDate"
                  :dateToday="dateToday"
                />
              </div>
              <div class="px-0 col-md-3">
                <div
                  class="mx-0 mt-5 row w-100"
                  style=""
                >
                  <div
                    v-show="!loading && !loadingError && lectures.length !== 0"
                    class="pb-3 col-12"
                    style="font-weight: 600"
                  >
                    Events this Week
                  </div>
                  <EventDetailsCardComponent
                    v-for="(lecture, index) in lectures"
                    :key="index"
                    :event="{ type: 'Lecture', data: lecture }"
                    :dateToday="dateToday"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="!loading && !loadingError && lectures.length === 0"
              class="row w-100"
            >
              <div
                class="col-12"
                style="margin-top: 80px"
              >
                <img
                  src="../assets/img/no-content.png"
                  alt=""
                  style="height: 100px"
                />
                <div
                  class="pt-3"
                  style="
                    font-weight: 500;
                    font-size: 0.9em;
                    color: rgba(0, 0, 0, 0.45);
                  "
                >
                  There are No Events to display
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="loading"
        class="row w-100"
      >
        <div class="col-12">
          <ScaleOut
            style="margin-top: 150px"
            :background="'#1F4EA1'"
          />
          <div
            class="mt-3"
            style="font-weight: 500; font-size: 0.9em"
          >
            Fetching Student Time Table
          </div>
          <div style="font-weight: 500; font-size: 0.9em">Please Wait...</div>
        </div>
      </div>

      <div
        v-if="!loading && loadingError"
        class="col-12"
        style="margin-top: 120px"
      >
        <span style="font-weight: 600">Unable to get Time Table</span>
        <div
          class="pt-2"
          style="font-size: 0.9em"
        >
          Failed to get Time Table Information right now. Click below to retry
        </div>
        <div class="mt-1 col-12">
          <button
            type="button"
            class="btn z-depth-0"
            @click="fetchStudentCalendar"
            v-ripple="'rgba(255, 255, 255, 0.35)'"
            style="
              width: 250px;
              text-transform: capitalize;
              height: 45px;
              border-radius: 4px;
              background-color: red;
              font-weight: 500;
            "
          >
            <span style="position: relative; bottom: 1px">Try Again</span>
          </button>
        </div>
      </div>
    </div>
    <el-dialog
      title="Change Week"
      :visible.sync="showChangeMonthModal"
      width="30%"
      :before-close="onBeforeClose"
    >
      <div class="row w-100">
        <div class="col-12">
          <el-date-picker
            ref="datePicker"
            v-model="value1"
            type="week"
            format="Week WW"
            placeholder="Pick a week"
            class="text-center"
            :clearable="false"
            @change="onChangeWeek"
          >
          </el-date-picker>
        </div>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import BreadCrumbComponent from "../components/bread-crumb-component";
import DetailedCalendarComponent from "../components/detailed-calendar-component";
import EventDetailsCardComponent from "../components/event-details-card-component";
import ScaleOut from "@/components/scale-out-component.vue";
import { format, parseISO, startOfWeek, addWeeks } from "date-fns";
export default {
  components: {
    BreadCrumbComponent,
    DetailedCalendarComponent,
    EventDetailsCardComponent,
    ScaleOut,
  },
  data() {
    return {
      value1: "",
      lectures: [],
      week: "",
      studentFirstName: "",
      weekStartDate: "",
      weekEndDate: "",
      dateToday: "2019-03-16",
      loading: true,
      showChangeMonthModal: false,
      loadingError: false,
    };
  },
  computed: {
    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMMM yyyy");
    },
  },
  mounted() {
    this.weekStartDate = this.$route.params.startDate || "";
    this.fetchStudentCalendar();
  },
  methods: {
    onChangeWeek() {
      this.weekStartDate = format(startOfWeek(this.value1), "yyyy-MM-dd");
      this.showChangeMonthModal = false;
      window.scrollTo(0, 0);
      this.fetchStudentCalendar();
    },

    onWeekForwardBackward(direction) {
      this.weekStartDate = format(
        addWeeks(parseISO(this.weekStartDate), direction),
        "yyyy-MM-dd"
      );
      window.scrollTo(0, 0);
      this.fetchStudentCalendar();
    },

    onChangeWeekIntent() {
      this.showChangeMonthModal = true;
    },
    onBeforeClose(done) {
      done();
    },
    async fetchStudentCalendar() {
      this.loading = true;
      this.loadingError = false;
      try {
        let request = await this.$http.get(
          `calendar/student/${this.$store.state.userId}?start_date=${this.weekStartDate}`
        );
        if (
          request.data.success &&
          request.data.message == "LIVE_LECTURES_RETRIEVED"
        ) {
          this.lectures = request.data.lectures;
          this.studentFirstName = request.data.student_first_name;
          this.weekStartDate = request.data.week_start_date;
          this.weekEndDate = request.data.week_end_date;
          this.dateToday = request.data.date_today;
        } else if (request.data.message == "STUDENT_NOT_FOUND") {
          this.showFailedMessage(
            "Account Not Found",
            "Student Account Not found. Redirecting to login now"
          );
          this.$store.commit("LOGOUT_USER");
          this.$router.replace({ path: "/login" });
        } else if (request.data.message == "STUDENT_HAS_NO_BATCH") {
          this.showFailedMessage(
            "No Student Diet Found",
            "No Student Diet found. Redirecting to login now"
          );
          this.$store.commit("LOGOUT_USER");
          this.$router.replace({ path: "/login" });
        } else {
          this.$rollbar.warning("STUDENTS FRONT END: Unexpected API response", {
            response: request.data,
            request,
          });
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        this.loadingError = true;
        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }
        this.showFailedMessage(
          "Fetching Failed",
          "Unable to fetch Time Table Now, Please try again"
        );
        
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.content {
  margin-top: 45px !important;
}

.intro-card {
  background-color: var(--el-app-primary);
  border-radius: 19px 19px 0px 19px;
}

.intro-heading {
  color: rgba(255, 255, 255, 0.918);
  /* padding-top: 20px; */
  font-weight: 500;
  font-size: 1.3em;
}

.intro-content {
  color: rgba(255, 255, 255, 0.74);
  font-size: 0.9em;
  padding-top: 10px;
}
.fa-chevron-left,
.fa-chevron-right {
  color: rgba(0, 0, 0, 0.4);
  position: relative;
  top: 3px;
}

.chevron-right-box {
  width: 35px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0px 3px 3px 0px;
  height: 26px;
  cursor: pointer;
}

.chevron-left-box {
  width: 35px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px 0px 0px 3px;
  height: 26px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .content {
    margin-top: 15px !important;
  }
}
</style>


<style>

.el-date-editor--week .el-input__inner,
.el-range-editor .el-input__inner {
  cursor: pointer;
  height: 40px !important;
  width: 100% !important;
  padding-left: 45px !important;
}

.my-calendar .el-dialog__title {
  font-size: 1em;
  font-weight: 500;
}
</style>