<template>
  <div class="popup-dialog text-left">
    <el-dialog
      :title="title"
      :visible="isVisible"
      :before-close="handleClose"
    >
      <div class="container ">
        <div class="content m-0">
          <div v-html="content"></div>
          <div class="row w-100 mx-0">
            <div class="col-12 text-right px-0">
              <button
                type="button"
                class="mx-0 mt-3 btn z-depth-0 close-btn"
                v-ripple="'rgba(255, 255, 255, 0.35)'"
                @click="$emit('close-dialog')"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
    },

    content: {
      required: true,
      type: String,
    },

    title : {
      required: true,
      type: String,
    }
  },

  methods: {
    handleClose() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style scoped>
.close-btn {
  width: 130px;
  border-radius: 4px;
  height: 43px;
  padding: 0px !important;
  background-color: var(--el-app-primary);
  text-transform: capitalize;
  font-size: 16px;
}
</style>

<style>
.popup-dialog div {
  word-break: break-word !important;
}

.popup-dialog .el-dialog__title {
  font-weight: 600;
}

.popup-dialog .bolden {
  font-weight: 500;
}

.popup-dialog .el-dialog {
  width: 60%;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .popup-dialog .el-dialog {
    width: 50%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .popup-dialog .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .popup-dialog .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .popup-dialog .el-dialog {
    width: 90%;
  }
}
</style>

<style scoped>
.container {
  padding-top: 0 !important;
}

.content {
  /* white-space:pre-wrap; */
}
</style>