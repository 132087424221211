import { render, staticRenderFns } from "./my-calendar.vue?vue&type=template&id=37553973&scoped=true"
import script from "./my-calendar.vue?vue&type=script&lang=js"
export * from "./my-calendar.vue?vue&type=script&lang=js"
import style0 from "./my-calendar.vue?vue&type=style&index=0&id=37553973&prod&scoped=true&lang=css"
import style1 from "./my-calendar.vue?vue&type=style&index=1&id=37553973&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37553973",
  null
  
)

export default component.exports