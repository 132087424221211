<template>
  <div>
    <div class="search_add_section">
      <div class="">
        <input
          type="text"
          v-model.trim="filters[0].value"
          placeholder="Search by Title"
          class="search_by_input"
          spellcheck="off"
        />
      </div>
    </div>
    <hr />

    <article>
      <section>
        <div class="table-responsive">
          <data-tables
            :data="courseworks" 
            :table-props="tableProps" 
            :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }" 
            :filters="filters"
            type="expand"
          >
            <div slot="empty">
              <ScaleOut
                v-if="loading && !loadingError"
                class="mt-3"
                :background="'#164B70'"
              />
              <div
                @click="fetchAvailableCourseWorks"
                v-else-if="!loading && loadingError"
                style="cursor: pointer"
              >
                Unable to Load Course Work Now. Please click here to retry
              </div>
              <div v-if="!loading && !loadingError">
                No Course Work at the moment
              </div>
            </div>

            <el-table-column
              label="#"
              :show-overflow-tooltip="true"
              label-class-name="text-center"
              class-name="text-center"
              width="80px"
            >
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>

            <el-table-column
              label="Title"
              :show-overflow-tooltip="true"
              label-class-name="text-left"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.course_work.title }}</span>
              </template>
            </el-table-column>

            <el-table-column
              label="Duration."
              :show-overflow-tooltip="true"
              label-class-name="text-left"
            >
              <template slot-scope="scope">
                <small
                  style="
                    color: #000;
                    padding: 0px 10px;
                    color: rgba(0, 0, 0, 0.6);
                  "
                  class="pb-1 text-left d-block text-truncate"
                >
                  <i class="fas fa-check"></i>
                  <strong>Start Date & Time: </strong>
                  <br />
                  {{ scope.row.course_work.start_time_ug_iso }} EAT
                </small>
                <small
                  style="
                    color: #000;
                    padding: 0px 10px;
                    color: rgba(0, 0, 0, 0.6);
                  "
                  class="pb-1 text-left d-block text-truncate"
                >
                  <i class="fas fa-check"></i>
                  <strong>End Date & Time: </strong>
                  <br />
                  {{ scope.row.course_work.end_time_ug_iso }} EAT
                </small>
              </template>
            </el-table-column>

            <el-table-column
              label="Course Unit Code"
              :show-overflow-tooltip="true"
              label-class-name="text-left"
              width="150px"
            >
              <template slot-scope="scope">
                <div
                  class="mt-4 text-left col-12 text-truncate"
                  style="
                    color: var(--el-app-primary);
                    text-transform: capitalize;
                  "
                >
                  <strong>{{ scope.row.course_unit.course_unit_code }}</strong>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              label="Status"
              :show-overflow-tooltip="true"
              label-class-name="text-left"
              width="auto"
            >
              <template slot-scope="scope">
                <small
                  v-if="missedCourseWork(scope.row.course_work)"
                  style="color: rgba(255, 0, 0, 0.8); padding: 0px 10px"
                  class="pb-1 text-left d-block text-truncate"
                >
                  <i class="fas fa-close"></i> You Missed this Course Work
                </small>
                <div v-if="neverCompleted(scope.row.course_work)">
                  <small
                    style="padding: 0px 10px"
                    class="pb-1 text-left d-block text-truncate text-warning"
                  >
                    <i class="fas fa-warning"></i> Started, Never Submitted
                  </small>
                </div>

                <small
                  v-if="courseWorkSubmitted(scope.row.course_work)"
                  style="color: #67c23a; padding: 0px 10px"
                  class="pb-1 text-left d-block text-truncate"
                >
                  <i class="fas fa-check"></i> Already Submitted
                </small>
              </template>
            </el-table-column>

            <!-- <el-table-column
              label="Actions"
              :show-overflow-tooltip="true"
              label-class-name="text-left"
              width="100px"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.cr_amount }}</span>
              </template>
            </el-table-column> -->
          </data-tables>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ScaleOut from "@/components/scale-out-component.vue";
import { format, parseISO } from "date-fns";

export default {
  components: { ScaleOut },
  mixins: [validationMixin],

  data() {
    return {
        loading: false,
        loadingError: false,
        courseworks: [],
        tableProps: {
            border: true,
            stripe: true,
        },

        filters: [
            {
                prop: "title",
                value: "",
            },
        ],
    };
  },

  computed: {
    missedCourseWork() {
      return (course_work) => {
        const hasSubmission = !!course_work.student_course_work_submissions[0];
        return course_work.is_course_work_ended && !hasSubmission;
      };
    },

    courseWorkSubmitted() {
      return (course_work) => {
        const hasSubmission = !!course_work.student_course_work_submissions[0];
        return (
          hasSubmission &&
          !!course_work.student_course_work_submissions[0].submitted_at
        );
      };
    },

    neverCompleted() {
      return (course_work) => {
        const hasSubmission = !!course_work.student_course_work_submissions[0];
        return (
          hasSubmission &&
          !course_work.student_course_work_submissions[0].submitted_at
        );
      };
    },

    formattedDate() {
      return (dateString) => {
        if (!dateString) return "";
        return format(parseISO(dateString), "do MMMM yyyy");
      };
    },

    getInitials() {
      return (nameString) => {
        //  format(parseISO(dateString), "do MMMM yyyy");
        var names = nameString.split(" "),
          initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
          initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      };
    },
  },

  validations: {
    title: {
      required,
    },
    title_edit: {
      required,
    },
  },

  mounted() {
    this.fetchAvailableCourseWorks();
  },

  methods: {
    async fetchAvailableCourseWorks() {
      try {
        //If we havent been loading yet, let's Load
        if (!this.isFetching) {
          this.isFetching = true;
          this.isError = false;
        }

        let request = await this.$http.get(
          `course-work/fetch-all-for-student/${this.$store.state.userId}`
        );
        if (
          request.data.success &&
          request.data.message == "STUDENT COURSE WORKS RETRIEVED"
        ) {
          this.courseworks = request.data.course_works;
          this.isFetching = false;
          this.isError = false;
        } else {
          this.$rollbar.warning("STUDENTS FRONT END: Unexpected API response", {
            response: request.data,
            request,
          });
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        // eslint-disable-line no-unused-vars
        this.isFetching = false;
        this.isError = true;

        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        console.log(error);

        this.showFailedMessage(
          "Fetching failed",
          "Unable to fetch Course Work now, please try again"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

input:focus {
  border: 0.01em solid #409eff !important;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
.faculty_dialogs .el-dialog {
  width: 40%;
}

.search_by_input {
  width: 400px !important;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .faculty_dialogs .el-dialog {
    width: 50%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .faculty_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .faculty_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .faculty_dialogs .el-dialog {
    width: 90%;
  }
}
</style>
