<template>
  <div class="container-fluid">
    <div class="row w-100">
      <div class="row w-100 TabpaddingTop">
        <div class="pb-3 w-100 text-left">
          <BreadCrumbNavigation :pageTitles="[
              'My Modules',
              courseUnitName ? courseUnitName : '...',
            ]" />
        </div><br/>

        <div class="mt-4 mb-4">
          <div class="">
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <span style="color: grey; font-weight: 600; font-size: 1.2em;">
                Resources for 
                {{ courseUnitName ? `${courseUnitName} (${course_unit_code})`:'...', }} 
              </span>
            </div>
          </div>
        </div>

        <!-- RESOURCES PRELOADER -->
        <div
          v-if="isLoading && !isLoadingError"
          class="col-12"
        >
          <ResourcesPreloader />
        </div>

        <!-- TABS SECTION -->
        <div
          v-if="!isLoading && !isLoadingError && !currentResource"
          class="mx-0 row w-100"
        >
           <DocumentResources
              @on-select-resource="onSelectResource"
              :resources="resources"
              :title="'Module Resources'"
            />
        </div>

        <!-- ERROR SECTION FOR LOADING ERRORS -->
        <div
          v-if="!isLoading && isLoadingError"
          class="mt-5 col-12"
        >
          <span style="font-weight: 600">Unable to get Resources</span>
          <div
            class="pt-2"
            style="font-size: 0.9em"
          >
            Failed to get Resources right now. Click below to retry
          </div>
          <div class="mt-1 col-12">
            <button
              type="button"
              class="btn z-depth-0"
              @click="loadCourseUnitResources"
              v-ripple="'rgba(255, 255, 255, 0.35)'"
              style="
                width: 250px;
                text-transform: capitalize;
                height: 45px;
                border-radius: 4px;
                background-color: red;
                font-weight: 500;
              "
            >
              <span style="position: relative; bottom: 1px">Try Again</span>
            </button>
          </div>
        </div>
      </div>

      <!-- {{ videoResources }} -->

    </div>
  </div>
</template>

<script>
// import { Tabs, TabPane } from "element-ui";
import DocumentResources from "../components/course-unit-resources/document-resources.vue";
// import VideoResources from "../components/course-unit-resources/video-resources.vue";
import BreadCrumbNavigation from "../components/bread-crumb-component.vue";
import ResourcesPreloader from "../components/course-unit-resources/resources-preloader.vue";
export default {
  components: {
    // "el-tabs": Tabs,
    // "el-tab-pane": TabPane,
    DocumentResources,
    BreadCrumbNavigation,
    ResourcesPreloader,
    // VideoResources,
  },
  data() {
    return {
      activeName: "notes",
      currentResource: "",
      activePage: 1,
      resources: [],
      isLoading: true,
      isLoadingError: false,
      courseName: "",
      courseUnitName: "",
      course_unit_code: ""
    };
  },

  computed: {
    notesResources() {
      if (this.resources && this.resources.length == 0) return [];
      return this.resources.filter(
        (resource) => resource.resources[0].resource_type === "notes"
      );
    },
    videoResources() {
      if (this.resources && this.resources.length == 0) return [];
      return this.resources.filter(
        (resource) => resource.resources.resource_type === "video"
      );
    },
    pastPaperResources() {
      if (this.resources && this.resources.length == 0) return [];
      return this.resources.filter(
        (resource) => resource.resources.resource_type === "past-papers"
      );
    },
  },

  mounted() {
    this.courseName = this.$route.params.courseName;
    this.courseUnitName = this.$route.params.courseUnitName;
    this.loadCourseUnitResources();
  },

  methods: {
    onSelectResource(resource) {
      if (resource.is_available == true) {
        this.$router.push({ name: "ResourceDetails", params: { resource } });
        this.currentResource = resource;
      } else {
        this.showWarningMessage(
          "Not available",
          "Unable to reach this Resource now"
        );
      }
    },

    async loadCourseUnitResources() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        let request = await this.$http.get(
          `resources/fetch-by-course-unit/${this.$route.params.courseUnitId}`
        );
        if (
          request.data.success &&
          request.data.message === "COURSE_UNIT_RESOURCES RETRIEVED SUCCESSFULLY"
        ) {
          this.resources = request.data.course_unit_resources;
          this.courseUnitName = request.data.course_unit.title;
          this.course_unit_code = request.data.course_unit.course_unit_code;
        } else {
          this.$rollbar.warning("STUDENTS FRONT END: Unexpected API response", {
            response: request.data,
            request,
          });
          throw "UNEXPECTED_RESPONSE_RECEIVED";
        }
      } catch (error) {
        this.isLoadingError = true;

        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }
        this.showFailedMessage(
          "Failed",
          "Unable to fetch Resources now, please try again"
        );
      } finally {
        this.isLoading = false;
      }
    },

    onTabClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style scoped>
.direction_arrows {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: rgb(214, 226, 238);
  color: var(--el-app-primary);
  cursor: pointer;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.direction_arrows i {
  font-size: 15px;
}

#the-tab-section {
  width: 80%;
  position: fixed;
  background-color: #F6F6F6;
  z-index: 100;
  top: 60px;
}

#the-tab {
  width: 50%;
}

.TabpaddingTop {
  margin: 50px 27px 0px 27px;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  #the-tab-section {
    width: 100%;
  }

  #the-tab {
    width: 100%;
  }

  .TabpaddingTop {
    margin: 50px 10px 0px 10px;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  #the-tab-section {
    width: 100%;
  }

  #the-tab {
    width: 100%;
  }

  .TabpaddingTop {
    margin: 50px 10px 0px 10px;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  #the-tab-section {
    width: 100%;
  }

  #the-tab {
    width: 100%;
  }

  .TabpaddingTop {
    margin: 50px 10px 0px 10px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  #the-tab-section {
    width: 100%;
  }

  #the-tab {
    width: 100%;
  }

  .TabpaddingTop {
    margin: 50px 5px 0px 5px;
  }
}
</style>