// export default `<div>
// <div class="mceNonEditable">
//     <p>&nbsp;</p>
//     <p style="text-align: center;"><strong><img class="hide_on_submission" src="https://vclass.ac/img/vu-logo-with-words.ac2cb230.png"
//                 alt="VLogo" width="269" height="115" /></strong></p>
//     <h2 style="text-align: center;"><strong class="hide_on_submission">Examination Online Answer Book</strong></h2>
//     <h2 style="text-align: center;"><strong class="hide_on_submission">UNIVERSITY EXAMS 2022 / 2023</strong></h2>
// </div>
// <table border="1" cellspacing="0" cellpadding="0">
//     <tbody>
//         <tr>
//             <td colspan="9" width="638" class="mceNonEditable">
//                 <p>&nbsp;<strong>NUMBER OF QUESTIONS</strong> you have answered in the order in which you have
//                     written them</p>
//             </td>
//         </tr>
//         <tr>
//             <td width="71">&nbsp;</td>
//             <td width="71">&nbsp;</td>
//             <td width="71">&nbsp;</td>
//             <td width="71">&nbsp;</td>
//             <td width="71">&nbsp;</td>
//             <td width="71">&nbsp;</td>
//             <td width="71">&nbsp;</td>
//             <td width="71">&nbsp;</td>
//             <td width="71">&nbsp;</td>
//         </tr>
//     </tbody>
// </table>
// <div>
// <p>&nbsp;</p>
// <hr/>
// <h2 style="text-align: center;"><strong>ANSWERS HERE</strong></h2>
//     <p>&nbsp;</p>
//     <p>&nbsp;</p>
//     <p>&nbsp;</p>
// </div>
// </div>`;

export default "";
