import { render, staticRenderFns } from "./resource-details.vue?vue&type=template&id=1d578f60&scoped=true"
import script from "./resource-details.vue?vue&type=script&lang=js"
export * from "./resource-details.vue?vue&type=script&lang=js"
import style0 from "./resource-details.vue?vue&type=style&index=0&id=1d578f60&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d578f60",
  null
  
)

export default component.exports