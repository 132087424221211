import { render, staticRenderFns } from "./todays-schedule-component.vue?vue&type=template&id=aaee4020&scoped=true"
import script from "./todays-schedule-component.vue?vue&type=script&lang=js"
export * from "./todays-schedule-component.vue?vue&type=script&lang=js"
import style0 from "./todays-schedule-component.vue?vue&type=style&index=0&id=aaee4020&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaee4020",
  null
  
)

export default component.exports