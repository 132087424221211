<template>
  <div
    class="mx-auto available-tests-exams row"
    style="width: 100%"
  >
    <div
      class="pl-0 pr-0 text-left col-12"
      style="font-weight: 600"
    >
      Available Examinations
    </div>

    <div
      v-if="!isFetching && !isError && examinations.length == 0"
      class="mt-4 text-left col-12"
      style="font-weight: 400; font-size: 0.9em"
    >
      <el-empty description=" You have No Available Examinations at the Moment."></el-empty>

    </div>

    <div class="pr-0 mt-1 text-right col-12"></div>
    <div
      v-if="isError"
      class="px-0 col-12"
      style="height: 180px"
    >
      <div style="font-weight: 600; margin-top: 50px">Fetching Failed</div>
      <div
        class="pt-2"
        style="font-weight: 400; font-size: 0.9em"
      >
        Failed to fetch Examinations now. Please try again
      </div>
      <button
        type="button"
        class="mx-0 mt-3 btn z-depth-0"
        v-ripple="'rgba(255, 255, 255, 0.35)'"
        @click="fetchAvailableExams"
        style="
          width: 220px;
          text-transform: capitalize;
          height: 45px;
          border-radius: 4px;
          background-color: red;
          font-weight: 500;
        "
      >
        <span style="position: relative; bottom: 1px"><i class="pr-2 fas fa-refresh"></i> Try Again</span>
      </button>
    </div>
    <div
      v-show="isFetching && !isError"
      class="px-0 mt-3 col-12"
    >
      <carousel
        ref="preloaderCarouselRef"
        :autoWidth="true"
        :dots="false"
      >
        <div
          v-for="i in [1, 2, 3, 4, 5, 6]"
          :key="i"
          class="mr-3 skeleton-block skeleton-effect-wave"
          style="width: 230px; height: 400px; border-radius: 5px"
          :navText="[
            `<i class='fas fa-chevron-left'></i>`,
            `<i class='fas fa-chevron-right'></i>`,
          ]"
        ></div>
      </carousel>
    </div>

    <div class="px-0 mt-3 col-12">
      <!-- <div style="height: 10px; width: 10px; background-color: red"></div> -->
      <carousel
        :key="carouselKey"
        :autoWidth="true"
        :dots="false"
        :navText="[
          `<i class='fas fa-chevron-left'></i>`,
          `<i class='fas fa-chevron-right'></i>`,
        ]"
      >
        <div
          v-for="(item, index) in examinations"
          :key="index"
          v-loading="fetchingExamID == item.examination.examination_id"
          class="mx-3 my-2 card ml-md-0"
          style="width: 230px; height: auto"
        >
          <div class="pt-0 pb-3">
            <div
              class="d-flex justify-content-center align-items-center"
              style="
                height: 150px;
                width: 100%;
                background-color: rgba(255, 0, 0, 0.075);
              "
            >
              <i
                class="fas fa-book-open"
                style="font-size: 2.7em; color: rgba(255, 0, 0, 0.35)"
              ></i>
            </div>

            <div
              class="mt-4 text-left col-12 text-truncate"
              style="color: var(--el-app-primary); text-transform: capitalize"
            >
              <strong>{{ item.course_unit.course_unit_code }}</strong>
            </div>

            <small
              style="
                color: #000;
                padding: 0px 10px;
                color: rgba(0, 0, 0, 0.4);
                text-transform: capitalize;
              "
              class="pb-1 text-left d-block text-truncate"
            >{{ item.examination.title }}
            </small>
            <hr class="custom-classic-hr" />
            <small
              style="color: #000; padding: 0px 10px; color: rgba(0, 0, 0, 0.6)"
              class="pb-1 text-left d-block text-truncate"
            >
              <i class="fas fa-check"></i> <strong>Start Date & Time: </strong>
              <br />
              {{ item.examination.start_time_ug_iso }} EAT
            </small>
            <small
              style="color: #000; padding: 0px 10px; color: rgba(0, 0, 0, 0.6)"
              class="pb-1 text-left d-block text-truncate"
            >
              <i class="fas fa-check"></i> <strong>End Date & Time: </strong>
              <br />
              {{ item.examination.end_time_ug_iso }} EAT
            </small>
            <div v-if="forMarksOnly(item.examination)">
              <small
                style="padding: 0px 10px"
                class="pb-1 text-left d-block text-truncate text-warning"
              >
                <i class="fas fa-warning"></i> For Marks Only
              </small>
            </div>
            <div v-else>
              <small
                v-if="missedExam(item.examination)"
                style="color: rgba(255, 0, 0, 0.8); padding: 0px 10px"
                class="pb-1 text-left d-block text-truncate"
              >
                <i class="fas fa-close"></i> You Missed this Exam
              </small>
              <div v-if="neverCompleted(item.examination)">
                <small
                  style="padding: 0px 10px"
                  class="pb-1 text-left d-block text-truncate text-warning"
                >
                  <i class="fas fa-warning"></i> Started, Never Submitted
                </small>

              </div>

              <small
                v-if="examSubmitted(item.examination)"
                style="color: #67c23a; padding: 0px 10px"
                class="pb-1 text-left d-block text-truncate"
              >
                <i class="fas fa-check"></i> Already Submitted
              </small>
            </div>
            <div class="pl-0 pr-3 mt-1 col-12">
              <button
                type="button"
                class="btn z-depth-0"
                :class="{ 'not-allowed': fetchingExamID != null }"
                @click="
                  $router.push({
                    path: `/dashboard/examinations/instructions/${item.examination.examination_id}/${item.course_unit.course_unit_id}`,
                  })
                "
                v-ripple="'rgba(255, 255, 255, 0.35)'"
                style="
                  width: 100%;
                  text-transform: capitalize;
                  height: 40px;
                  border-radius: 4px;
                  background-color: var(--el-app-primary);
                "
              >
                <span style="position: relative; bottom: 3px">{{
                  examSubmitted(item.examination)
                    ? "Submitted"
                    : "Details"
                }}</span>
              </button>
            </div>
          </div>
        </div>
      </carousel>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
// import ScheduleLiveLessonComponent from "../components/schedule-live-lesson-component";
import { format, parseISO } from "date-fns";

export default {
  components: {
    carousel,
    // ScheduleLiveLessonComponent,
  },
  data() {
    return {
      isFetching: true,
      isError: false,
      fetchingExamID: null,
      examinations: [],
      tests: [],
      carouselKey: 1,
    };
  },
  computed: {
    /*   formattedDateWithTime() {
      return (dateString) =>
        format(parseISO(dateString), "do MMM yyyy ' at ' hh:mma");
    }, */

    forMarksOnly() {
      return (examination) => {
        const hasSubmission = !!examination.student_examination_submissions[0];
        return (
          hasSubmission &&
          examination.student_examination_submissions[0].for_marks_only
        );
      };
    },

    missedExam() {
      return (examination) => {
        const hasSubmission = !!examination.student_examination_submissions[0];
        return examination.is_exam_ended && !hasSubmission;
      };
    },

    examSubmitted() {
      return (examination) => {
        const hasSubmission = !!examination.student_examination_submissions[0];
        return (
          hasSubmission &&
          !!examination.student_examination_submissions[0].submitted_at
        );
      };
    },

    neverCompleted() {
      return (examination) => {
        const hasSubmission = !!examination.student_examination_submissions[0];
        return (
          hasSubmission &&
          !examination.student_examination_submissions[0].submitted_at
        );
      };
    },

    formattedDate() {
      return (dateString) => {
        if (!dateString) return "";
        return format(parseISO(dateString), "do MMMM yyyy");
      };
    },

    getInitials() {
      return (nameString) => {
        //  format(parseISO(dateString), "do MMMM yyyy");
        var names = nameString.split(" "),
          initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
          initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      };
    },
    // getInitials(nameString) {},
  },
  watch: {
    examinations() {
      //Force rerender of the component once we get some data!!!
      this.carouselKey++;
    },
  },
  mounted() {
    this.$refs.preloaderCarouselRef.$el.getElementsByClassName(
      "owl-prev"
    )[0].innerHTML = "<i class='fas fa-chevron-left'></i>";
    this.$refs.preloaderCarouselRef.$el.getElementsByClassName(
      "owl-next"
    )[0].innerHTML = "<i class='fas fa-chevron-right'></i>";

    this.fetchAvailableExams();
  },

  methods: {
    async fetchAvailableExams() {
      try {
        //If we havent been loading yet, let's Load
        if (!this.isFetching) {
          this.isFetching = true;
          this.isError = false;
        }

        let request = await this.$http.get(
          `examinations/fetch-all-for-student/${this.$store.state.userId}`
        );
        if (
          request.data.success &&
          request.data.message == "STUDENT EXAMINATIONS RETRIEVED"
        ) {
          this.examinations = request.data.examinations;
          this.isFetching = false;
          this.isError = false;
        } else {
          this.$rollbar.warning("STUDENTS FRONT END: Unexpected API response", {
            response: request.data,
            request,
          });
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        // eslint-disable-line no-unused-vars
        this.isFetching = false;
        this.isError = true;

        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        console.log(error);

        this.showFailedMessage(
          "Fetching failed",
          "Unable to fetch Examinations now, please try again"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>


<style scoped>
.btn.disabled,
.btn:disabled {
  opacity: 0.35;
}
</style>

<style>
.user-avatar .skeleton-block {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
hr.custom-classic-hr {
  /* Gradient transparent-color-transparent */
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.205),
    rgba(0, 0, 0, 0)
  );
}
.available-tests-exams .el-progress__text {
  font-size: 12px !important;
}
.owl-prev {
  position: absolute;
  left: -21px;
  top: 44%;
  padding: 6px 11px !important;
  border-radius: 30px !important;
}

.owl-next {
  position: absolute;
  right: -21px;
  top: 44%;
  padding: 6px 11px !important;
  border-radius: 30px !important;
}
</style>