<template>
  <div
    class="col-12 text-left pb-3 pb-md-1 px-0"
    style="font-weight: 400; font-size: .9em"
  >
    <i class="fas fa-check pr-3"></i> <span v-html="instruction"></span>
  </div>
</template>

<script>
export default {
  props: {
    instruction: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
</style>