import { ExaminationErrorMessages } from "@/enums/examinationErrorMessages.enum";

export default (issue: ExaminationErrorMessages) => {
        switch (issue) {
            case ExaminationErrorMessages.EXAMINATION_SET_FINANCIAL_REQUIREMENTS_UNMET:
                return "You do not meet the financial requirements to attempt this examination.";
            case ExaminationErrorMessages.COURSE_WORK_PREREQUISITES_UNMET:
                return "You do not have the required coursework to attempt this examination.";
            case ExaminationErrorMessages.RESIT_PAYMENT_PREREQUISITE_UNMET:
                return "You have not made the necessary payment to attempt this resit examination.";
            case ExaminationErrorMessages.RETAKE_PAYMENT_PREREQUISITE_UNMET:
                return "You have not made the necessary payment to attempt this retake examination.";
            case ExaminationErrorMessages.STUDENT_BLOCKED_FROM_ATTEMPTING_EXAMINATION:
                // return "You are blocked from attempting this examination.";
                return "You are blocked from doing this examination due to attendance requirement.";
            default:
                return issue;
        }
}