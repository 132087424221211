<template>
  <section class="container">
    <div class="content">
      <div class="row w-100 mb-4">
        <div class="col-12 pl-3 px-0 mb-0 text-left">
          <BreadCrumbComponent :pageTitles="['Course Work', this.url_result_query ? 'All' : '']" />
        </div>
      </div>
      <div class="row w-100">
        <div class="col-12 text-left">
          <div style="
              font-size: 20px;
              font-weight: 400;
              color: var(--el-app-primary);
              margin-bottom: 15px;
            ">
            <strong>Course Work</strong>
          </div>
        </div>
      </div>

      <div v-if="url_result_query === 'all'">
        <AllCourseWorksComponent />
      </div>

      <div v-else>
        <AvailableCourseWorksComponent />
        <div class="row w-100 mt-3">
          <div
            class="col-12 text-left"
            style="font-weight: 600"
          >
            All Course Work
          </div>
          <div
            class="col-12 text-left mt-4"
            style="font-weight: 400; font-size: 0.9em"
          >
            To view all Course Work including those you attempted or missed
            already. Press the button below
          </div>
          <div class="col-12 pl-2 mt-1 text-left">
            <button
              type="button"
              class="btn z-depth-0"
              @click="loadAllCourseWorks"
              v-ripple="'rgba(255, 255, 255, 0.35)'"
              style="
                width: 140px;
                text-transform: capitalize;
                height: 40px;
                border-radius: 4px;
                background-color: var(--el-app-primary);
              "
            >
              <span style="position: relative; bottom: 4px">View All </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AvailableCourseWorksComponent from "../../components/courseworks/available-courseworks-component.vue";
import AllCourseWorksComponent from "../../components/courseworks/all-course-works-component.vue";
import BreadCrumbComponent from "../../components/bread-crumb-component";
export default {
  components: {
    AvailableCourseWorksComponent,
    AllCourseWorksComponent,
    BreadCrumbComponent,
  },
  data() {
    return {
      url_result_query: this.$route.query.result,
      // url_result_query: '',

      tableData: [
        {
          title: "Pointers, Virtual Functions & Polymorphoism",
          duration: "1 Hour",
          paperCode: "JAVA-02",
          starts: "09:30am 13/Feb/2021",
          ends: "09:30am 16/Feb/2021",
          type: "Objective",
          result: "78/100",
        },
        {
          title: "Dynamic Memory Allocation for Arrays ",
          duration: "1 Hour",
          paperCode: "CPPE02",
          starts: "11:30am 13/Jun/2021",
          ends: "07:30am 16/Jul/2021",
          type: "Objective",
          result: null,
        },
        {
          title: "Constructors in Derived Classes",
          duration: "2 Hours",
          paperCode: "GUI01",
          starts: "01:30pm 13/Feb/2021",
          ends: "09:30am 16/Feb/2021",
          type: "Objective",
          result: "Date is today",
        },
        {
          title: "Decision making and Branching ",
          duration: "2Hr 30mins",
          paperCode: "CPPE02",
          starts: "11:30am 13/Jun/2021",
          ends: "07:30am 16/Jul/2021",
          type: "Objective",
          result: "56/100",
        },
        {
          title: "Implementation of Polymorphism",
          duration: "1 Hour",
          paperCode: "CPPE02",
          starts: "11:30am 13/Jun/2021",
          ends: "07:30am 16/Jul/2021",
          type: "Essay",
          result: "submitted",
        },
      ],
      // titles: [
      //   { prop: "title", label: "Title" },
      //   { prop: "username", label: "Username" },
      //   { prop: "gender", label: "Gender" },
      //   { prop: "access_level", label: "Access Level" },
      //   { prop: "last_login", label: "Last Login" },
      // ],
      tableProps: {
        /*  border: true, */
        stripe: true,
        defaultSort: {
          prop: "title",
          order: "ascending",
        },
      },

      filters: [
        {
          prop: "title",
          value: "",
        },
      ],
    };
  },

  methods: {
    loadAllCourseWorks() {
      this.$router.push({ name: "CourseworksAll" });
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.course-work-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
}

.course-work-cards > div {
  background-color: rgb(252, 250, 250);
  padding: 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;

  box-shadow: 2px 10px 5px -11px rgba(161, 156, 161, 1);
  -webkit-box-shadow: 2px 10px 5px -11px rgba(161, 156, 161, 1);
  -moz-box-shadow: 2px 10px 5px -11px rgba(161, 156, 161, 1);
}

.course-work-cards > div:hover {
  box-shadow: 0 8px 6px -6px rgba(161, 156, 161, 1);
  -webkit-box-shadow: 0 8px 6px -6px rgba(161, 156, 161, 1);
  -moz-box-shadow: 0 8px 6px -6px rgba(161, 156, 161, 1);
}

.course-work-cards > div > div {
  width: 100%;
}

.course-work-cards .theBtns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.course-work-cards .theBtns > div:nth-child(2) {
  display: flex;
  justify-content: flex-end;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .course-work-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .course-work-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }

  .course-work-cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }

  .course-work-cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
}
</style>

<style>
.announcement_dialogs .el-dialog {
  width: 70%;
}

.search_by_input {
  width: 400px !important;
}

.announcement_dialogs .el-select .el-input__inner {
  cursor: pointer;
  height: 36px !important;
  width: 100% !important;
  padding-left: 10px !important;
  text-transform: capitalize;
}
.announcement_dialogs .el-button--primary {
  color: #fff;
  background-color: #409eff !important;
  border-color: #409eff !important;
}

el-table th > .cell {
  color: black !important;
  font-weight: 500;
}
.el-table .cell {
  font-size: 0.9em;
  line-height: 23px;
  font-weight: 300;
}

.announcement_dialogs .el-input input {
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.announcement_dialogs .el-input input:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}

.announcement_dialogs .el-upload-dragger {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 366px !important;
  height: 150px !important;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.announcement_dialogs .el-icon-upload:before {
  font-size: 50px;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .announcement_dialogs .el-dialog {
    width: 50%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .announcement_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .announcement_dialogs .el-dialog {
    width: 90%;
  }
}
.content {
  margin-top: 45px !important;
}
.ui-margin {
  margin-left: 42px !important;
  margin-right: 42px !important;
}

@media (max-width: 768px) {
  .content {
    margin-top: 15px !important;
  }
}
</style>


<style>
th > .cell {
  color: rgba(0, 0, 0, 0.836) !important;
  font-weight: 600 !important;
  font-size: 13px !important;
}
el-table th > .cell {
  color: black !important;
  font-weight: 500;
}
.el-table .cell {
  font-size: 0.9em;
  line-height: 23px;
  font-weight: 300;
}
</style>