<template>
  <div class="mx-auto available-tests-exams row" style="width: 100%">
    <div class="pl-0 pr-0 text-left col-12" style="font-weight: 600">
      Available Tutorials
    </div>

    <div
      v-if="!isFetching && !isError && tutorials.length == 0"
      class="mt-4 text-left col-12"
      style="font-weight: 400; font-size: 0.9em"
    >
      <el-empty
        description=" You have No Available Tutorials at the Moment."
      ></el-empty>
    </div>

    <div class="pr-0 mt-1 text-right col-12"></div>
    <div v-if="isError" class="px-0 col-12" style="height: 180px">
      <div style="font-weight: 600; margin-top: 50px">Fetching Failed</div>
      <div class="pt-2" style="font-weight: 400; font-size: 0.9em">
        Failed to fetch Tutorials now. Please try again
      </div>
      <button
        type="button"
        class="mx-0 mt-3 btn z-depth-0"
        v-ripple="'rgba(255, 255, 255, 0.35)'"
        @click="fetchTutorials"
        style="
          width: 220px;
          text-transform: capitalize;
          height: 45px;
          border-radius: 4px;
          background-color: red;
          font-weight: 500;
        "
      >
        <span style="position: relative; bottom: 1px"
          ><i class="pr-2 fas fa-refresh"></i> Try Again</span
        >
      </button>
    </div>
    <div v-show="isFetching && !isError" class="px-0 mt-3 col-12">
      <carousel ref="preloaderCarouselRef" :autoWidth="true" :dots="false">
        <div
          v-for="i in [1, 2, 3, 4, 5, 6]"
          :key="i"
          class="mr-3 skeleton-block skeleton-effect-wave"
          style="width: 230px; height: 230px; border-radius: 5px"
          :navText="[
            `<i class='fas fa-chevron-left'></i>`,
            `<i class='fas fa-chevron-right'></i>`,
          ]"
        ></div>
      </carousel>
    </div>

    <div class="px-0 mt-3 col-12">
      <!-- <div style="height: 10px; width: 10px; background-color: red"></div> -->
      <carousel
        :key="carouselKey"
        :autoWidth="true"
        :dots="false"
        :navText="[
          `<i class='fas fa-chevron-left'></i>`,
          `<i class='fas fa-chevron-right'></i>`,
        ]"
      >
        <div
          v-for="(tutorial, index) in tutorials"
          :key="index"
          class="mx-3 my-2 card ml-md-0"
          style="width: 230px; height: auto"
        >
          <div class="  pt-0 pb-3">
            <img
              src="../assets/img/video-placeholder.webp"
              alt=""
              height="150px"
              style="object-fit: cover !important"
            />
            <div
              class="col-12 text-left mt-4 text-truncate"
              style="color: var(--el-app-primary); text-transform: capitalize"
            >
              <strong>{{ tutorial.title }}</strong>
              <small
                style="
                  color: #000;
                  padding: 0px;
                  color: rgba(0, 0, 0, 0.4);
                  text-transform: capitalize;
                "
                class="d-block text-truncate pb-1 text-left"
              >
                {{ tutorial.sub_title }}
              </small>
            </div>

            <hr class="custom-classic-hr" />
            <div class="col-12 mt-1 pl-0 ">
              <router-link
                :to="{
                  name: 'TutorialDetails',
                  params: {
                    videoSource: `${tutorial.video_source}`,
                    tutorialName: `${tutorial.title}`,
                  },
                }"
              >
                <button
                  type="button"
                  class="btn z-depth-0 pb-1"
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  style="
                    width: 100%;
                    text-transform: capitalize;
                    height: 40px;
                    border-radius: 4px;
                    background-color: var(--el-app-primary);
                  "
                >
                  <span style="position: relative; bottom: 3px">Watch</span>
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </carousel>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
  components: {
    carousel,
  },
  data() {
    return {
      isFetching: true,
      isError: false,
      tutorials: [],
      carouselKey: 1,
    };
  },
  watch: {
    tutorials() {
      //Force rerender of the component once we get some data!!!
      this.carouselKey++;
    },
  },
  mounted() {
    this.$refs.preloaderCarouselRef.$el.getElementsByClassName(
      "owl-prev"
    )[0].innerHTML = "<i class='fas fa-chevron-left'></i>";
    this.$refs.preloaderCarouselRef.$el.getElementsByClassName(
      "owl-next"
    )[0].innerHTML = "<i class='fas fa-chevron-right'></i>";
    this.fetchTutorials();
  },

  methods: {
    async fetchTutorials() {
      try {
        //If we havent been loading yet, let's Load
        if (!this.isFetching) {
          this.isFetching = true;
          this.isError = false;
        }
        let request = await this.$http.get(`students/fetch-tutorials`);
        if (
          request.data.success &&
          request.data.message == "TUTORIALS FETCHED SUCCESSFULLY"
        ) {
          this.tutorials = request.data.tutorials;
          this.isFetching = false;
          this.isError = false;
        } else {
          this.$rollbar.warning("STUDENTS FRONT END: Unexpected API response", {
            response: request.data,
            request,
          });
          throw "UNEXPECTED_RESPONSE_RECEIVED";
        }
      } catch (error) {
        // eslint-disable-line no-unused-vars
        this.isFetching = false;
        this.isError = true;

        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }
        this.showFailedMessage(
          "Fetching failed",
          "Unable to fetch Tutorials, please try again"
        );
      }
    },
  },
};
</script>


<style scoped>
.btn.disabled,
.btn:disabled {
  opacity: 0.35;
}
</style>

<style>
.user-avatar .skeleton-block {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
hr.custom-classic-hr {
  /* Gradient transparent-color-transparent */
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.205),
    rgba(0, 0, 0, 0)
  );
}
.available-tests-exams .el-progress__text {
  font-size: 12px !important;
}
.owl-prev {
  position: absolute;
  left: -21px;
  top: 44%;
  padding: 6px 11px !important;
  border-radius: 30px !important;
}

.owl-next {
  position: absolute;
  right: -21px;
  top: 44%;
  padding: 6px 11px !important;
  border-radius: 30px !important;
}
</style>

