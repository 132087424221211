import { render, staticRenderFns } from "./event-item-component.vue?vue&type=template&id=17f6dc50&scoped=true"
import script from "./event-item-component.vue?vue&type=script&lang=js"
export * from "./event-item-component.vue?vue&type=script&lang=js"
import style0 from "./event-item-component.vue?vue&type=style&index=0&id=17f6dc50&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17f6dc50",
  null
  
)

export default component.exports