import Vue from 'vue'
import Vuex from 'vuex'
import { elearningStore } from './elearningStore'
import { INITIALIZE_STORE, LOGIN_USER, LOGOUT_USER, SAVE_SEARCH_RESULTS, REFRESH_ACCESS_TOKEN, UPDATE_NOTIFICATIONS, DO_FORCED_UPDATE, UPDATE_EXAM_TIME_LEFT } from "./mutation-types"
import jwt_decode from "jwt-decode";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userId: "",
    userInitials: "",
    profilePicture: "",
    accessToken: "",
    deviceToken: "",
    universityName: "VICTORIA UNIVERSITY",
    logoImageUrl: require("../assets/img/vu-logo-with-words.png"),
    backgroundImage: require("../assets/img/login-img.jpg"),
    secureImg: require("../assets/img/secure.png"),
    search_results: [],
    notificationsLastChecked: "",
    forcedUpdateLastChecked: "",
    fullName: "",
    regNo: "",
    examTimeLeft: "",
    isPesamoniUser: false,
    isElec: false,
  },
  mutations: {
    [UPDATE_NOTIFICATIONS]: (state) => {
      //@ts-ignore
      state.notificationsLastChecked = +(new Date());
    },
    [DO_FORCED_UPDATE]: (state) => {

      //@ts-ignore
      state.forcedUpdateLastChecked = +(new Date());
    },
    [REFRESH_ACCESS_TOKEN]: (state, { accessToken }) => {
      //Save The Access Token in local Storage 
      (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).setItem("s-at", accessToken);

      try {
        //@ts-ignore
        var decoded: String = jwt_decode(accessToken);
        //@ts-ignore
        state.userId = decoded.user_id;
        //@ts-ignore
        state.profilePicture = decoded.pp;
        //@ts-ignore
        state.userInitials = decoded.ui;
        state.accessToken = accessToken;
        //@ts-ignore
        state.isPesamoniUser = decoded.isP;
        //@ts-ignore
        state.isElec = decoded.elec;

        //@ts-ignore
        state.fullName = decoded.fn;

        //@ts-ignore
        state.regNo = decoded.reg_no;

      } catch (error) {
        //Nothing to do
      }
    },
    [LOGIN_USER]: (state, { accessToken, fbDeviceToken }) => {
      //Save The Access Token in local Storage 
      state.deviceToken = fbDeviceToken;
      try {
        //@ts-ignore
        var decoded: String = jwt_decode(accessToken);
        //@ts-ignore
        state.userId = decoded.user_id;
        //@ts-ignore
        state.profilePicture = decoded.pp;
        //@ts-ignore
        state.userInitials = decoded.ui;
        state.accessToken = accessToken;
        //@ts-ignore
        state.isPesamoniUser = decoded.isP;
        //@ts-ignore
        state.isElec = decoded.elec;

        //@ts-ignore
        state.fullName = decoded.fn;

        //@ts-ignore
        state.regNo = decoded.reg_no;
      } catch (error) {
        //Nothing to do
      }

      (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).setItem("s-at", accessToken);
      //Save the Device Token as we need it for Single User Sessions
      (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).setItem("s-dt", fbDeviceToken);
    },
    [LOGOUT_USER]: (state) => {
      //Save The Access Token in local Storage
      state.userId = "";
      state.accessToken = '';
      state.userInitials = '';
      state.profilePicture = ''
      state.search_results = [];
      state.deviceToken = "";
      (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).removeItem("s-at");
      (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).removeItem("s-dt");
      localStorage.removeItem("isPersistent");
    },
    [INITIALIZE_STORE]: (state) => {

      //Retrieve Device Token
      //@ts-ignore
      state.deviceToken = (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).getItem("s-dt");

      //Attempt to retrieve Token from local Storage
      let accessToken = (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).getItem("s-at");
      //If we have no access token, No use to proceed
      if (!accessToken) return;
      try {

        let decoded = jwt_decode(accessToken);
        //@ts-ignore
        state.userId = decoded.user_id;
        state.accessToken = accessToken;

        //@ts-ignore
        state.userInitials = decoded.ui;
        //@ts-ignore
        state.profilePicture = decoded.pp;
        //@ts-ignore
        state.isPesamoniUser = decoded.isP;
        //@ts-ignore
        state.isElec = decoded.elec;

        //@ts-ignore
        state.fullName = decoded.fn;

        //@ts-ignore
        state.regNo = decoded.reg_no;
      } catch (error) {
        //Nothing to do
        //TODO: HANDLE THIS SCENARIO
      }
    },

    [SAVE_SEARCH_RESULTS]: (state, payload) => {
      state.search_results = payload;
    },

    [UPDATE_EXAM_TIME_LEFT]: (state, payload) => {
      state.examTimeLeft = payload;
    },

  },
  getters: {
    isLoggedIn: state => {
      return !!state.userId;
    },
    // deviceToken: state => state.accessToken
  },
  plugins: [elearningStore],
  actions: {
  },
  modules: {
  }
})
