<template>
    <section>
        <div class="chats-article1" style="text-align:left;">
            <div>
                <div class="the-chats" id="the-chats">

                    <div class="chat-messages-area">
                        <div class="container">
                            <div class="chat-container">
                                <ul class="chat-box chatContainerScroll" style="padding: 0px; list-style: none;">
                                    <li class="chat-left">
                                        <div class="chat-avatar">
                                            <img src="https://www.bootdey.com/img/Content/avatar/avatar3.png" alt="Retail Admin">
                                            <div class="chat-name">Lawrence</div>
                                        </div>
                                        <div class="chat-text">Hello, I'm Lawrence.
                                            <br>How can I help you today?</div>
                                        <div class="chat-hour">08:55 <span class="fa fa-check-circle"></span></div>
                                    </li>
                                    <li class="chat-right">
                                        <div class="chat-hour">08:56 <span class="fa fa-check-circle"></span></div>
                                        <div class="chat-text">Hi, Lawrence
                                            <br> I need more information about Developer Plan.</div>
                                        <div class="chat-avatar">
                                            <img src="https://www.bootdey.com/img/Content/avatar/avatar3.png" alt="Retail Admin">
                                            <div class="chat-name">Andrew</div>
                                        </div>
                                    </li>
                                    <li class="chat-left">
                                        <div class="chat-avatar">
                                            <img src="https://www.bootdey.com/img/Content/avatar/avatar3.png" alt="Retail Admin">
                                            <div class="chat-name">Lawrence</div>
                                        </div>
                                        <div class="chat-text">Are we meeting today?
                                            <br>Project has been already finished and I have results to show you.</div>
                                        <div class="chat-hour">08:57 <span class="fa fa-check-circle"></span></div>
                                    </li>
                                    <li class="chat-right">
                                        <div class="chat-hour">08:59 <span class="fa fa-check-circle"></span></div>
                                        <div class="chat-text">Well I am not sure.
                                            <br>I have results to show you.</div>
                                        <div class="chat-avatar">
                                            <img src="https://www.bootdey.com/img/Content/avatar/avatar5.png" alt="Retail Admin">
                                            <div class="chat-name">Andrew</div>
                                        </div>
                                    </li>
                                    <li class="chat-left">
                                        <div class="chat-avatar">
                                            <img src="https://www.bootdey.com/img/Content/avatar/avatar3.png" alt="Retail Admin">
                                            <div class="chat-name">Lawrence</div>
                                        </div>
                                        <div class="chat-text">The rest of the team is not here yet.
                                            <br>Maybe in an hour or so?</div>
                                        <div class="chat-hour">08:57 <span class="fa fa-check-circle"></span></div>
                                    </li>
                                    <li class="chat-right">
                                        <div class="chat-hour">08:59 <span class="fa fa-check-circle"></span></div>
                                        <div class="chat-text">Have you faced any problems at the last phase of the project?</div>
                                        <div class="chat-avatar">
                                            <img src="https://www.bootdey.com/img/Content/avatar/avatar4.png" alt="Retail Admin">
                                            <div class="chat-name">Andrew</div>
                                        </div>
                                    </li>
                                    <li class="chat-left">
                                        <div class="chat-avatar">
                                            <img src="https://www.bootdey.com/img/Content/avatar/avatar3.png" alt="Retail Admin">
                                            <div class="chat-name">Lawrence</div>
                                        </div>
                                        <div class="chat-text">Actually everything was fine.
                                            <br>I'm very excited to show this to our team.</div>
                                        <div class="chat-hour">07:00 <span class="fa fa-check-circle"></span></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="messaging-area">
                        <div class="container">
                            <el-input
                                type="textarea"
                                :autosize="{ minRows: 2, maxRows: 4}"
                                placeholder="Type a message"
                                v-model="textarea2">
                            </el-input>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </section>
</template>

<script>
    export default{
        data() {
            return {
                textarea2: '',
            }
        }
    }
</script>

<style scoped>
    /* Just for demo */
    * {
        box-sizing: border-box;
    }

    .textarea {
  border: 1px solid #ccc;
  font-family: inherit;
  font-size: inherit;
  padding: 1px 6px;
}
.width-machine {
  /*   Sort of a magic number to add extra space for number spinner */
  padding: 0 1rem;
}

.textarea {
  display: block;
  width: 100%;
  overflow: hidden;
  resize: both;
  min-height: 40px;
  line-height: 20px;
}

.textarea[contenteditable]:empty::before {
  content: "Placeholder still possible";
  color: gray;
}


    .chats-article1 {
        background-color: #F6F6F6;
        position: fixed;
        top:65px;
        bottom: 0px;
        right: 0px;
        width: 80%;
    }
    .the-chats {
        position: absolute;
        top:0px;
        bottom: 0px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-flow: column;
    }
    .chat-messages-area{
        padding-top: 20px;
        height: 100%;
        background-color: #F6F6F6;
        overflow: scroll;
        overflow-x: hidden;
    }
    .messaging-area {
        background-color: #eeeeee;
        padding: 10px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .messaging-area .textarea{
        background-color: #f5f4f4;
        border: none;
        padding: 10px 20px;
    }

    .messaging-area>div {
        display: flex;
        align-items: center;
    }
    .messaging-area>div:first-child {
        width: 100%;
    }
    .messaging-area>div:nth-child(2) {
        padding: 5px;
    }

    .round-ball-lg {
        border-radius:50%; width:40px; height:40px; background-color:whitesmoke;
        color: var(--el-app-primary);;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .profile_img {
        display: flex;
        align-items: center;
    }
    .the-image{
        margin-right: 15px;
        border-radius:50%; width:40px ; height:40px ;
        position: relative;
    }

    .profile_img img{
        border: 1px solid #F6F6F6;
        border-radius:50%; width:40px; height:40px; object-fit:cover;
    }

    .online-status {
        position: absolute;
        bottom: 0px;
        right: 0px;
        border-radius:100%; width:7px; height:7px;
        background-color: white;
    }

    .online-status-ball {
        position: absolute;
        bottom: 0px;
        right: 0px;
        border-radius:100%; width:6px; height:6px;
        background-color: rgb(46, 190, 147);
    }

    .user-chat-flag {
        width: 100%;
    }

    .user-chat-flag {
        line-height:16px; margin:0px; padding:0px;
    }


    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #F6F6F6;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #1F4EA1;
        border-radius: 10px;
    }
 
    .chat-container {
        position: relative;
        /* padding: 1rem; */
    }
    .chat-container li.chat-left,
    .chat-container li.chat-right {
            display: flex;
            flex: 1;
            flex-direction: row;
            margin-bottom: 40px;
    }
        .chat-container li img {
            width: 48px;
            height: 48px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;
        }
        .chat-container li .chat-avatar {
            margin-right: 20px;
        }
        .chat-container li.chat-right {
            justify-content: flex-end;
        }
        .chat-container li.chat-right > .chat-avatar {
            margin-left: 20px;
            margin-right: 0;
        }
        .chat-container li .chat-name {
            font-size: .75rem;
            color: #999999;
            text-align: center;
        }
        .chat-container li .chat-text {
            padding: .4rem 1rem;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
            background: #ffffff;
            font-weight: 300;
            line-height: 150%;
            position: relative;
        }
        .chat-container li .chat-text:before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 10px;
            left: -20px;
            border: 10px solid;
            border-color: transparent #ffffff transparent transparent;
        }
        .chat-container li.chat-right > .chat-text {
            text-align: right;
        }
        .chat-container li.chat-right > .chat-text:before {
            right: -20px;
            border-color: transparent transparent transparent #ffffff;
            left: inherit;
        }
        .chat-container li .chat-hour {
            padding: 0;
            margin-bottom: 10px;
            font-size: .75rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin: 0 0 0 15px;
        }
        .chat-container li .chat-hour > span {
            font-size: 16px;
            color: #9ec94a;
        }
        .chat-container li.chat-right > .chat-hour {
            margin: 0 15px 0 0;
        }
        @media (max-width: 767px) {
            .chat-container li.chat-left,
            .chat-container li.chat-right {
                flex-direction: column;
                margin-bottom: 30px;
            }
            .chat-container li img {
                width: 32px;
                height: 32px;
            }
            .chat-container li.chat-left .chat-avatar {
                margin: 0 0 5px 0;
                display: flex;
                align-items: center;
            }
            .chat-container li.chat-left .chat-hour {
                justify-content: flex-end;
            }
            .chat-container li.chat-left .chat-name {
                margin-left: 5px;
            }
            .chat-container li.chat-right .chat-avatar {
                order: -1;
                margin: 0 0 5px 0;
                align-items: center;
                display: flex;
                justify-content: right;
                flex-direction: row-reverse;
            }
            .chat-container li.chat-right .chat-hour {
                justify-content: flex-start;
                order: 2;
            }
            .chat-container li.chat-right .chat-name {
                margin-right: 5px;
            }
            .chat-container li .chat-text {
                font-size: .8rem;
            }
        }




    /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
    @media (min-width: 768px) and (max-width: 1024px) {
        
        
    }
    
    /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        
    }
    
    /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
    @media (min-width: 481px) and (max-width: 767px) {
        .chats-article1 {
            width: 100%;
        }
    }
    
    /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
    @media (min-width: 320px) and (max-width: 480px) {
        .chats-article1 {
            width: 100%;
        }
    }

    
</style>


<style>
    .messaging-area .el-textarea__inner {
        background-color: #FFF;
        border: 1px solid #DCDFE6;
        border-radius: 8px;
    }
</style>
