<template>
  <article class="">
    <section>
      <div class="">
        <el-dialog
          title="Preview Answer Sheet File"
          class="text-left"
          width="90%"
          :visible="isVisible"
          :destroy-on-close="true"
          :close-on-press-escape="false"
          :close-on-click-modal="false"
          :before-close="handleClose"
        >
          <div v-loading="isBusy">
            <div class="px-3 row">
              <div class="col-12 px-0 pb-4">
                <span
                  class="pt-0 text-left"
                  style="font-size: 1em; font-style: italic; color: rgba(0, 0, 0, 0.8)"
                >
                  <!-- This will <b style="font-weight: 500">immediately</b> submit your answer sheet for this Examination, ensure it is correct before submitting. -->
                  Please scroll to the Bottom to Confirm & Submit Your Answer Sheet
                </span>
              </div>
              <!-- Document viewer for PDF -->
              <div
                v-loading="loading"
                v-if="filePathUrl.endsWith('pdf')"
                class="row w-100 mx-0"
              >
                <div
                  v-show="!isLoading"
                  class="row w-100 mx-0"
                >
                  <div class="col-12">
                    <span class="float-right">
                      <span
                        class="pr-5"
                        style="font-weight: 400"
                      >Page <strong>{{ activePage }}</strong> of
                        {{ totalPages ? totalPages : "?" }}</span>
                      <div style="display: inline-flex">
                        <div
                          @click="onPreviousPage"
                          class="direction_arrows"
                        >
                          <i class="fas fa-long-arrow-alt-left"></i>
                        </div>
                        <div
                          @click="onNextPage"
                          class="direction_arrows"
                        >
                          <i class="fas fa-long-arrow-alt-right"></i>
                        </div>
                      </div>
                    </span>
                  </div>
                  <div class="col-12">
                    <pdf
                      :src="filePathUrl"
                      :page="activePage"
                      @num-pages="onPageNumberObtained"
                      @progress="onProgress"
                      @loaded="onLoaded"
                      @error="onPDfError"
                    ></pdf>
                  </div>
                  <div class="col-12 text-right">
                    <span
                      class="pr-5"
                      style="font-weight: 400"
                    >Page <strong>{{ activePage }}</strong> of
                      {{ totalPages ? totalPages : "?" }}</span>
                    <div
                      class="pt-3"
                      style="display: inline-flex"
                    >
                      <div
                        @click="onPreviousPage"
                        class="direction_arrows"
                      >
                        <i class="fas fa-long-arrow-alt-left"></i>
                      </div>
                      <div
                        @click="onNextPage"
                        class="direction_arrows"
                      >
                        <i class="fas fa-long-arrow-alt-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-show="isLoading"
                  class="col-12 text-center"
                  style="margin-top: 130px"
                >
                  <ScaleOut
                    :background="'#1F4EA1'"
                    class="mx-auto"
                  />
                  <div style="font-size: 0.9em; font-weight: 500">
                    {{ downloadProgress }}% Loading Preview...
                  </div>
                </div>
              </div>

              <!-- Otherwise just ask the user to download the file -->
              <div
                v-loading="loading"
                v-else
                class="row w-100 mx-0"
              >
                <div class="col-12">
                  <div class="col-12 d-flex justify-content-center text-center">
                    <div
                      class="pt-5 pb-3"
                      style="font-size: 0.9em; font-style: italic"
                    >
                      Download the <b>file</b> to view its contents.
                      Once downloaded, preview it on your device and then click Confirm & Submit.
                      <br />
                      <a :href="filePathUrl">
                        <img
                          src="@/assets/img/zip.png"
                          alt=""
                          height="120px"
                        />
                      </a>
                      <br />
                      <el-button
                        style="
                          background-color: var(--el-app-primary);
                          color: white;
                          height: 30px;
                        "
                        class="z-depth-0 addbtn py-0"
                        v-ripple="'rgba(255, 255, 255, 0.35)'"
                        id="download_button"
                        @click="handleFileDownload"
                      >Download Preview</el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="text-right form-group"
              style="padding-top: 30px"
            >
              <el-button
                style="position: relative; bottom: 9px; color: white"
                type="danger"
                class="z-depth-0"
                v-ripple="'rgba(255, 255, 255, 0.35)'"
                @click="handleClose"
              >Cancel</el-button>
              <el-button
                style="
                  background-color: var(--el-app-primary);
                  position: relative;
                  bottom: 9px;
                  color: white;
                "
                class="z-depth-0"
                v-ripple="'rgba(255, 255, 255, 0.35)'"
                @click="onSubmitFile"
              >Confirm & Submit</el-button>
            </div>
            <span
              class="pt-0 text-left"
              style="font-size: .8em; font-style: italic; color: rgba(0, 0, 0, 0.8)"
            >
              This will <b style="font-weight: 500">immediately</b> submit your answer sheet for this Examination, ensure it is the right one before submitting.
            </span>
          </div>
        </el-dialog>
      </div>
    </section>
  </article>
</template>

<script>
import ScaleOut from "@/components/scale-out-component.vue";
import pdf from "vue-pdf";
export default {
  components: {
    pdf,
    ScaleOut,
  },
  data() {
    return {
      isBusy: false,
      loading: false,
      isLoading: true,
      activePage: 1,
      progress: 0,
      totalPages: "?",
    };
  },

  props: {
    isVisible: {
      required: true,
      type: Boolean,
    },
    filePathUrl: {
      type: String,
      required: true,
    },
  },

  computed: {
    downloadProgress() {
      return Math.round(this.progress * 100);
    },
  },

  methods: {
    handleClose() {
      this.clearInfo();
      this.$emit("close-dialog");
    },

    clearInfo() {
      this.activePage = 1;
      this.progress = 0;
      this.totalPages = "?";
    },
    onSubmitFile() {
      // this.loading = true;
      this.$emit("submit-file");
    },
    onPDfError() {
      this.showFailedMessage(
        "loading Failed",
        "Unable to load the selected Document, please try again"
      );
      // this.$router.back();
    },
    onLoaded() {
      this.isLoading = false;
    },
    onProgress(progress) {
      if (!progress) return;
      this.progress = progress;
    },
    onNextPage() {
      if (this.activePage == this.totalPages) return;
      ++this.activePage;
      window.scrollTo(0, 0);
    },
    onPreviousPage() {
      if (this.activePage == 1) return;
      --this.activePage;
      window.scrollTo(0, 0);
    },
    onPageNumberObtained(pageNumber) {
      this.totalPages = pageNumber;
    },
    handleFileDownload() {
      const link = document.createElement("a");
      link.href = this.filePathUrl;
      link.download = "Examination -Preview File";
      link.click();
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
}
</style>
