<template>
  <div>
    <i
      @click="$router.push({ path: '/dashboard/home' })"
      class="fas fa-home bread-crumb-home"
      style="font-size: 23px; color: #919191; cursor: pointer"
    ></i>
    <span class="px-2" style="font-size: 0.8em; font-weight: 500; color: #919191" >></span
    >
    <span v-for="(page, index) in pageTitles" :key="index">
      <span @click="onNavigateBack(index)" class="bread-crumb-item">{{
        page
      }}</span>
      <span
        v-if="index != pageTitles.length - 1"
        class="px-2"
        style="font-size: 0.8em; font-weight: 500; color: #919191"
        >></span
      >
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // historyBackIndex: 0,
    };
  },
  props: {
    pageTitles: {
      type: Array,
      required: true,
    },
  },

  mounted() {
    //Atempt to get the last item index as we shall use it to go back
    // this.historyBackIndex = this.pageTitles.length - 1;
  },
  methods: {
    onNavigateBack(index) {
      let goBackSteps = index - (this.pageTitles.length - 1);
      if (goBackSteps == 0) return;
      this.$router.go(goBackSteps);
      goBackSteps;
    },
  },
};
</script>

<style>
</style>