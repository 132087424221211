<template>
  <article class="upload-answe-sheet">
    <el-button
      type="success"
      style="margin-right: 10px"
      @click="showUploadSheetDialog"
    >Submit Answer Sheet
      <i
        style="margin-left: 10px; color: #fff"
        class="fas fa-upload"
      ></i>
    </el-button>
    <section>
      <div class="upload_answer_sheet_dialog">
        <el-dialog
          title="Submit Answer Sheet"
          class="text-left"
          :visible.sync="uploadAnswerSheetDialog"
          :destroy-on-close="true"
          :before-close="handleClose"
        >
          <div v-loading="
            isBusy || isSubmittingOnlineAnswer || fileUploadingProgress == 100
          ">
            <div class="px-3 row">
              <div class="col-md-12">
                <label
                  for="file"
                  style="width: 100%; font-weight: 600"
                >
                  <strong>Answer Sheet</strong>
                </label>
                <br />
                <div
                  v-if="uploadType == 'UPLOAD'"
                  class="mt-4"
                  style="display: flex; justify-content: center"
                >
                  <el-upload
                    class="upload-demo"
                    drag
                    :action="''"
                    :file-list="answerSheetsFiles"
                    :on-remove="handleAnswerRemove"
                    :on-change="handleAnswerPreview"
                    :auto-upload="false"
                    :multiple="false"
                  >
                    <div v-if="!isLoading">
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">
                        Drop file here or <em>click to upload</em>
                      </div>
                    </div>
                    <div
                      v-else
                      style="margin-top: 30px"
                    >
                      <el-progress
                        v-if="fileUploadingProgress != 100"
                        type="circle"
                        :color="progressColors"
                        :percentage="fileUploadingProgress"
                        :width="80"
                        :stroke-width="4"
                      ></el-progress>
                      <el-progress
                        v-else
                        type="circle"
                        :percentage="100"
                        status="success"
                        :width="80"
                        :stroke-width="4"
                      ></el-progress>
                      <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                        {{
                            fileUploadingProgress == 100
                              ? "Finalizing..."
                              : "Uploading..."
                        }}
                      </div>
                    </div>
                  </el-upload>
                </div>
                <div v-else>
                  <div
                    class="col-12"
                    style="
                      border: 1px solid rgba(0, 0, 0, 0.5);
                      padding: 10px 15px;
                      border-left: 6px solid red;
                    "
                  >
                    You have Chosen to Submit ONLINE Answer Sheet
                  </div>
                </div>
              </div>
              <div class="pb-2 mt-4 text-left col-12">
                <div style="font-weight: 400; font-size: 0.9em">
                  To Submit your Answer Sheet, you should acknowledge that this
                  is your Original work and you have not done any Plagiarism and
                  accept responsibility in situations where you have violated
                  the University's Rules and Regulations governing Course Work.
                </div>
                <el-checkbox
                  class="mt-3"
                  v-model="consentAgreed"
                >
                  Yes, I agree.</el-checkbox>
              </div>
            </div>
            <div
              class="text-right form-group"
              style="padding-top: 30px"
            >
              <el-button
                style="
                  background-color: var(--el-app-primary);
                  position: relative;
                  bottom: 9px;
                  color: white;
                "
                class="z-depth-0"
                v-ripple="'rgba(255, 255, 255, 0.35)'"
                :disabled="isLoading"
                @click="showPreviewDialog"
              >Continue & Preview</el-button>
            </div>
            <span
              class="pt-0 text-left"
              style="
                font-size: 0.8em;
                font-style: italic;
                color: rgba(0, 0, 0, 0.8);
              "
            >
              Pressing <b style="font-weight: 500">Continue & Preview</b> will
              Preview your answer sheet for confirmation before you submit
            </span>
          </div>
        </el-dialog>
      </div>
    </section>
    <OnlineAnswerSheetPreviewDialog
      :isVisible="openOnlineAnswerSheetPreviewDialog"
      :studentAnswers="studentAnswers"
      @close-dialog="closeOnlineAnswerSheetPreviewDialog"
      @submit-answer-sheet="onSubmitAnswers"
    />
    <PreviewFileDialog
      :isVisible="openPreviewFileDialog"
      @close-dialog="closePreviewFileDialog"
      :filePathUrl="previewFileUrl"
      @submit-file="onSubmitAnswers"
    />
  </article>
</template>

<script>
import OnlineAnswerSheetPreviewDialog from "../../dialogs/preview-online-answer-sheet-dialog.vue";
import PreviewFileDialog from "../../dialogs/preview-file-dialog.vue";
export default {
  name: "app",
  components: {
    OnlineAnswerSheetPreviewDialog,
    PreviewFileDialog,
  },

  data() {
    return {
      uploadAnswerSheetDialog: false,
      isBusy: false,
      consentAgreed: false,
      answerSheetsFiles: [],
      uploadType: "",
      selectedAnswerFile: null,
      openOnlineAnswerSheetPreviewDialog: false,
      openPreviewFileDialog: false,
      isLoading: false,
      filePathUrl: "",
      fileUploadingProgress: 0,
      previewFileUrl: "",
      uploadUrlInfo: {},
      progressColors: [
        { color: "#f56c6c", percentage: 25 },
        { color: "#e6a23c", percentage: 50 },
        { color: "#1989fa", percentage: 75 },
        { color: "#6f7ad3", percentage: 100 },
      ],
    };
  },

  mounted() { },

  props: {
    courseWorkId: {
      type: String,
      required: true,
    },
    studentAnswers: {
      type: String,
      required: true,
    },
    isSubmittingOnlineAnswer: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    canSubmit() {
      if (this.uploadType == "UPLOAD") {
        return this.selectedAnswerFile && this.consentAgreed;
      } else if (this.uploadType == "ONLINE") {
        return this.consentAgreed;
      }

      return false;
    },
  },
  methods: {
    async onSubmitAnswers() {
      let fullScreenLoader;
      try {
        this.isBusy = true;
        if (!this.uploadType) return;

        if (this.uploadType == "UPLOAD") {
          //Request upload URL
          // const uploadUrlInfo = await this.requestUploadUrl();
          fullScreenLoader = this.$loading({
            lock: true,
            text: "Submitting Answer sheet.",
            spinner: "el-icon-loading",
            background: "rgba(0%, 0%, 0%, 0.7)",
          });
          if (!this.uploadUrlInfo) return;

          //Now that the student has uploaded their answer sheet, let them tell the backend they have submitted.

          const request = await this.$http.post(
            "course-work/submit-answer-sheet",
            {
              course_work_id: this.courseWorkId,
              answer_sheet_path_url: this.filePathUrl,
              answer_sheet_path_key: this.uploadUrlInfo.path_key,
              file_name: this.selectedAnswerFile.raw.name,
            }
          );

          if (
            request.data.success &&
            request.data.message == "ANSWER SHEET SUBMITTED SUCCESSFULLY"
          ) {
            this.showSuccessMessage(
              "Course work Submitted",
              "Course work Answers successfully submitted"
            );
            this.uploadAnswerSheetDialog = false;
            this.$router.replace({ name: "Courseworks" });
          } else if (request.data.message == "COURSE WORK IS NOT AVAILABLE") {
            this.showWarningMessage(
              "Course Work Not Available",
              "The Course work you are trying to submit your answer sheet to is no longer available. Please Contact Admin for Help."
            );
          } else if (
            request.data.message ==
            "VALID STUDENT COURSE WORK RELATED INFO NOT FOUND" ||
            request.data.message ==
            "ANSWER SHEET AND PATH URL DONT SEEM TO MATCH"
          ) {
            this.showWarningMessage(
              "course work Info mismatch",
              "Unable to provide consistent course work info for your account. Please try again"
            );
            this.$router.replace({ name: "Courseworks" });
          } else {
            this.$rollbar.critical(
              "STUDENTS COURSE WORKS FRONT END: Unexpected Error occurred while submitting course work",
              request
            );
            throw "UNEXPECTED API RESPONSE";
          }
        } else if (this.uploadType == "ONLINE") {
          //Otherwise, submit the Answer Sheet
          this.$emit("on-submit-online-answer-sheet");
        }
      } catch (error) {
        if (error.response && error.response.status == 400) {
          if (
            error.response.data.message == "UNSUPPORTED ANSWER SHEET FILE TYPE"
          ) {
            return this.showWarningMessage(
              "Unsupported file type",
              "The Answer Sheet file type you submitted is not accepted"
            );
          } else if (
            error.response.data.message ==
            "ANSWER SHEET SHOULD BE LESS THAN 100MBS"
          ) {
            return this.showWarningMessage(
              "File too big",
              "The Answer Sheet file type you submitted is too big"
            );
          } else {
            this.$rollbar.critical(
              "STUDENTS COURSE WORKS FRONT END: Unexpected Error occurred while submitting coursework",
              { message: error.response.data.message },
              error
            );
          }
        } else {
          if (error.message === "Network Error") {
            return this.showFailedMessage(
              "Connection Failed",
              "Unable to Connect. Please check your Internet Connection and try again."
            );
          }

          this.$rollbar.critical(
            "STUDENTS COURSE WORKS FRONT END: Unexpected Error occurred while submitting course work",
            error
          );

          // console.log(error);
          this.showFailedMessage(
            "Unable to Submit",
            "An unexpected Error occurred, please try again"
          );
        }
      } finally {
        if (
          (this.uploadType == "UPLOAD" && !this.selectedAnswerFile) ||
          !this.consentAgreed ||
          this.uploadType == "ONLINE"
        ) {
          //Dont close the dialog
        } else {
          this.uploadAnswerSheetDialog = false;
        }
        this.isBusy = false;
        this.closePreviewFileDialog();
        // this.closeOnlineAnswerSheetPreviewDialog();
        // fullScreenLoader?.close?.call();
        if (fullScreenLoader) {
          fullScreenLoader.close();
        }
      }
    },

    async uploadResourceToAWS({ aws_upload_url, selected_file, onProgress }) {
      const formData = new FormData();
      Object.entries(aws_upload_url.fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("file", selected_file);
      let request = await this.$http.post(`${aws_upload_url.url}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: " ",
        },
        onUploadProgress: onProgress,
        // onUploadProgress: (progressEvent) => console.log(progressEvent),
      });

      if (request.status == 204) {
        return request.headers.location;
      } else {
        throw "UNABLE TO UPLOAD FILE TO AWS";
      }
    },

    async requestUploadUrl() {
      let request = await this.$http.post(
        "course-work/request-for-answersheet-upload-url",
        {
          course_work_id: this.courseWorkId,
          answer_sheet: {
            file_name: this.selectedAnswerFile.raw.name,
            file_size: this.selectedAnswerFile.raw.size,
          },
        }
      );

      if (
        request.data.success &&
        request.data.message == "ANSWER SHEET UPLOAD URL GENERATED"
      ) {
        return request.data.answer_sheet;
      } else if (
        request.data.message ==
        "VALID STUDENT COURSE WORK RELATED INFO NOT FOUND"
      ) {
        this.showWarningMessage(
          "Course Work Info mismatch",
          "Unable to provide consistent course work info for your account. Please try again"
        );
        this.$router.replace({ name: "Courseworks" });
      } else if (request.data.message == "COURSE WORK IS NOT AVAILABLE") {
        this.showWarningMessage(
          "Course Work Not Available",
          "The Course Work you are trying to submit your answer sheet to is no longer available. Please Contact Admin for Help."
        );
      } else {
        this.$rollbar.warning(
          "STUDENT FRONT END: Unexpected API response while getting server response",
          request.data,
          request
        );
        throw "UNEXPECTED_RESPONSE";
      }
    },

    async requestForConvertedPDFFile(wordDocumentUrl) {
      try {
        let request = await this.$http.post(
          "examinations/convert-word-to-pdf",
          { file_path_url: wordDocumentUrl }
        );

        if (
          request.data.success &&
          request.data.message == "PDF FILE CREATED SUCCESSFULLY"
        ) {
          return request.data.pdf_file_path;
        } else {
          this.$rollbar.warning(
            "STUDENT FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        return;
      }
    },

    handleClose(done) {
      this.clearInputs();
      done();
    },

    clearInputs() {
      this.answerSheetsFiles = [];
      this.selectedAnswerFile = null;
      this.consentAgreed = false;
      this.filePathUrl = "";
    },

    handleChange(value) {
      console.log(value);
    },

    handleAnswerPreview(file) {
      this.answerSheetsFiles = [file];
      this.selectedAnswerFile = file;
    },
    handleAnswerRemove() {
      this.selectedAnswerFile = null;
    },

    async showUploadSheetDialog() {
      //Otherwise means the lecture is either ongoing or is in the future.
      await this.$alert(
        "Please Choose a method of submitting your answer sheet, you can either upload the Answer Sheet file you downloaded or Submit the Online Answer sheet.",
        "Choose Submit Method",
        {
          confirmButtonText: "Online Answers",
          cancelButtonText: "Upload File",
          showCancelButton: true,
          distinguishCancelAndClose: true,
          closeOnClickModal: true,
          closeOnPressEscape: true,

          // type: "warning",
          callback: (action) => {
            if (action == "confirm") {
              this.uploadType = "ONLINE";

              this.uploadAnswerSheetDialog = true;
            } else if (action == "cancel") {
              this.uploadType = "UPLOAD";

              this.uploadAnswerSheetDialog = true;
            }
          },
        }
      );
    },
    async showPreviewDialog() {
      if (!this.consentAgreed) {
        return this.showWarningMessage(
          "Consent Required.",
          "In order to submit your answersheet, please agree to the No Plagiarism disclaimer."
        );
      }
      if (this.uploadType == "ONLINE") {
        this.openOnlineAnswerSheetPreviewDialog = true;
      } else {
        try {
          if (this.selectedAnswerFile != null) {
            //If its a notes they are trying to upload, ensure its the accepted file type and size

            //Ensure it is not more than 100Mbs
            /* if (this.selectedAnswerFile.size > 1024 * 1024 * 1024) {
              return this.showWarningMessage(
                "Too Big",
                "The Answer Sheet File you are trying to upload is too Big."
              );
            } else */

            if (this.selectedAnswerFile.raw.size === 0) {
              return this.showWarningMessage(
                "Invalid File Size",
                "The file you are trying to upload is 0KB. Please ensure you're uploading the the correct file."
              );
            }
          } else {
            this.showWarningMessage(
              "Empty File",
              "Please ensure you have chosen an answer sheet file to submit"
            );
            return;
          }
          this.isLoading = true;
          //Request upload URL
          this.uploadUrlInfo = await this.requestUploadUrl();
          if (!this.uploadUrlInfo) return;
          this.filePathUrl = await this.uploadResourceToAWS({
            aws_upload_url: this.uploadUrlInfo.upload_url,
            selected_file: this.selectedAnswerFile.raw,
            // eslint-disable-next-line no-unused-vars
            onProgress: (progressEvent) => {
              this.fileUploadingProgress = +(
                (progressEvent.loaded / progressEvent.total) *
                100
              ).toFixed(0);
            },
          });

          //Now just before we open up the preview dialog, we check if the file is a word document, if it is, we convert it to pdf and show the pdf instead
          const fileExtension = this.filePathUrl.split(".").pop()?.toLowerCase();

          const wordDocumentExtensions = [
            "doc",
            "docx",
            "ppt",
            "pptx",
            "xls",
            "xlsx",
          ];

          if (wordDocumentExtensions.includes(fileExtension)) {
            const convertedFileURL = await this.requestForConvertedPDFFile(
              this.filePathUrl
            );

            //If the conversion was successful, we set the file path url to the converted file url
            this.previewFileUrl = convertedFileURL
              ? convertedFileURL
              : this.filePathUrl;
          } else {
            this.previewFileUrl = this.filePathUrl;
          }

          this.openPreviewFileDialog = true;
        } catch (error) {
          if (error.message === "Network Error") {
            return this.showFailedMessage(
              "Connection Failed",
              "Unable to Connect. Please check your Internet Connection and try again."
            );
          }
        } finally {
          this.isLoading = false;
          this.fileUploadingProgress = 0;
        }
      }
    },
    closeOnlineAnswerSheetPreviewDialog() {
      this.openOnlineAnswerSheetPreviewDialog = false;
      this.uploadAnswerSheetDialog = false;
      this.clearInputs();
    },
    closePreviewFileDialog() {
      this.openPreviewFileDialog = false;
      this.uploadAnswerSheetDialog = false;
      this.clearInputs();
    },
  },
};
</script>

<style>
.upload-answe-sheet .el-checkbox__label {
  white-space: normal !important;
}
</style>

<style scoped>
* {
  box-sizing: border-box;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }

  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }

  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
[type="radio"]:checked+label:after {
  border-color: #67c23a !important;
  background-color: #67c23a !important;
  z-index: 0;
  transform: scale(1.02);
}

[type="checkbox"][class*="filled-in"]:checked+label:after {
  border-color: #67c23a !important;
  background-color: #67c23a !important;
  z-index: 0;
}

.upload_answer_sheet_dialog .el-dialog {
  width: 50%;
}

.search_by_input {
  width: 400px !important;
}

.upload_answer_sheet_dialog .el-button--primary {
  color: #fff;
  background-color: #409eff !important;
  border-color: #409eff !important;
}

el-table th>.cell {
  color: black !important;
  font-weight: 500;
}

.el-table .cell {
  font-size: 0.9em;
  line-height: 23px;
  font-weight: 300;
}

.upload_answer_sheet_dialog .el-icon-upload:before {
  font-size: 50px;
}

.upload_answer_sheet_dialog .el-tooltip__popper {
  display: none;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .upload_answer_sheet_dialog .el-dialog {
    width: 50%;
    background-color: #fafafa;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .upload_answer_sheet_dialog .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .upload_answer_sheet_dialog .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .upload_answer_sheet_dialog .el-dialog {
    width: 90%;
  }
}
</style>
