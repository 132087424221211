/* eslint-disable  no-unused-vars*/
import Vue from 'vue'
import store from "../store";
import VueRouter, { RouteConfig } from 'vue-router'
import Dashboard from '../views/dashboard.vue'
import Home from "../views/home.vue";
import Chats from "../views/chats.vue";
import MyCourseUnits from "../views/course-units/my-course-units.vue";
import ResourceDetails from "../views/resource-details.vue";
import MyCalendar from "../views/my-calendar.vue";
import Settings from "../views/settings.vue";
import Help from "../views/help.vue";
import UserProfile from "../views/user-profile.vue";
import StudentProfile from "../views/profiles/student-profile.vue";
import StaffProfile from "../views/profiles/staff-profile.vue";
import SearchResults from "../views/search-results.vue";
import Lectures from "../views/lectures.vue";
import LiveLecture from "../views/live-lecture.vue";
import CompleteAccountSetUp from "../views/complete-account-set-up.vue";
import RecordedLecture from "../views/recorded-lecture.vue";
import CourseUnitResources from "../views/course-unit-resources.vue";
import NoticeBoard from "../views/notice-board.vue";
import StudentChat from "../views/students-chat.vue";

import Examinations from "../views/examinations/examinations.vue";
import ExamDetails from "../views/examinations/exam-details.vue";
import AnswerSheet from "../views/examinations/answer-sheet.vue";

import Courseworks from "../views/courseWorks/courseworks.vue";
import CourseworksAll from "../views/courseWorks/courseworks-all.vue";
import CourseworksDetails from "../views/courseWorks/courseworks-details.vue";
import CourseworksAnswerSheet from "../views/courseWorks/answer-sheet.vue";
import CourseWorkInstructions from "../views/courseWorks/coursework-instructions.vue";

import ExamInstructions from "../views/exam-and-test-instructions.vue";
import OngoingExamination from "../views/ongoing-examination.vue";
import OngoingCourseWork from "../views/courseWorks/ongoing-course-work.vue";
import Tutorials from "../views/tutorials.vue";
import TutorialDetails from "../views/tutorial-detail.vue";
// import CourseWork from "../views/course-work.vue";
import CourseWorkDetails from "../views/course-work-details.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
   {
      path: '/',
      redirect: '/dashboard/home',
      meta: { requiresLogin: true }
   },
   {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: () => import(/* webpackChunkName: "ForgotPassword" */ '../views/forgot-password.vue'),
      meta: { requiresLogin: false }
   },
   {
      path: '/enter-verification-code',
      name: 'EnterVerificationCode',
      component: () => import(/* webpackChunkName: "EnterVerificationCode" */'../views/enter-verification-code.vue'),
      meta: { requiresLogin: false }

   },
   {
      path: '/reset-password',
      name: 'ResetPassword',
      component: () => import(/* webpackChunkName: "ResetPassword" */'../views/reset-password.vue'),
      meta: { requiresLogin: false }

   },
   {
      path: '/login',
      name: 'Login',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "Login" */ '../views/login.vue'),
      meta: { requiresLogin: false }
   },
   {
      path: '/complete-account-set-up',
      name: 'CompleteAccountSetUp',
      component: CompleteAccountSetUp,
      meta: { requiresLogin: false }
   },

   {
      path: '/payments/make-payment',
      name: 'MakePayment',
      component: () => import(/* webpackChunkName: "MakePayment" */ '../views/make-payment.vue')
   },
   {
      path: '/payments/make-online-application-payment',
      name: 'MakeOnlineApplicationPayment',
      component: () => import(/* webpackChunkName: "MakeOnlineApplicationPayment" */ '../views/make-online-application-payment.vue')
   },
   {
      path: '/payments',
      name: 'Payments',
      component: () => import(/* webpackChunkName: "Payments" */ '../views/payments.vue')
   },
   {
      path: '/payment-outcome/:id',
      name: 'PaymentOutcome',
      component: () => import(/* webpackChunkName: "PaymentOutcome" */ '../views/payment-outcome.vue')
   },
   {
      path: '/website-application-payment-outcome/:id',
      name: 'WebsiteApplicationPaymentOutcome',
      component: () => import(/* webpackChunkName: "WebsiteApplicationPaymentOutcome" */ '../views/website-application-payment-outcome.vue')
   },
   {
      path: '/make-card-payment',
      name: 'MakeCardPayment',
      component: () => import(/* webpackChunkName: "MakeCardPayment" */ '../views/make-card-payment.vue')
   },
   {
      path: '/verify',
      name: 'VerifyTranscript',
      component: () => import(/* webpackChunkName: "VerifyTranscript" */ '../views/verify-transcript.vue')
   },
   {
      path: '/ongoing-lecture/',
      name: 'OngoingLecture',
      component: () => import(/* webpackChunkName: "OngoingLecture" */ '../views/ongoing-lecture.vue'),
      meta: { requiresLogin: true }
   },

   {
      path: '/dashboard',
      name: 'Dashboard',
      meta: { requiresLogin: true },
      component: Dashboard,
      children: [
         {
            path: 'home',
            name: 'Home',
            component: Home,
            meta: { requiresLogin: true }
         },
         {
            path: 'chats',
            name: 'Chats',
            component: Chats,
            meta: { requiresLogin: true },
         },
         {
            path: 'my-course-units',
            name: 'MyCourseUnits',
            component: MyCourseUnits,
            meta: { requiresLogin: true },
         },
         {
            path: 'my-course-units/failed',
            name: 'MyFailedModules',
            component: () => import(/* webpackChunkName: "MyFailedModules" */'../views/my-failed-modules.vue'),
         },
         {
            path: 'my-course-units/:courseUnitId',
            name: 'CourseUnitResources',
            component: CourseUnitResources,
            meta: { requiresLogin: true },
         },
         {
            path: 'resource-details',
            name: 'ResourceDetails',
            component: ResourceDetails,
            meta: { requiresLogin: true },
         },
         {
            path: 'my-calendar',
            name: 'MyCalendar',
            component: MyCalendar,
            meta: { requiresLogin: true }
         },

         {
            path: "student-modules-history",
            name: "StudentModulesHistory",
            component: () => import("../views/student-modules-history.vue"),
         },
         {
            path: 'course-work-details/:courseWorkId',
            name: 'CourseWorkDetails',
            component: CourseWorkDetails,
         },
         {
            path: 'settings',
            name: 'Settings',
            component: Settings,
            meta: { requiresLogin: true }
         },
         {
            path: 'help',
            name: 'Help',
            component: Help,
         },
         {
            path: 'user-profile',
            name: 'UserProfile',
            component: UserProfile,
         },
         {
            path: 'student-profile/:sId',
            name: 'StudentProfile',
            component: StudentProfile,
         },
         {
            path: 'staff-profile/:sId',
            name: 'StaffProfile',
            component: StaffProfile,
         },
         {
            path: 'search-results',
            name: 'SearchResults',
            component: SearchResults
         },
         {
            path: 'lectures',
            name: 'Lectures',
            component: Lectures,
            meta: { requiresLogin: true }
         },
         {
            path: 'inquiries',
            name: 'inquiries',
            component: StudentChat,
            meta: { requiresLogin: true }
         },
         {
            path: 'live-lecture',
            name: 'LiveLecture',
            component: LiveLecture,
            meta: { requiresLogin: true }
         },
         {
            path: 'recorded-lecture',
            name: 'RecordedLecture',
            component: RecordedLecture,
         },
         {
            path: 'notice-board',
            name: 'NoticeBoard',
            component: NoticeBoard,
         },

         {
            path: 'examinations',
            name: 'Examinations',
            component: Examinations,
         },
         {
            path: 'course-works',
            name: 'Courseworks',
            component: Courseworks,
         },
         {
            path: 'courseworks-all',
            name: 'CourseworksAll',
            component: CourseworksAll,
         },
         {
            path: 'tutorials',
            name: 'Tutorials',
            component: Tutorials,
         },
         {
            path: 'tutorial-details',
            name: 'TutorialDetails',
            component: TutorialDetails,
         },
         {
            path: 'examinations/instructions/:examID/:courseUnitId',
            name: 'ExamInstructions',
            component: ExamInstructions,
         },
         {
            path: 'course-work/instructions/:courseWorkID/:courseUnitId',
            name: 'CourseWorkInstructions',
            component: CourseWorkInstructions,
         },
         {
            path: 'examinations/ongoing/:examId',
            name: 'OngoingExamination',
            component: OngoingExamination,
         },
         {
            path: 'course-work/ongoing/:courseWorkId',
            name: 'OngoingCourseWork',
            component: OngoingCourseWork,
         },
         {
            path: ':examId/exam-details',
            name: 'ExamDetails',
            component: ExamDetails,
         },
         {
            path: ':courseworkId/course-works-details',
            name: 'CourseworksDetails',
            component: CourseworksDetails,
         },
         {
            path: ':answerId/answer-sheet',
            name: 'AnswerSheet',
            component: AnswerSheet,
         },
         {
            path: ':CourseworksAnswerSheetId/course-works-answer-sheet',
            name: 'CourseworksAnswerSheet',
            component: CourseworksAnswerSheet,
         },

         {
            path: 'student-results',
            name: 'StudentResults',
            component: () => import('../views/student-results.vue'),
         },

         // {
         //    path: 'student-financial-statements',
         //    name: 'StudentFinancialStatements',
         //    component: () => import('../views/students-financial-statements.vue'),
         // },
         {
            path: 'elections/intro',
            name: 'Elections',
            component: () => import('../views/elections.vue'),
         },
         {
            path: 'elections/candidates',
            name: 'ElectionCandidates',
            component: () => import('../views/election-candidates.vue'),
         },
         {
            path: 'transactions',
            name: 'Transactions',
            component: () => import(/* webpackChunkName: "Transactions" */'../views/transactions.vue'),
         },
         {
            path: 'apply-for-changes',
            name: 'ApplyForChanges',
            component: () => import(/* webpackChunkName: "ApplyForChanges" */'../views/apply-for-changes.vue'),
         },
         {
            path: 'programme-change-request',
            name: 'ProgrammeChangeRequest',
            component: () => import(/* webpackChunkName: "ProgrammeChangeRequest" */'../views/programme-change-request.vue'),
         },
         {
            path: 'intake-change-request',
            name: 'IntakeChangeRequest',
            component: () => import(/* webpackChunkName: "IntakeChangeRequest" */'../views/intake-change-request.vue'),
         },
         {
            path: 'session-change-request',
            name: 'SessionChangeRequest',
            component: () => import(/* webpackChunkName: "SessionChangeRequest" */'../views/session-change-requests.vue'),
         },
         //In case the route is anything else
         {
            path: '*',
            redirect: '/dashboard/home'
         },

      ]
   },

   //In case the route is anything else
   {
      path: '*',
      redirect: '/dashboard/home'
   },
   // {
   //    path: '/signup',
   //    name: 'Signup',
   // route level code-splitting
   // this generates a separate chunk (about.[hash].js) for this route
   // which is lazy-loaded when the route is visited.
   //    component: () => import(/* webpackChunkName: "about" */ '../views/signup.vue')
   //  }
]

const router = new VueRouter({
   mode: 'history',
   base: process.env.BASE_URL,
   routes,

   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   scrollBehavior(to, from, savedPosition) {
      return { x: 0, y: 0, behavior: 'smooth', }
   }
})


router.beforeResolve((to, from, next) => {

   if (((to.name === 'Login') || (to.name === 'SignUp') || (to.name === 'EnterVerificationCode') || (to.name === 'CompleteAccountSetUp')) && store.getters.isLoggedIn) {
      next({ path: '/dashboard/home' })
   }
   //Check if the route is a restricted one to some users
   // if (to.name === 'Analysis' || to.name === 'Documentation' || to.name === 'Settings') {
   //   if (store.state.accessLevel != 'Super') {
   //     next({ path: '/pcms' });
   //   }
   // }

   //Check if login is required for Routes with meta data loginRequired!
   if (to.matched.some(record => record.meta.requiresLogin)) {

      if (!store.getters.isLoggedIn) {
         //Redirect to Login Page
         next({ path: '/login' })
      } else next();
   } else next();
})

export default router
