<template>
  <div class="container-fluid">
    <br /><br />

    <div v-if="is_permitted">
      <div id="the-tab-section">
        <div style="display: flex; justify-content: center">
          <div id="the-tab">
            <el-tabs
              class="mx-auto"
              :stretch="true"
              v-model="activeName"
              @tab-click="onTabClick"
            >
              <el-tab-pane name="questionPaper">
                <span slot="label"
                  ><i class="pr-2 el-icon-paperclip"></i> Question Paper</span
                >
              </el-tab-pane>
              <el-tab-pane name="answerSheet">
                <span slot="label"
                  ><i class="pr-2 el-icon-document"></i> Answer Sheet</span
                >
              </el-tab-pane>
              <el-tab-pane name="submissions">
                <span slot="label"
                  ><i class="el-icon-user"></i> Submissions</span
                >
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>

      <div v-if="activeName == 'questionPaper'" class="row">
        <div class="pb-3 mt-5 text-left col-12">
          <BreadCrumbNavigation
            v-if="examination"
            :pageTitles="['Examinations', 'Question Paper']"
          />
        </div>

        <!-- DOCUMENT examinationS -->
        <div v-if="question_fileExtension == 'pdf'" class="mx-0 row w-100">
          <div v-show="!isLoading" class="mx-0 row w-100">
            <div class="col-12">
              <span
                v-if="examination.title"
                class="float-left pb-4"
                style="
                  font-weight: 600;
                  text-transform: capitalize;
                  font-size: 1.2em;
                "
              >
                ({{ examination.course_unit_code }}) {{ examination.title }}
              </span>
              <el-button
                size="small"
                type="success"
                @click="downloadQuestionPaper(examination.path_key)"
                >Download Question Paper
                <i style="margin-left: 10px" class="fas fa-download"></i
              ></el-button>
              <span class="float-right">
                <span class="pr-5" style="font-weight: 400">
                  Page <strong>{{ activePage }}</strong> of
                  {{ totalPages ? totalPages : "N/A" }}
                </span>
                <div style="display: inline-flex">
                  <div @click="onPreviousPage" class="direction_arrows">
                    <i class="fas fa-long-arrow-alt-left"></i>
                  </div>
                  <div @click="onNextPage" class="direction_arrows">
                    <i class="fas fa-long-arrow-alt-right"></i>
                  </div>
                </div>
              </span>
            </div>
            <div class="col-12">
              <pdf
                :src="examination.path_url"
                :page="activePage"
                @num-pages="onPageNumberObtained"
                @progress="onProgress"
                @loaded="onLoaded"
                @error="onPDfError"
              >
              </pdf>
            </div>
            <div class="text-right col-12">
              <span class="pr-5" style="font-weight: 400"
                >Page <strong>{{ activePage }}</strong> of
                {{ totalPages ? totalPages : "N/A" }}
              </span>
              <div class="pt-3" style="display: inline-flex">
                <div @click="onPreviousPage" class="direction_arrows">
                  <i class="fas fa-long-arrow-alt-left"></i>
                </div>
                <div @click="onNextPage" class="direction_arrows">
                  <i class="fas fa-long-arrow-alt-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="isLoading"
            class="text-center col-12"
            style="margin-top: 130px"
          >
            <ScaleOut :background="'#1F4EA1'" class="mx-auto" />
            <div style="font-size: 0.9em; font-weight: 500">
              {{ downloadProgress }}% Loading...
            </div>
          </div>
        </div>

        <!-- OTHER SUPPORTED DOCUMENT VIEWER LONG AS IT'S ONE OF .DOC, .DOCX, .XLS, .XLSX, .PPT, .PPTX, .ODT, .ODS, .ODP, .PDF, .TXT -->
        <div v-else class="row w-100">
          <div
            class="pb-4 text-left col-12"
            style="
              font-weight: 600;
              text-transform: capitalize;
              font-size: 1.2em;
            "
          >
            {{ examination.title }}

            <el-button
              size="small"
              type="success"
              @click="downloadQuestionPaper(examination.path_key)"
              >Download Question Paper
              <i style="margin-left: 10px" class="fas fa-download"></i
            ></el-button>
          </div>
          <div class="text-left col-12">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                id="document_viewer_live"
                :src="`https://view.officeapps.live.com/op/embed.aspx?src=${examination.path_url}`"
                class="embed-responsive-item"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>

        <div v-loading="loading" style="height: 50vh"></div>
      </div>

      <div v-else-if="activeName == 'answerSheet'" class="row">
        <div class="pb-3 mt-5 text-left col-12">
          <BreadCrumbNavigation
            v-if="examination"
            :pageTitles="['Examinations', 'Answer sheet']"
          />
        </div>

        <!-- DOCUMENT examinationS -->
        <div v-if="answerSheet_fileExtension == 'pdf'" class="mx-0 row w-100">
          <div v-show="!isLoading" class="mx-0 row w-100">
            <div class="col-12">
              <span
                v-if="examination.title"
                class="float-left pb-4"
                style="
                  font-weight: 600;
                  text-transform: capitalize;
                  font-size: 1.2em;
                "
              >
                {{ examination.title }}
              </span>

              <el-button
                size="small"
                type="success"
                @click="downloadAnswerSheet(examination.answer_path_key)"
                >Download Answer Sheet
                <i style="margin-left: 10px" class="fas fa-download"></i
              ></el-button>
              <span class="float-right">
                <span class="pr-5" style="font-weight: 400">
                  Page <strong>{{ activePage }}</strong> of
                  {{ totalPages ? totalPages : "N/A" }}
                </span>
                <div style="display: inline-flex">
                  <div @click="onPreviousPage" class="direction_arrows">
                    <i class="fas fa-long-arrow-alt-left"></i>
                  </div>
                  <div @click="onNextPage" class="direction_arrows">
                    <i class="fas fa-long-arrow-alt-right"></i>
                  </div>
                </div>
              </span>
            </div>
            <div class="col-12">
              <pdf
                :src="examination.answer_path_url"
                :page="activePage"
                @num-pages="onPageNumberObtained"
                @progress="onProgress"
                @loaded="onLoaded"
                @error="onPDfError"
              >
              </pdf>
            </div>
            <div class="text-right col-12">
              <span class="pr-5" style="font-weight: 400"
                >Page <strong>{{ activePage }}</strong> of
                {{ totalPages ? totalPages : "N/A" }}
              </span>
              <div class="pt-3" style="display: inline-flex">
                <div @click="onPreviousPage" class="direction_arrows">
                  <i class="fas fa-long-arrow-alt-left"></i>
                </div>
                <div @click="onNextPage" class="direction_arrows">
                  <i class="fas fa-long-arrow-alt-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="isLoading"
            class="text-center col-12"
            style="margin-top: 130px"
          >
            <ScaleOut :background="'#1F4EA1'" class="mx-auto" />
            <div style="font-size: 0.9em; font-weight: 500">
              {{ downloadProgress }}% Loading...
            </div>
          </div>
        </div>

        <!-- OTHER SUPPORTED DOCUMENT VIEWER LONG AS IT'S ONE OF .DOC, .DOCX, .XLS, .XLSX, .PPT, .PPTX, .ODT, .ODS, .ODP, .PDF, .TXT -->
        <div v-else class="row w-100">
          <div
            class="pb-4 text-left col-12"
            style="
              font-weight: 600;
              text-transform: capitalize;
              font-size: 1.2em;
            "
          >
            <span>{{ examination.title }}</span>
            <el-button
              size="small"
              type="success"
              @click="downloadAnswerSheet(examination.answer_path_key)"
              >Download Answer Sheet
              <i style="margin-left: 10px" class="fas fa-download"></i
            ></el-button>
          </div>
          <div class="text-left col-12">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                id="document_viewer_live"
                :src="`https://view.officeapps.live.com/op/embed.aspx?src=${examination.answer_path_url}`"
                class="embed-responsive-item"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>

        <div v-loading="loading" style="height: 50vh"></div>
      </div>

      <div v-else-if="activeName == 'submissions'" class="row">
        <div class="pb-3 mt-5 text-left col-12">
          <BreadCrumbNavigation
            v-if="examination"
            :pageTitles="['Examinations', 'Student Submissions']"
          />
        </div>

        <article style="width: 100%">
          <section style="padding-top: 10px">
            <div class="container">
              <div class="row w-100">
                <div class="text-left col-12">
                  <div
                    style="
                      font-size: 20px;
                      font-weight: 400;
                      color: var(--el-app-primary);
                      margin-bottom: 15px;
                    "
                  >
                    <strong
                      >"{{ examination.course_unit_code }}
                      {{ examination.title }}" Student Submissions</strong
                    >
                  </div>
                </div>
              </div>

              <div class="search_add_section">
                <div class="">
                  <input
                    type="text"
                    v-model.trim="filters[0].value"
                    placeholder="Search by Title"
                    class="search_by_input"
                    spellcheck="off"
                  />
                </div>
              </div>
              <hr />

              <div v-loading="deleting" style="width: 100%">
                <data-tables
                  :data="students_doing_exam"
                  :table-props="tableProps"
                  :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
                  type="expand"
                  :filters="filters"
                >
                  <div slot="empty">
                    <ScaleOut
                      v-if="loading && !loadingError"
                      class="mt-3"
                      :background="'#164B70'"
                    />
                    <div
                      @click="getExams"
                      v-else-if="!loading && loadingError"
                      style="cursor: pointer"
                    >
                      Unable to Load Examination Now. Please click here to retry
                    </div>
                    <div v-if="!loading && !loadingError">No Examination</div>
                  </div>

                  <el-table-column
                    label="#"
                    :show-overflow-tooltip="true"
                    label-class-name="text-center"
                    class-name="text-center"
                    width="80px"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.$index + 1 }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column
                    label="Student"
                    :show-overflow-tooltip="true"
                    label-class-name="text-left"
                    width="auto"
                  >
                    <template slot-scope="scope">
                      <div style="display: flex; align-items: center">
                        <div
                          style="width: 40px; height: 40px; border-radius: 50%"
                        >
                          <div
                            v-if="scope.row.profile_picture"
                            style="
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              background-color: var(--vu-red);
                              color: white;
                              width: 40px;
                              height: 40px;
                              object-fit: cover;
                              border-radius: 100%;
                            "
                          >
                            <img
                              :src="scope.row.profile_picture"
                              class="img-fluid"
                              style="
                                width: 40px;
                                height: 40px;
                                object-fit: cover;
                                border-radius: 100%;
                              "
                            />
                          </div>
                          <div
                            v-if="scope.row.profile_picture == null"
                            style="
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              background-color: var(--vu-red);
                              color: white;
                              width: 40px;
                              height: 40px;
                              object-fit: cover;
                              border-radius: 100%;
                            "
                          >
                            <strong>{{
                              getInitials(
                                `${scope.row.first_name} ${scope.row.last_name}`
                              )
                            }}</strong>
                          </div>
                        </div>
                        <div style="margin-left: 10px">
                          <span
                            >{{ scope.row.first_name }}
                            {{ scope.row.last_name }}</span
                          ><br />
                          <span
                            ><strong style="font-weight: 600">Reg No.</strong>
                            {{ scope.row.registration_no }}</span
                          >
                        </div>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column
                    label="Intake"
                    :show-overflow-tooltip="true"
                    label-class-name="text-center"
                    class-name="text-center"
                  >
                    <template slot-scope="scope">
                      <strong>
                        {{ scope.row.course_intake_batch.course.course_code }}
                        {{ scope.row.course_intake_batch.year }}
                        {{ toMonthName(scope.row.course_intake_batch.month) }} (
                        {{ scope.row.course_intake_batch.cohort }})
                      </strong>
                    </template>
                  </el-table-column>

                  <el-table-column
                    label="Actions"
                    :show-overflow-tooltip="true"
                    label-class-name="text-center"
                    class-name="text-center"
                  >
                    <template slot-scope="scope">
                      <el-row>
                        <el-tooltip content="Answer Sheet" placement="top">
                          <el-button
                            type="warning"
                            @click="questionPaper(scope.row.student_id)"
                            icon="el-icon-document-copy"
                            circle
                          ></el-button>
                        </el-tooltip>
                      </el-row>
                    </template>
                  </el-table-column>
                </data-tables>
              </div>
            </div>
          </section>
        </article>
      </div>
    </div>

    <div v-else>
      <div class="pb-3 text-left col-12">
        <BreadCrumbNavigation
          v-if="examination"
          :pageTitles="['Examinations', 'Not Permitted']"
        />
      </div>
      <div
        style="
          height: 80vh;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <div>
          <img :src="$store.state.secureImg" width="50%" />
          <div class="mt-3">
            <h1
              style="
                font-size: 1.3em;
                font-weight: 600;
                color: var(--el-app-primary);
              "
            >
              You are not permitted to attempt this exam
            </h1>
            <p style="font-size: 0.9em">
              Please confirm with the University adminstration.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tabs, TabPane } from "element-ui";
import ScaleOut from "@/components/scale-out-component.vue";
import BreadCrumbNavigation from "../../components/bread-crumb-component.vue";
import pdf from "vue-pdf";
// import { format, parseISO } from "date-fns";
import getMonthName from "../../helpers/getMonthName";

export default {
  components: {
    pdf,
    ScaleOut,
    "el-tabs": Tabs,
    "el-tab-pane": TabPane,
    BreadCrumbNavigation,
  },

  data() {
    return {
      loading: false,
      deleting: false,
      activeName: "questionPaper",
      activePage: 1,
      progress: 0,
      isLoading: true,
      totalPages: "N/A",
      examination: {},
      // examination: this.$route.params.examination,
      examId: this.$route.params.examId, //this is the examination id from the browser

      students_doing_exam: [],
      question_fileExtension: "",
      answerSheet_fileExtension: "",
      is_permitted: false,

      tableData: [],
      tableProps: {
        border: true,
        stripe: true,
      },

      filters: [
        {
          prop: "title",
          value: "",
        },
      ],
    };
  },

  computed: {
    downloadProgress() {
      return Math.round(this.progress * 100);
    },

    getInitials() {
      return (nameString) => {
        //  format(parseISO(dateString), "do MMMM yyyy");
        var names = nameString.split(" "),
          initials = names[0].substring(0, 1).toUpperCase();
        if (names.length > 1) {
          initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      };
    },
  },

  mounted() {
    this.getExamination();
    // If there's No examination, e.g page has reloaded, go back to previous page
    // if (!this.examination) {
    //     this.$router.back();
    // }
  },

  methods: {
    toMonthName(monthNum) {
      return getMonthName(monthNum, true);
    },

    async getExamination() {
      try {
        this.loading = true;
        this.loadingError = false;
        let request = await this.$http.get(
          `examinations/fetch-one/${this.examId}`
        );
        if (
          request.data.success &&
          request.data.message == "EXAMINATION_FETCHED_SUCCESS_FULLY"
        ) {
          this.examination = request.data.examination;
          this.students_doing_exam =
            request.data.examination.students_doing_exam;

          this.question_fileExtension = this.examination.path_key.substring(
            this.examination.path_key.lastIndexOf(".") + 1
          );
          this.answerSheet_fileExtension =
            this.examination.answer_path_key.substring(
              this.examination.answer_path_key.lastIndexOf(".") + 1
            );
          this.is_permitted = true;
        } else if (
          request.data.message == "STUDENT_NOT_PERMITTED_TO_SEAT_FOR_EXAM_MM"
        ) {
          this.is_permitted = false;
          return this.showWarningMessage(
            "Not Permitted",
            "You are not permitted to sit for this Exam. Please confirm with the University Administration."
          );
        } else {
          this.$rollbar.warning("STUDENTS FRONT END: Unexpected API response", {
            response: request.data,
            request,
          });
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        this.loadingError = true;
        
        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }
        this.showFailedMessage(
          "Unable to load examination",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    onPDfError() {
      this.showFailedMessage(
        "loading Failed",
        "Unable to load the selected Document, please try again"
      );
      // this.$router.back();
    },
    onLoaded() {
      this.isLoading = false;
    },
    onProgress(progress) {
      if (!progress) return;
      this.progress = progress;
    },
    onNextPage() {
      if (this.activePage == this.totalPages) return;
      ++this.activePage;
      window.scrollTo(0, 0);
    },
    onPreviousPage() {
      if (this.activePage == 1) return;
      --this.activePage;
      window.scrollTo(0, 0);
    },
    onPageNumberObtained(pageNumber) {
      this.totalPages = pageNumber;
    },

    onTabClick() {},

    downloadQuestionPaper(path_key) {
      const ext = path_key.substring(path_key.lastIndexOf(".") + 1);
      this.submiting = true;
      this.$http
        .get(`examinations/download-question-paper/${path_key}`, {
          responseType: "blob",
        })
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Question-paper-${new Date().toLocaleDateString()}.${ext}`
          );
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.submiting = false;
        });
    },

    downloadAnswerSheet(answer_path_key) {
      this.submiting = true;
      this.$http
        .get(`examination/download-answer-sheet/${answer_path_key}`, {
          responseType: "blob",
        })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Question-paper-${new Date().toLocaleDateString()}`
          );
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.submiting = false;
        });
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.direction_arrows {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: rgb(214, 226, 238);
  color: var(--el-app-primary);
  cursor: pointer;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.direction_arrows i {
  font-size: 15px;
}

#the-tab-section {
  width: 80%;
  position: fixed;
  background-color: #F6F6F6;
  z-index: 100;
  top: 60px;
  padding-top: 30px;
}

#the-tab {
  width: 50%;
}

.TabpaddingTop {
  margin: 130px 27px 0px 27px;
  width: 100%;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  #the-tab-section {
    width: 100%;
  }

  #the-tab {
    width: 100%;
  }

  .TabpaddingTop {
    margin: 130px 10px 0px 10px;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  #the-tab-section {
    width: 100%;
  }

  #the-tab {
    width: 100%;
  }

  .TabpaddingTop {
    margin: 130px 10px 0px 10px;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  #the-tab-section {
    width: 100%;
  }

  #the-tab {
    width: 100%;
  }

  .TabpaddingTop {
    margin: 130px 10px 0px 10px;
  }

  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  #the-tab-section {
    width: 100%;
  }

  #the-tab {
    width: 100%;
  }

  .TabpaddingTop {
    margin: 130px 5px 0px 5px;
  }
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
.announcement_dialogs .el-dialog {
  width: 70%;
}

.search_by_input {
  width: 400px !important;
}

.announcement_dialogs .el-select .el-input__inner {
  cursor: pointer;
  height: 36px !important;
  width: 100% !important;
  padding-left: 10px !important;
  text-transform: capitalize;
}
.announcement_dialogs .el-button--primary {
  color: #fff;
  background-color: #409eff !important;
  border-color: #409eff !important;
}

el-table th > .cell {
  color: black !important;
  font-weight: 500;
}
.el-table .cell {
  font-size: 0.9em;
  line-height: 23px;
  font-weight: 300;
}

.announcement_dialogs .el-input input {
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.announcement_dialogs .el-input input:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}

.announcement_dialogs .el-upload-dragger {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 366px !important;
  height: 150px !important;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.announcement_dialogs .el-icon-upload:before {
  font-size: 50px;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .announcement_dialogs .el-dialog {
    width: 50%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .announcement_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .announcement_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .announcement_dialogs .el-dialog {
    width: 90%;
  }
}
</style>