import { render, staticRenderFns } from "./complete-account-set-up.vue?vue&type=template&id=5a636bee&scoped=true"
import script from "./complete-account-set-up.vue?vue&type=script&lang=js"
export * from "./complete-account-set-up.vue?vue&type=script&lang=js"
import style0 from "./complete-account-set-up.vue?vue&type=style&index=0&id=5a636bee&prod&scoped=true&lang=css"
import style1 from "./complete-account-set-up.vue?vue&type=style&index=1&id=5a636bee&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a636bee",
  null
  
)

export default component.exports