<template>
  <article>
    <button
      type="button"
      class="btn z-depth-0 px-0"
      :disabled="isLoading"
      @click="onCheckRequirements"
      v-ripple="'rgba(255, 255, 255, 0.35)'"
      style="
        width: 180px;
        text-transform: capitalize;
        height: 40px;
        border-radius: 4px;
        background-color: #DF282B;
      "
    >
      <span style="position: relative; bottom: 4px">{{isLoading ? 'Please Wait' : 'Check Requirements'}} </span>
    </button>
    <section>
      <div class="enter_course_work_card_number_dialog">
        <el-dialog
          title="Previous Check"
          class="text-left"
          :visible.sync="showCheckRequirementsDialog"
          :destroy-on-close="true"
          :before-close="handleClose"
        >
          <div v-loading="isBusy">
            <div
              v-if="courseWorkProctor.requirements_check"
              class=" row w-100 mx-0"
            >
              <div class="col-12">
                <i
                  v-if="courseWorkProctor.requirements_check.microphone_check"
                  class="fas fa-check-circle pr-2"
                  style="color: green"
                ></i>
                <i
                  v-else
                  class="fas fa-close"
                  style=" color: red"
                ></i> Microphone Check
              </div>
              <div class="col-12 pt-3">
                <i
                  v-if="courseWorkProctor.requirements_check.screenshare_check"
                  class="fas fa-check-circle pr-2"
                  style="color: green"
                ></i>
                <i
                  v-else
                  class="fas fa-close"
                  style=" color: red"
                ></i> Screen Share Check
              </div>
              <div class="col-12 pt-3">
                <i
                  v-if="courseWorkProctor.requirements_check.speakers_check"
                  class="fas fa-check-circle pr-2"
                  style="color: green"
                ></i>
                <i
                  v-else
                  class="fas fa-close"
                  style=" color: red"
                ></i> Speakers Check
              </div>
              <div class="col-12 pt-3">
                <i
                  v-if="courseWorkProctor.requirements_check.webcam_check"
                  class="fas fa-check-circle pr-2"
                  style="color: green"
                ></i>
                <i
                  v-else
                  class="fas fa-close"
                  style=" color: red"
                ></i> WebCam Check
              </div>
              <div class="col-12 pt-3">
                <i
                  class="fas fa-check-circle pr-2"
                  style="color: green"
                ></i> Browser: {{courseWorkProctor.browser}} version {{courseWorkProctor.browser_version}}
              </div>
              <div class="col-12 pt-3">
                <i
                  class="fas fa-check-circle pr-2"
                  style="color: green"
                ></i> Operating System: {{courseWorkProctor.operating_system}}
              </div>
            </div>

            <!--  <div
              class="text-center form-group"
              style="padding-top: 30px"
            >
              <el-button
                style="position: relative; bottom: 9px; color: white"
                :disabled="!courseWorkProctor"
                type="success"
                class="z-depth-0"
                v-ripple="'rgba(255, 255, 255, 0.35)'"
                @click="onCheckExaminationCard"
              >Check Again</el-button>
            </div> -->

          </div>
        </el-dialog>
      </div>
    </section>
  </article>
</template>

<script>
export default {
  name: "app",
  components: {},

  data() {
    return {
      showCheckRequirementsDialog: false,
      isBusy: false,
      isLoading: false,
      courseWorkProctor: "",
    };
  },

  props: {
    course_work: {
      type: Object,
      required: true,
    },
  },

  computed: {},
  methods: {
    async onCheckRequirements() {
      if (!this.course_work.is_proctored) return;

      try {
        this.isLoading = true;

        let request = await this.$http.get(
          `course-work/check-requirements/${this.$store.state.userId}/${this.$route.params.courseWorkId}`
        );

        if (
          request.data.success &&
          request.data.message == "PROCTORED_EXAM_REQUIREMENTS_RETRIEVED"
        ) {
          this.courseWorkProctor = request.data.examProctor;

          //If student has not checked requirements yet, redirect them to check requirements
          if (!this.courseWorkProctor.checked_requirements) {
            window.open(
              `https://demo.proctorexam.com/check_requirements/${this.courseWorkProctor.token}`,
              "_blank"
            );
          } else {
            //Otherwise show dialog for requirements
            this.showCheckRequirementsDialog = true;
          }
        } else {
          this.$rollbar.warning("STUDENTS FRONT END: Unexpected API response", {
            response: request.data,
            request,
          });
          throw "UNEXEPECTED RESPONSE";
        }
      } catch (error) {
        console.log(error);
        if (error.message === "Network Error") {
          this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        } else {
          this.showFailedMessage(
            "Unable to Check Requirements",
            "An unexpected Error occurred, please try again"
          );
        }
      } finally {
        this.isLoading = false;
      }
    },

    handleClose(done) {
      done();
    },
  },
};
</script>


<style scoped>
* {
  box-sizing: border-box;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.enter_course_work_card_number_dialog .el-dialog {
  width: 30%;
}

.search_by_input {
  width: 400px !important;
}

.enter_course_work_card_number_dialog .el-button--primary {
  color: #fff;
  background-color: #409eff !important;
  border-color: #409eff !important;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .enter_course_work_card_number_dialog .el-dialog {
    width: 50%;
    background-color: #fafafa;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .enter_course_work_card_number_dialog .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .enter_course_work_card_number_dialog .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .enter_course_work_card_number_dialog .el-dialog {
    width: 90%;
  }
}
</style>
