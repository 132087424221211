<template>
  <div class="container-fluid">
    <div class="mx-0 mt-5 row w-100">
      <div class="text-left col-12">
        <img
          class="pr-2"
          :src="$store.state.logoImageUrl"
          style="height: 40px"
        />
        <div class="d-inline-block">
          <div style="
              text-transform: uppercase;
              font-size: 0.75em;
              font-weight: 700;
              position: relative;
              color: rgba(0, 0, 0, 0.4);
              top: 10px;
            ">
            {{ $store.state.universityName }}<br />
            STUDENT'S E-LEARNING
          </div>
        </div>
      </div>
      <div
        class="text-center col-12"
        style="margin-top: 30px"
      >
        <h1 style="font-weight: 700; font-size: 1.4em">
          Provide your Course Details
        </h1>
      </div>

      <div
        v-loading="isLoading"
        class="mx-auto mt-5 row complete_account_set_up_class"
      >
        <div
          class="mx-auto row"
          style="width: 50%"
        >
          <div class="pt-5 text-left col-12">
            <h3 style="
                font-weight: 500;
                font-size: 0.95em;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.8);
              ">
              Select a course
            </h3>
          </div>
          <div class="col-12">
            <el-select
              v-model="selectedCourseId"
              placeholder="Select"
              class="d-block"
            >
              <el-option
                v-for="item in courses"
                :key="item.course_id"
                :label="item.title"
                :value="item.course_id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="pt-4 text-left col-12">
            <h3 style="
                font-weight: 500;
                font-size: 0.95em;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.8);
              ">
              Select Intake
            </h3>
          </div>
          <div class="col-md-6">
            <el-select
              v-model="selectedMonth"
              placeholder="Select"
              class="d-block"
            >
              <el-option
                v-for="item in monthOptions"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="pl-2 pr-2 col-md-6 date-picker">
            <el-date-picker
              v-model="selectedYear"
              :clearable="false"
              type="year"
              class="d-block"
              placeholder="Pick a year"
              :value-format="'yyyy'"
            >
            </el-date-picker>
          </div>
          <div class="mt-4 text-right col-12">
            <button
              @click="updateCourseDetails"
              :disabled="anyInvalid"
              type="button"
              class="ml-0 btn z-depth-0 d-block"
              v-ripple="'rgba(255, 255, 255, 0.35)'"
              style="
                width: 200px;
                text-transform: capitalize;
                height: 45px;
                border-radius: 4px;
                background-color: var(--el-app-primary);
              "
            >
              <span style="position: relative; bottom: 1px">Update Information
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import SelectCourse from "@/components/complete-account-set-up/select-course.vue";
// import SelectDietIntake from "@/components/complete-account-set-up/select-batch-intake.vue";
export default {
  components: {
    // SelectCourse,
    // SelectDietIntake,
  },
  data() {
    return {
      selectedCourseId: "",
      isLoading: false,
      courses: [],
      selectedMonth: "",
      selectedYear: "",
      monthOptions: [
        {
          title: "January",
          id: 1,
        },
        {
          title: "February",
          id: 2,
        },
        {
          title: "March",
          id: 3,
        },
        {
          title: "April",
          id: 4,
        },
        {
          title: "May",
          id: 5,
        },
        {
          title: "June",
          id: 6,
        },
        {
          title: "July",
          id: 7,
        },
        {
          title: "August",
          id: 8,
        },
        {
          title: "September",
          id: 9,
        },
        {
          title: "October",
          id: 10,
        },
        {
          title: "November",
          id: 11,
        },
        {
          title: "December",
          id: 12,
        },
      ],
    };
  },
  computed: {
    anyInvalid() {
      return (
        !this.selectedCourseId || !this.selectedMonth || !this.selectedYear
      );
    },
  },
  methods: {
    async updateCourseDetails() {
      if (this.anyInvalid) return;
      try {
        this.isLoading = true;
        let request = await this.$http.put("students/choose-course-details", {
          course_id: this.selectedCourseId,
          course_intake_batch: {
            year: this.selectedYear,
            month: this.selectedMonth,
          },
          student_id: this.$route.params.routeInfo.student.student_id,
        });

        if (
          request.data.success &&
          request.data.message == "COURSE_INFO_UPDATED"
        ) {
          this.$store.commit("LOGIN_USER", this.$route.params.routeInfo);
          this.$router.replace({ path: "/dashboard/home" });
          this.showSuccessMessage(
            "Course Info updated",
            "Your course Information has been succesfully updated."
          );
        } else if (request.data.message == "UNKNOWN_INTAKE_BATCH") {
          this.showFailedMessage(
            "Unknown Intake Diet",
            "The Intake Diet you entered is not known for the selected Course, please review it and try again"
          );
        } else {
          this.$rollbar.warning("STUDENTS FRONT END: Unexpected API response", {
            response: request.data,
            request,
          });
          throw "UNEXPECTED_SERVER_RESPONSE";
        }
      } catch (error) {
        // eslint-disable-line no-unused-vars
        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }
        this.showFailedMessage(
          "Save failed",
          "Failed to update your Course Details now, please try again"
        );
      } finally {
        this.isLoading = false;
      }
    },
  },

  created() {
    // If we dont have faculties, redirect to login
    if (!this.$route.params.routeInfo) {
      return this.$router.replace({ path: "/login" });
    }
    this.courses = this.$route.params.routeInfo.courses;
  },
};
</script>

<style scoped>
.batch-intake-width {
  width: 60% !important;
}
</style>

<style>
.complete_account_set_up_class .el-select .el-input__inner {
  cursor: pointer;
  height: 40px !important;
  width: 100% !important;
  padding-left: 10px !important;
  text-transform: capitalize;
}

.complete_account_set_up_class .el-select-dropdown__item {
  text-transform: capitalize !important;
  font-family: "Euclid Circular B";
  font-weight: 400;
}

.date-picker .el-date-editor .el-input__inner {
  cursor: pointer;
  height: 40px !important;
  width: 100% !important;
  padding-left: 45px !important;
}
</style>