<template>
  <article class="">
    <section>
      <div class="">
        <el-dialog
          title="Preview Online Answer Sheet"
          class="text-left"
          width="90%"
          :visible="isVisible"
          :destroy-on-close="true"
          :close-on-press-escape="false"
          :close-on-click-modal="false"
          :before-close="handleClose"
        >
          <div v-loading="isBusy">
            <div class="px-3 row">
              <div class="col-12 px-0 pb-4">
                <span
                  class="pt-0 text-left"
                  style="font-size: 1em; font-style: italic; color: rgba(0, 0, 0, 0.8)"
                >
                  <!-- This will <b style="font-weight: 500">immediately</b> submit your answer sheet for this Examination, ensure it is correct before submitting. -->
                  Please scroll to the Bottom to Confirm & Submit Your Answer Sheet
                </span>
              </div>
              <div
                class="col-12"
                style="
                  border: 1px solid black;
                  width: 98%;
                  padding-top: 20px;
                  padding-bottom: 20px;
                "
                v-html="studentAnswers"
              ></div>
            </div>
            <div
              class="text-right form-group"
              style="padding-top: 30px"
            >
              <el-button
                style="position: relative; bottom: 9px; color: white"
                type="danger"
                class="z-depth-0"
                v-ripple="'rgba(255, 255, 255, 0.35)'"
                @click="handleClose"
              >Cancel</el-button>
              <el-button
                style="
                  background-color: var(--el-app-primary);
                  position: relative;
                  bottom: 9px;
                  color: white;
                "
                class="z-depth-0"
                v-ripple="'rgba(255, 255, 255, 0.35)'"
                @click="onSubmitAnswerSheet"
              >Confirm & Submit</el-button>
            </div>
            <span
              class="pt-0 text-left"
              style="font-size: .8em; font-style: italic; color: rgba(0, 0, 0, 0.8)"
            >
              This will <b style="font-weight: 500">immediately</b> submit your answer sheet for this Examination, ensure it is the right one before submitting.
            </span>
          </div>
        </el-dialog>
      </div>
    </section>
  </article>
</template>

<script>
export default {
  data() {
    return {
      isBusy: false,
    };
  },

  props: {
    isVisible: {
      required: true,
      type: Boolean,
    },
    studentAnswers: {
      type: String,
      required: true,
    },
  },

  methods: {
    handleClose() {
      this.$emit("close-dialog");
    },
    onSubmitAnswerSheet() {
      // this.isBusy = true;
      this.$emit("submit-answer-sheet");
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
}
</style>
