<template>
  <div
    class="mx-auto available-tests-courseworks row"
    style="width: 100%"
  >
    <div
      class="pl-0 pr-0 text-left col-12"
      style="font-weight: 600"
    >
      Available Course Work
    </div>

    <div
      v-if="!isFetching && !isError && courseworks.length == 0"
      class="mt-4 text-left col-12"
      style="font-weight: 400; font-size: 0.9em"
    >
      <el-empty description=" You have No Available Course Work at the Moment."></el-empty>

    </div>

    <div class="pr-0 mt-1 text-right col-12"></div>
    <div
      v-if="isError"
      class="px-0 col-12"
      style="height: 180px"
    >
      <div style="font-weight: 600; margin-top: 50px">Fetching Failed</div>
      <div
        class="pt-2"
        style="font-weight: 400; font-size: 0.9em"
      >
        Failed to fetch Course Work now. Please try again
      </div>
      <button
        type="button"
        class="mx-0 mt-3 btn z-depth-0"
        v-ripple="'rgba(255, 255, 255, 0.35)'"
        @click="fetchAvailableCourseWorks"
        style="
          width: 220px;
          text-transform: capitalize;
          height: 45px;
          border-radius: 4px;
          background-color: red;
          font-weight: 500;
        "
      >
        <span style="position: relative; bottom: 1px"><i class="pr-2 fas fa-refresh"></i> Try Again</span>
      </button>
    </div>
    <div
      v-show="isFetching && !isError"
      class="px-0 mt-3 col-12"
    >
      <carousel
        ref="preloaderCarouselRef"
        :autoWidth="true"
        :dots="false"
      >
        <div
          v-for="i in [1, 2, 3, 4, 5, 6]"
          :key="i"
          class="mr-3 skeleton-block skeleton-effect-wave"
          style="width: 230px; height: 400px; border-radius: 5px"
          :navText="[
            `<i class='fas fa-chevron-left'></i>`,
            `<i class='fas fa-chevron-right'></i>`,
          ]"
        ></div>
      </carousel>
    </div>

    <div class="px-0 mt-3 col-12">
      <!-- <div style="height: 10px; width: 10px; background-color: red"></div> -->
      <carousel
        :key="carouselKey"
        :autoWidth="true"
        :dots="false"
        :navText="[
          `<i class='fas fa-chevron-left'></i>`,
          `<i class='fas fa-chevron-right'></i>`,
        ]"
      >
        <div
          v-for="(item, index) in courseworks"
          :key="index"
          v-loading="fetchingCourseWorkID == item.course_work.course_work_id"
          class="mx-3 my-2 card ml-md-0"
          style="width: 230px; height: auto"
        >
          <div class="pt-0 pb-3">
            <div
              v-if="item.course_work.is_published"
              class="d-flex justify-content-center align-items-center"
              :class="getBackgroundColorClass(item.course_work)"
              style=" height: 150px; width: 100%; "
            >
              <span>
                <span style="font-size: 2.5em; font-weight: 500">{{getStudentScore(item.course_work)}}</span> <span>/ {{item.course_work.total_marks}}</span>
              </span>
            </div>

            <div
              v-else
              class="d-flex justify-content-center align-items-center"
              style=" height: 150px; width: 100%; background-color: rgba(255, 0, 0, 0.075); "
            >
              <i
                class="fas fa-book-open"
                style="font-size: 2.7em; color: rgba(255, 0, 0, 0.35)"
              ></i>
            </div>

            <div
              class="mt-4 text-left col-12 text-truncate"
              style="color: var(--el-app-primary); text-transform: capitalize"
            >
              <strong>{{ item.course_unit.course_unit_code }}</strong>
            </div>

            <small
              style="
                color: #000;
                padding: 0px 10px;
                color: rgba(0, 0, 0, 0.4);
                text-transform: capitalize;
              "
              class="pb-1 text-left d-block text-truncate"
            >{{ item.course_work.title }}
            </small>
            <hr class="custom-classic-hr" />
            <small
              style="color: #000; padding: 0px 10px; color: rgba(0, 0, 0, 0.6)"
              class="pb-1 text-left d-block text-truncate"
            >
              <i class="fas fa-check"></i> <strong>Start Date & Time: </strong>
              <br />
              {{ item.course_work.start_time_ug_iso }} EAT
            </small>
            <small
              style="color: #000; padding: 0px 10px; color: rgba(0, 0, 0, 0.6)"
              class="pb-1 text-left d-block text-truncate"
            >
              <i class="fas fa-check"></i> <strong>End Date & Time: </strong>
              <br />
              {{ item.course_work.end_time_ug_iso }} EAT
            </small>
            <div v-if="forMarksOnly(item.course_work)">
              <small
                style="padding: 0px 10px"
                class="pb-1 text-left d-block text-truncate text-warning"
              >
                <i class="fas fa-warning"></i> For Marks Only
              </small>
            </div>
            <small
              v-if="missedCourseWork(item.course_work)"
              style="color: rgba(255, 0, 0, 0.8); padding: 0px 10px"
              class="pb-1 text-left d-block text-truncate"
            >
              <i class="fas fa-close"></i> You Missed this Course Work
            </small>
            <div v-if="neverCompleted(item.course_work)">
              <small
                style="padding: 0px 10px"
                class="pb-1 text-left d-block text-truncate text-warning"
              >
                <i class="fas fa-warning"></i> Started, Never Submitted
              </small>

            </div>

            <small
              v-if="courseWorkSubmitted(item.course_work)"
              style="color: #67c23a; padding: 0px 10px"
              class="pb-1 text-left d-block text-truncate"
            >
              <i class="fas fa-check"></i> Already Submitted
            </small>
            <div class="pl-0 pr-3 mt-1 col-12">
              <button
                v-if="item.course_work.is_published && (getStudentScore(item.course_work) !== '?')"
                @click="downloadStudentCourseWorkResults(item.course_work.course_work_id, item.course_work.student_course_work_submissions[0].student_course_work_submission_id)"
                type="button btn"
                class="btn z-depth-0"
                v-ripple="'rgba(255, 255, 255, 0.35)'"
              >
                <span style="display: inline">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style="fill: rgba(255, 255, 255, 1); margin-right: 5px"
                  >
                    <path d="M18.944 11.112C18.507 7.67 15.56 5 12 5 9.244 5 6.85 6.61 5.757 9.149 3.609 9.792 2 11.82 2 14c0 2.657 2.089 4.815 4.708 4.971V19H17.99v-.003L18 19c2.206 0 4-1.794 4-4a4.008 4.008 0 0 0-3.056-3.888zM8 12h3V9h2v3h3l-4 5-4-5z"></path>
                  </svg>
                  <span style="position: relative; bottom: 6px">Download Results</span></span>
              </button>

              <button
                v-else
                type="button"
                class="btn z-depth-0"
                :class="{ 'not-allowed': fetchingCourseWorkID != null }"
                @click="
                  $router.push({
                    path: `/dashboard/course-work/instructions/${item.course_work.course_work_id}/${item.course_unit.course_unit_id}`,
                  })
                "
                v-ripple="'rgba(255, 255, 255, 0.35)'"
              >
                <span>{{
                  courseWorkSubmitted(item.course_work)
                    ? "Submitted"
                    : "Details"
                }}</span>
              </button>
            </div>
          </div>
        </div>
      </carousel>
    </div>

  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
// import ScheduleLiveLessonComponent from "../components/schedule-live-lesson-component";
import { format, parseISO } from "date-fns";

export default {
  components: {
    carousel,
    // ScheduleLiveLessonComponent,
  },
  data() {
    return {
      isFetching: true,
      isError: false,
      fetchingCourseWorkID: null,
      courseworks: [],
      tests: [],
      carouselKey: 1,
    };
  },
  computed: {
    forMarksOnly() {
      return (course_work) => {
        const hasSubmission = !!course_work.student_course_work_submissions[0];
        return (
          hasSubmission &&
          course_work.student_course_work_submissions[0].for_marks_only
        );
      };
    },
    missedCourseWork() {
      return (course_work) => {
        const hasSubmission = !!course_work.student_course_work_submissions[0];
        return course_work.is_course_work_ended && !hasSubmission;
      };
    },

    courseWorkSubmitted() {
      return (course_work) => {
        const hasSubmission = !!course_work.student_course_work_submissions[0];
        return (
          hasSubmission &&
          !!course_work.student_course_work_submissions[0].submitted_at
        );
      };
    },

    neverCompleted() {
      return (course_work) => {
        const hasSubmission = !!course_work.student_course_work_submissions[0];
        return (
          hasSubmission &&
          !course_work.student_course_work_submissions[0].submitted_at
        );
      };
    },

    formattedDate() {
      return (dateString) => {
        if (!dateString) return "";
        return format(parseISO(dateString), "do MMMM yyyy");
      };
    },

    getInitials() {
      return (nameString) => {
        //  format(parseISO(dateString), "do MMMM yyyy");
        var names = nameString.split(" "),
          initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
          initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      };
    },
  },
  watch: {
    courseworks() {
      //Force rerender of the component once we get some data!!!
      this.carouselKey++;
    },
  },
  mounted() {
    this.$refs.preloaderCarouselRef.$el.getElementsByClassName(
      "owl-prev"
    )[0].innerHTML = "<i class='fas fa-chevron-left'></i>";
    this.$refs.preloaderCarouselRef.$el.getElementsByClassName(
      "owl-next"
    )[0].innerHTML = "<i class='fas fa-chevron-right'></i>";

    this.fetchAvailableCourseWorks();
  },

  methods: {
    getStudentScore(course_work) {
      //Check if student has submission
      const hasSubmission = !!course_work.student_course_work_submissions[0];
      if (!hasSubmission) return "?";

      const submission = course_work.student_course_work_submissions[0];

      //Check if the student submission has a total_score
      const hasScore = submission.total_score !== null && submission.total_score !== undefined;
      if (!hasScore) return "?";

      return submission.total_score;
    },

    getBackgroundColorClass(course_work) {
      //Check if student has submission
      const hasSubmission = !!course_work.student_course_work_submissions[0];
      if (!hasSubmission) return "bg-poor";

      const submission = course_work.student_course_work_submissions[0];

      //Check if the student submission has a score_group
      const scoreGroup = submission.score_group;
      if (!scoreGroup) return "bg-poor";

      if (scoreGroup === "EXCELLENT") {
        return "bg-excellent";
      } else if (scoreGroup === "FAIR") {
        return "bg-fair";
      }

      return "bg-poor";
    },

    async fetchAvailableCourseWorks() {
      try {
        //If we havent been loading yet, let's Load
        if (!this.isFetching) {
          this.isFetching = true;
          this.isError = false;
        }

        let request = await this.$http.get(
          `course-work/fetch-all-for-student/${this.$store.state.userId}`
        );
        if (
          request.data.success &&
          request.data.message == "STUDENT COURSE WORKS RETRIEVED"
        ) {
          this.courseworks = request.data.course_works;
          this.isFetching = false;
          this.isError = false;
        } else {
          this.$rollbar.warning("STUDENTS FRONT END: Unexpected API response", {
            response: request.data,
            request,
          });
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        // eslint-disable-line no-unused-vars
        this.isFetching = false;
        this.isError = true;

        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        console.log(error);

        this.showFailedMessage(
          "Fetching failed",
          "Unable to fetch Course Work now, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    async downloadStudentCourseWorkResults(
      course_work_id,
      student_course_work_submission_id
    ) {
      try {
        //If we haven't been loading yet, let's Load
        this.fetchingCourseWorkID = course_work_id;

        let request = await this.$http.get(
          `course-work/online-marking/course-work-results/download-zip/${student_course_work_submission_id}`
        );
        if (
          request.data.success &&
          request.data.message === "REPORT GENERATED SUCCESSFULLY"
        ) {
          this.showSuccessMessage(
            "Processing Download",
            "Download in Progress. Please wait..."
          );
          // download the excel report based on the api endpoint returned.
          const reportDownloadLink = request.data.link;
          const link = document.createElement("a");
          link.href = reportDownloadLink;
          link.click();
        } else {
          this.$rollbar.warning("STUDENTS FRONT END: Unexpected API response", {
            response: request.data,
            request,
          });
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        // eslint-disable-line no-unused-vars

        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        if (error.response) {
          if (
            error.response.data.message ===
            "STUDENT COURSE WORK SUBMISSION NOT FOUND"
          ) {
            return this.showWarningMessage(
              "Not Found",
              "Unable to find your submission right now, please try again later"
            );
          } else if (
            error.response.data.message === "NO CUMULATIVE SCORES FOUND"
          ) {
            return this.showWarningMessage(
              "Not Marked",
              "Your course work has not yet been marked, please try again later"
            );
          } else if (
            error.response.data.message === "COURSE WORK SHOULD BE PUBLISHED"
          ) {
            return this.showWarningMessage(
              "Not Published",
              "Your course work has not yet been published, please try again later"
            );
          }
        }

        this.showFailedMessage(
          "Fetching failed",
          "Unable to download Course Work Results now, please try again"
        );
      } finally {
        this.fetchingCourseWorkID = null;
      }
    },
  },
};
</script>


<style scoped>
.btn {
  width: 100%;
  text-transform: capitalize;
  padding: 0px 0px !important;
  height: 40px;
  border-radius: 4px;
  background-color: var(--el-app-primary);
}
.bg-excellent {
  background-color: #d8fbeb;
  color: #69ad8e;
}

.bg-fair {
  background-color: #fef3cb;
  color: #e7c15a !important;
}

.bg-poor {
  background-color: rgba(255, 0, 0, 0.075);
  color: rgba(255, 0, 0, 0.35) !important;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.35;
}
</style>

<style>
.user-avatar .skeleton-block {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
hr.custom-classic-hr {
  /* Gradient transparent-color-transparent */
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.205),
    rgba(0, 0, 0, 0)
  );
}
.available-tests-courseworks .el-progress__text {
  font-size: 12px !important;
}
.owl-prev {
  position: absolute;
  left: -21px;
  top: 44%;
  padding: 6px 11px !important;
  border-radius: 30px !important;
}

.owl-next {
  position: absolute;
  right: -21px;
  top: 44%;
  padding: 6px 11px !important;
  border-radius: 30px !important;
}
</style>